/* tslint:disable */

declare var Object: any;
export interface DeliveryFormQuestionInterface {
  type: string;
  name: string;
  label: string;
}

export class DeliveryFormQuestion implements DeliveryFormQuestionInterface {
  'type': string;
  'name': string;
  'label': string;
  constructor(data?: DeliveryFormQuestionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `DeliveryFormQuestion`.
   */
  public static getModelName() {
    return 'DeliveryFormQuestion';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of DeliveryFormQuestion for dynamic purposes.
   **/
  public static factory(
    data: DeliveryFormQuestionInterface,
  ): DeliveryFormQuestion {
    return new DeliveryFormQuestion(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'DeliveryFormQuestion',
      plural: 'DeliveryFormQuestions',
      path: 'DeliveryFormQuestions',
      idName: '',
      properties: {
        type: {
          name: 'type',
          type: 'string',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        label: {
          name: 'label',
          type: 'string',
        },
      },
      relations: {},
    };
  }
}
