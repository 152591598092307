/* tslint:disable */

declare var Object: any;
export interface StatusTypeInterface {
  staander?: boolean;
  ligger?: boolean;
  draairichting?: boolean;
  scharnier?: boolean;
  window?: boolean;
  slotkast?: boolean;
  dagschoot?: boolean;
  krukgat?: boolean;
  extra?: boolean;
  aluminiumplaat?: boolean;
}

export class StatusType implements StatusTypeInterface {
  'staander': boolean;
  'ligger': boolean;
  'draairichting': boolean;
  'scharnier': boolean;
  'window': boolean;
  'slotkast': boolean;
  'dagschoot': boolean;
  'krukgat': boolean;
  'extra': boolean;
  'aluminiumplaat': boolean;
  constructor(data?: StatusTypeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `StatusType`.
   */
  public static getModelName() {
    return 'StatusType';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of StatusType for dynamic purposes.
   **/
  public static factory(data: StatusTypeInterface): StatusType {
    return new StatusType(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'StatusType',
      plural: 'StatusTypes',
      path: 'StatusTypes',
      idName: 'id',
      properties: {
        staander: {
          name: 'staander',
          type: 'boolean',
        },
        ligger: {
          name: 'ligger',
          type: 'boolean',
        },
        draairichting: {
          name: 'draairichting',
          type: 'boolean',
        },
        scharnier: {
          name: 'scharnier',
          type: 'boolean',
        },
        window: {
          name: 'window',
          type: 'boolean',
        },
        slotkast: {
          name: 'slotkast',
          type: 'boolean',
        },
        dagschoot: {
          name: 'dagschoot',
          type: 'boolean',
        },
        krukgat: {
          name: 'krukgat',
          type: 'boolean',
        },
        extra: {
          name: 'extra',
          type: 'boolean',
        },
        aluminiumplaat: {
          name: 'aluminiumplaat',
          type: 'boolean',
        },
      },
      relations: {},
    };
  }
}
