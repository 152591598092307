/* tslint:disable */

declare var Object: any;
export interface ServiceStandardProductInterface {
  serviceId: any;
  standardProductId?: any;
  name?: string;
  price?: number;
  amount: number;
  originalProduct: any;
  images?: Array<any>;
  id?: any;
  standardOrderId?: any;
  dateCreated?: Date;
  dateModified?: Date;
  userIdCreated?: string;
  userIdModified?: string;
  product?: any;
  order?: any;
  service?: any;
}

export class ServiceStandardProduct implements ServiceStandardProductInterface {
  'serviceId': any;
  'standardProductId': any;
  'name': string;
  'price': number;
  'amount': number;
  'originalProduct': any;
  'images': Array<any>;
  'id': any;
  'standardOrderId': any;
  'dateCreated': Date;
  'dateModified': Date;
  'userIdCreated': string;
  'userIdModified': string;
  product: any;
  order: any;
  service: any;
  constructor(data?: ServiceStandardProductInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ServiceStandardProduct`.
   */
  public static getModelName() {
    return 'ServiceStandardProduct';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ServiceStandardProduct for dynamic purposes.
   **/
  public static factory(
    data: ServiceStandardProductInterface,
  ): ServiceStandardProduct {
    return new ServiceStandardProduct(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ServiceStandardProduct',
      plural: 'ServiceStandardProducts',
      path: 'ServiceStandardProducts',
      idName: 'id',
      properties: {
        serviceId: {
          name: 'serviceId',
          type: 'any',
        },
        standardProductId: {
          name: 'standardProductId',
          type: 'any',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        price: {
          name: 'price',
          type: 'number',
        },
        amount: {
          name: 'amount',
          type: 'number',
          default: 1,
        },
        originalProduct: {
          name: 'originalProduct',
          type: 'any',
        },
        images: {
          name: 'images',
          type: 'Array&lt;any&gt;',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        standardOrderId: {
          name: 'standardOrderId',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
        userIdCreated: {
          name: 'userIdCreated',
          type: 'string',
        },
        userIdModified: {
          name: 'userIdModified',
          type: 'string',
        },
      },
      relations: {
        product: {
          name: 'product',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'standardProductId',
          keyTo: 'id',
        },
        order: {
          name: 'order',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'standardOrderId',
          keyTo: 'id',
        },
        service: {
          name: 'service',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'serviceId',
          keyTo: 'id',
        },
      },
    };
  }
}
