/* tslint:disable */

declare var Object: any;
export interface PriceCategoryItemHistoryInterface {
  revision: any;
  userId?: any;
  modelId: any;
  action: string;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
  user?: any;
  priceCategoryItem?: any;
}

export class PriceCategoryItemHistory
  implements PriceCategoryItemHistoryInterface
{
  'revision': any;
  'userId': any;
  'modelId': any;
  'action': string;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  user: any;
  priceCategoryItem: any;
  constructor(data?: PriceCategoryItemHistoryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PriceCategoryItemHistory`.
   */
  public static getModelName() {
    return 'PriceCategoryItemHistory';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of PriceCategoryItemHistory for dynamic purposes.
   **/
  public static factory(
    data: PriceCategoryItemHistoryInterface,
  ): PriceCategoryItemHistory {
    return new PriceCategoryItemHistory(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'PriceCategoryItemHistory',
      plural: 'PriceCategoryItemHistories',
      path: 'PriceCategoryItemHistories',
      idName: 'id',
      properties: {
        revision: {
          name: 'revision',
          type: 'any',
        },
        userId: {
          name: 'userId',
          type: 'any',
        },
        modelId: {
          name: 'modelId',
          type: 'any',
        },
        action: {
          name: 'action',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {
        user: {
          name: 'user',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'userId',
          keyTo: 'id',
        },
        priceCategoryItem: {
          name: 'priceCategoryItem',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'modelId',
          keyTo: 'id',
        },
      },
    };
  }
}
