<div *ngIf="standardProducts && standardProducts.length === 0">
  <hr />
  Geen accessoire gevonden
</div>

<div [hidden]="!standardProducts || standardProducts.length === 0">
  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col">Naam</th>
        <th scope="col">Product type</th>
        <th scope="col">Prijs</th>
        <th scope="col">Bezorgkosten</th>
        <th scope="col">Dimensies (B*H*L/G)</th>
        <th scope="col">Voorraad</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let standardProduct of standardProducts"
        class="pointer"
        (click)="onClick(standardProduct)"
      >
        <td scope="row">
          {{ standardProduct.name }}
        </td>
        <td>{{ standardProduct.productType?.name }}</td>
        <td scope="row">
          {{ standardProduct.price | currency : 'EUR' : 'symbol' : '1.0' }}
        </td>
        <td>
          &euro;{{
            standardProduct.deliveryMethod?.price
              ? standardProduct.deliveryMethod?.price
              : 0
          }}
        </td>
        <td>{{ getDimensions(standardProduct) }}</td>
        <td>{{ countStock(standardProduct) }}</td>
        <td class="table-action"><i class="fas fa-angle-right"></i></td>
      </tr>
    </tbody>
  </table>
</div>
