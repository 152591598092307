/* tslint:disable */
import { Rack, ProductieStaat, Order } from '../index';

declare var Object: any;
export interface RackSlotInterface {
  label: number;
  rackId: any;
  id?: any;
  productieStaatId?: any;
  orderId?: any;
  dateCreated?: Date;
  dateModified?: Date;
  rack?: Rack;
  productieStaat?: ProductieStaat;
  order?: Order;
}

export class RackSlot implements RackSlotInterface {
  'label': number;
  'rackId': any;
  'id': any;
  'productieStaatId': any;
  'orderId': any;
  'dateCreated': Date;
  'dateModified': Date;
  rack: Rack;
  productieStaat: ProductieStaat;
  order: Order;
  constructor(data?: RackSlotInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `RackSlot`.
   */
  public static getModelName() {
    return 'RackSlot';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of RackSlot for dynamic purposes.
   **/
  public static factory(data: RackSlotInterface): RackSlot {
    return new RackSlot(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'RackSlot',
      plural: 'RackSlots',
      path: 'RackSlots',
      idName: 'id',
      properties: {
        label: {
          name: 'label',
          type: 'number',
        },
        rackId: {
          name: 'rackId',
          type: 'any',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        productieStaatId: {
          name: 'productieStaatId',
          type: 'any',
        },
        orderId: {
          name: 'orderId',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {
        rack: {
          name: 'rack',
          type: 'Rack',
          model: 'Rack',
          relationType: 'belongsTo',
          keyFrom: 'rackId',
          keyTo: 'id',
        },
        productieStaat: {
          name: 'productieStaat',
          type: 'ProductieStaat',
          model: 'ProductieStaat',
          relationType: 'belongsTo',
          keyFrom: 'productieStaatId',
          keyTo: 'id',
        },
        order: {
          name: 'order',
          type: 'Order',
          model: 'Order',
          relationType: 'belongsTo',
          keyFrom: 'orderId',
          keyTo: 'id',
        },
      },
    };
  }
}
