/* tslint:disable */

declare var Object: any;
export interface ShoppingCartStandardProductInterface {
  name?: string;
  price?: number;
  originalProduct: any;
  shoppingCartId: any;
  amount: number;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
  userIdCreated?: string;
  userIdModified?: string;
  standardProductId?: any;
  shoppingcart?: any;
  product?: any;
}

export class ShoppingCartStandardProduct
  implements ShoppingCartStandardProductInterface
{
  'name': string;
  'price': number;
  'originalProduct': any;
  'shoppingCartId': any;
  'amount': number;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  'userIdCreated': string;
  'userIdModified': string;
  'standardProductId': any;
  shoppingcart: any;
  product: any;
  constructor(data?: ShoppingCartStandardProductInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ShoppingCartStandardProduct`.
   */
  public static getModelName() {
    return 'ShoppingCartStandardProduct';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ShoppingCartStandardProduct for dynamic purposes.
   **/
  public static factory(
    data: ShoppingCartStandardProductInterface,
  ): ShoppingCartStandardProduct {
    return new ShoppingCartStandardProduct(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ShoppingCartStandardProduct',
      plural: 'ShoppingCartStandardProducts',
      path: 'ShoppingCartStandardProducts',
      idName: 'id',
      properties: {
        name: {
          name: 'name',
          type: 'string',
        },
        price: {
          name: 'price',
          type: 'number',
        },
        originalProduct: {
          name: 'originalProduct',
          type: 'any',
        },
        shoppingCartId: {
          name: 'shoppingCartId',
          type: 'any',
        },
        amount: {
          name: 'amount',
          type: 'number',
          default: 1,
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
        userIdCreated: {
          name: 'userIdCreated',
          type: 'string',
        },
        userIdModified: {
          name: 'userIdModified',
          type: 'string',
        },
        standardProductId: {
          name: 'standardProductId',
          type: 'any',
        },
      },
      relations: {
        shoppingcart: {
          name: 'shoppingcart',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'shoppingCartId',
          keyTo: 'id',
        },
        product: {
          name: 'product',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'standardProductId',
          keyTo: 'id',
        },
      },
    };
  }
}
