/* tslint:disable */
import { DeliveryFormProduct } from '../index';

declare var Object: any;
export interface DeliveryFormInterface {
  modelId: any;
  modelType?: string;
  questions: Array<any>;
  comment?: string;
  signature?: string;
  finished?: boolean;
  mailSent?: boolean;
  startingImage?: string;
  startingOtherImages?: Array<any>;
  startingOtherVideos?: Array<any>;
  finishingImage?: string;
  finishingOtherImages?: Array<any>;
  finishingOtherVideos?: Array<any>;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
  userIdCreated?: string;
  userIdModified?: string;
  model?: any;
  deliveryFormProducts?: DeliveryFormProduct[];
}

export class DeliveryForm implements DeliveryFormInterface {
  'modelId': any;
  'modelType': string;
  'questions': Array<any>;
  'comment': string;
  'signature': string;
  'finished': boolean;
  'mailSent': boolean;
  'startingImage': string;
  'startingOtherImages': Array<any>;
  'startingOtherVideos': Array<any>;
  'finishingImage': string;
  'finishingOtherImages': Array<any>;
  'finishingOtherVideos': Array<any>;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  'userIdCreated': string;
  'userIdModified': string;
  model: any;
  deliveryFormProducts: DeliveryFormProduct[];
  constructor(data?: DeliveryFormInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `DeliveryForm`.
   */
  public static getModelName() {
    return 'DeliveryForm';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of DeliveryForm for dynamic purposes.
   **/
  public static factory(data: DeliveryFormInterface): DeliveryForm {
    return new DeliveryForm(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'DeliveryForm',
      plural: 'DeliveryForms',
      path: 'DeliveryForms',
      idName: 'id',
      properties: {
        modelId: {
          name: 'modelId',
          type: 'any',
        },
        modelType: {
          name: 'modelType',
          type: 'string',
        },
        questions: {
          name: 'questions',
          type: 'Array&lt;any&gt;',
        },
        comment: {
          name: 'comment',
          type: 'string',
        },
        signature: {
          name: 'signature',
          type: 'string',
        },
        finished: {
          name: 'finished',
          type: 'boolean',
          default: false,
        },
        mailSent: {
          name: 'mailSent',
          type: 'boolean',
          default: false,
        },
        startingImage: {
          name: 'startingImage',
          type: 'string',
        },
        startingOtherImages: {
          name: 'startingOtherImages',
          type: 'Array&lt;any&gt;',
        },
        startingOtherVideos: {
          name: 'startingOtherVideos',
          type: 'Array&lt;any&gt;',
        },
        finishingImage: {
          name: 'finishingImage',
          type: 'string',
        },
        finishingOtherImages: {
          name: 'finishingOtherImages',
          type: 'Array&lt;any&gt;',
        },
        finishingOtherVideos: {
          name: 'finishingOtherVideos',
          type: 'Array&lt;any&gt;',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
        userIdCreated: {
          name: 'userIdCreated',
          type: 'string',
        },
        userIdModified: {
          name: 'userIdModified',
          type: 'string',
        },
      },
      relations: {
        model: {
          name: 'model',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'modelId',
          keyTo: 'id',
        },
        deliveryFormProducts: {
          name: 'deliveryFormProducts',
          type: 'DeliveryFormProduct[]',
          model: 'DeliveryFormProduct',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'deliveryFormId',
        },
      },
    };
  }
}
