//////////////////////////////
//                          //
//         Angular          //
//                          //
//////////////////////////////
import { Injectable, Optional } from '@angular/core';


//////////////////////////////
//                          //
//       Dependencies       //
//                          //
//////////////////////////////
import { BehaviorSubject ,  Observable } from 'rxjs';


//////////////////////////////
//                          //
//        App Imports       //
//                          //
//////////////////////////////
import { LoopBackFilter, BaseLoopBackApi } from '../../../_sdk';


@Injectable()
export class LoopBackApiService {
  private _dataApi: BaseLoopBackApi;
  private _filter: LoopBackFilter = {};
  private readonly _data: BehaviorSubject<any[]> = new BehaviorSubject([]);

  constructor(dataApi: any, filter: any = {}) {
    this._dataApi = dataApi;
    this.filter = filter;
    this.fetch();
  }


  //////////////////////////////
  //                          //
  //        Getters           //
  //                          //
  //////////////////////////////

  get filter(): LoopBackFilter {
    return this._filter;
  }

  get data(): BehaviorSubject<any[]> {
    return this._data;
  }


  //////////////////////////////
  //                          //
  //        Setters           //
  //                          //
  //////////////////////////////

  set filter(filter: LoopBackFilter) {
    this._filter = filter;
  }


  //////////////////////////////
  //                          //
  //     Private Methods      //
  //                          //
  //////////////////////////////

  /**
   * Fetches the `data` current value and tries to figure out whether we want to add or replace the given data.
   * Will call `next` on the subject eitherway.
   * @param {{}} data
   * @return {void}
   */
  private replaceOrAdd(data: any): void {
    const currentData: any[] = this.data.getValue();
    const count = data.length;

    let index;
    for (let i = 0; i < count; i++) {
      if (currentData[i].id === data.id) {
        index = i;
        break;
      }
    }

    if (index >= 0) {
      currentData.splice(index, 1, data);
    } else {
      currentData.push(data);
    }

    this._data.next(currentData);
  }


  //////////////////////////////
  //                          //
  //      Public Methods      //
  //                          //
  //////////////////////////////

  /**
   * Fetches the data and emits the result to the subject. Will also return observerable from the models `find` method.
   * @param {boolean} emit whether we want to update the data subject. Default: true.
   * @param {LoopBackFilter} tempFilter temporary filter to use for this fetch
   * @return {Observable<{}[]>}
   */
  fetch(emit = true, tempFilter?: LoopBackFilter): Observable<{}[]> {
    const data$ = this._dataApi.find(tempFilter ? tempFilter : this._filter);

    if (emit) {
      data$.subscribe(
        (res: {}[]) => this._data.next(res),
        (err: Error) => console.error(err)
      );
    }

    return data$;
  }

  /**
   * Creates the given data. Will also return the observerable from the models `create` method.
   * @param {{}} data the data model we want to save
   * @param {boolean} emit whether we want to update the data subject. Default: true.
   * @return {Observable<{}>}
   */
  create(data: any, emit = true): Observable<{}> {
    const data$ = this._dataApi.create(data);

    if (emit) {
      data$.subscribe((res: {}) => {
        const item = this._data.getValue();
        item.push(res);
        this._data.next(item);
      });
    }

    return data$;
  }

  /**
   * Saves the given group. Will also return the observerable from the models `create` method.
   * @param {{}} group the group model we want to save
   * @param {boolean} emit whether we want to update the data subject. Default: true.
   * @return {Observable<{}>}
   */
  save(data: any, emit = true): Observable<{}> {
    const data$ = this._dataApi.updateAttributes(data.id, data);

    if (emit) {
      data$.subscribe((res: {}) => this.replaceOrAdd(res));
    }

    return data$;
  }

  /**
   * Upserts the given group. Will also return the observerable from the models `create` method.
   * @param {{}} group the group model we want to save
   * @param {boolean} emit whether we want to update the data subject. Default: true.
   * @return {Observable<{}>}
   */
  upsert(data: any, emit = true): Observable<{}> {
    const data$ = this._dataApi.upsert(data);

    if (emit) {
      data$.subscribe((res: {}) => this.replaceOrAdd(res));
    }

    return data$;
  }
}
