/* tslint:disable */

declare var Object: any;
export interface CorrespondenceInterface {
  fkType?: string;
  fkId: any;
  type?: string;
  subType?: string;
  title?: string;
  content?: string;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
  parent?: any;
}

export class Correspondence implements CorrespondenceInterface {
  'fkType': string;
  'fkId': any;
  'type': string;
  'subType': string;
  'title': string;
  'content': string;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  parent: any;
  constructor(data?: CorrespondenceInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Correspondence`.
   */
  public static getModelName() {
    return 'Correspondence';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Correspondence for dynamic purposes.
   **/
  public static factory(data: CorrespondenceInterface): Correspondence {
    return new Correspondence(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Correspondence',
      plural: 'Correspondences',
      path: 'Correspondences',
      idName: 'id',
      properties: {
        fkType: {
          name: 'fkType',
          type: 'string',
        },
        fkId: {
          name: 'fkId',
          type: 'any',
        },
        type: {
          name: 'type',
          type: 'string',
        },
        subType: {
          name: 'subType',
          type: 'string',
        },
        title: {
          name: 'title',
          type: 'string',
        },
        content: {
          name: 'content',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {
        parent: {
          name: 'parent',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'fkId',
          keyTo: 'id',
        },
      },
    };
  }
}
