/* tslint:disable */

declare var Object: any;
export interface PaymentInterface {
  amount: number;
  status: string;
  dateOpen: Date;
  datePaid?: Date;
  paymentType: string;
  orderId?: any;
  remark?: string;
  id?: any;
  orderType?: string;
  detailsId?: any;
  detailsType?: string;
  userIdCreated?: string;
  dateCreated?: Date;
  dateModified?: Date;
  userIdModified?: string;
  order?: any;
  details?: any;
  userCreated?: any;
  refunds?: any[];
}

export class Payment implements PaymentInterface {
  'amount': number;
  'status': string;
  'dateOpen': Date;
  'datePaid': Date;
  'paymentType': string;
  'orderId': any;
  'remark': string;
  'id': any;
  'orderType': string;
  'detailsId': any;
  'detailsType': string;
  'userIdCreated': string;
  'dateCreated': Date;
  'dateModified': Date;
  'userIdModified': string;
  order: any;
  details: any;
  userCreated: any;
  refunds: any[];
  constructor(data?: PaymentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Payment`.
   */
  public static getModelName() {
    return 'Payment';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Payment for dynamic purposes.
   **/
  public static factory(data: PaymentInterface): Payment {
    return new Payment(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Payment',
      plural: 'Payments',
      path: 'Payments',
      idName: 'id',
      properties: {
        amount: {
          name: 'amount',
          type: 'number',
        },
        status: {
          name: 'status',
          type: 'string',
        },
        dateOpen: {
          name: 'dateOpen',
          type: 'Date',
        },
        datePaid: {
          name: 'datePaid',
          type: 'Date',
        },
        paymentType: {
          name: 'paymentType',
          type: 'string',
        },
        orderId: {
          name: 'orderId',
          type: 'any',
        },
        remark: {
          name: 'remark',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        orderType: {
          name: 'orderType',
          type: 'string',
        },
        detailsId: {
          name: 'detailsId',
          type: 'any',
        },
        detailsType: {
          name: 'detailsType',
          type: 'string',
        },
        userIdCreated: {
          name: 'userIdCreated',
          type: 'string',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
        userIdModified: {
          name: 'userIdModified',
          type: 'string',
        },
      },
      relations: {
        order: {
          name: 'order',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'orderId',
          keyTo: 'id',
        },
        details: {
          name: 'details',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'detailsId',
          keyTo: 'id',
        },
        userCreated: {
          name: 'userCreated',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'userIdCreated',
          keyTo: 'id',
        },
        refunds: {
          name: 'refunds',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'paymentId',
        },
      },
    };
  }
}
