import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-customer-service-message-modal',
  templateUrl: './customer-service-message-modal.component.html',
  styleUrls: ['./customer-service-message-modal.component.scss'],
})
export class CustomerServiceMessageModalComponent implements OnInit {
  customerServiceMessage = '';

  get isValid(): boolean {
    return this.customerServiceMessage.length > 0;
  }

  constructor(public activeModal: NgbActiveModal) {}

  async ngOnInit() {}

  save() {
    this.activeModal.close({
      sendMessage: 'confirm',
      customerServiceMessage: this.customerServiceMessage,
    });
  }

  reject() {
    this.activeModal.close();
  }
}
