/* tslint:disable */

declare var Object: any;
export interface FinalCheckFormQuestionAnswerInterface {
  value: string;
  question: any;
  optionTemplates?: Array<any>;
  order: number;
  disabled: boolean;
  dateCreated?: Date;
  dateModified?: Date;
}

export class FinalCheckFormQuestionAnswer
  implements FinalCheckFormQuestionAnswerInterface
{
  'value': string;
  'question': any;
  'optionTemplates': Array<any>;
  'order': number;
  'disabled': boolean;
  'dateCreated': Date;
  'dateModified': Date;
  constructor(data?: FinalCheckFormQuestionAnswerInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `FinalCheckFormQuestionAnswer`.
   */
  public static getModelName() {
    return 'FinalCheckFormQuestionAnswer';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of FinalCheckFormQuestionAnswer for dynamic purposes.
   **/
  public static factory(
    data: FinalCheckFormQuestionAnswerInterface,
  ): FinalCheckFormQuestionAnswer {
    return new FinalCheckFormQuestionAnswer(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'FinalCheckFormQuestionAnswer',
      plural: 'FinalCheckFormQuestionAnswer',
      path: 'FinalCheckFormQuestionAnswer',
      idName: '',
      properties: {
        value: {
          name: 'value',
          type: 'string',
        },
        question: {
          name: 'question',
          type: 'any',
        },
        optionTemplates: {
          name: 'optionTemplates',
          type: 'Array&lt;any&gt;',
        },
        order: {
          name: 'order',
          type: 'number',
          default: 1,
        },
        disabled: {
          name: 'disabled',
          type: 'boolean',
          default: false,
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {},
    };
  }
}
