/* tslint:disable */
/**
 * @module SDKModule
 * @author Jonathan Casarrubias <t:@johncasarrubias> <gh:jonathan-casarrubias>
 * @license MIT 2016 Jonathan Casarrubias
 * @version 2.1.0
 * @description
 * The SDKModule is a generated Software Development Kit automatically built by
 * the LoopBack SDK Builder open source module.
 *
 * The SDKModule provides Angular 2 >= RC.5 support, which means that NgModules
 * can import this Software Development Kit as follows:
 *
 *
 * APP Route Module Context
 * ============================================================================
 * import { NgModule }       from '@angular/core';
 * import { BrowserModule }  from '@angular/platform-browser';
 * // App Root
 * import { AppComponent }   from './app.component';
 * // Feature Modules
 * import { SDK[Browser|Node|Native]Module } from './shared/sdk/sdk.module';
 * // Import Routing
 * import { routing }        from './app.routing';
 * @NgModule({
 *  imports: [
 *    BrowserModule,
 *    routing,
 *    SDK[Browser|Node|Native]Module.forRoot()
 *  ],
 *  declarations: [ AppComponent ],
 *  bootstrap:    [ AppComponent ]
 * })
 * export class AppModule { }
 *
 **/
import { ErrorHandler } from './services/core/error.service';
import { LoopBackAuth } from './services/core/auth.service';
import { LoggerService } from './services/custom/logger.service';
import { SDKModels } from './services/custom/SDKModels';
import { InternalStorage, SDKStorage } from './storage/storage.swaps';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CookieBrowser } from './storage/cookie.browser';
import { StorageBrowser } from './storage/storage.browser';
import { SocketBrowser } from './sockets/socket.browser';
import { SocketDriver } from './sockets/socket.driver';
import { SocketConnection } from './sockets/socket.connections';
import { RealTime } from './services/core/real.time';
import { UserModelApi } from './services/custom/UserModel';
import { DeliveryConditionApi } from './services/custom/DeliveryCondition';
import { DealerApi } from './services/custom/Dealer';
import { DeliveryMethodApi } from './services/custom/DeliveryMethod';
import { WarrantyApi } from './services/custom/Warranty';
import { WarrantyPackageApi } from './services/custom/WarrantyPackage';
import { OrderApi } from './services/custom/Order';
import { PaymentApi } from './services/custom/Payment';
import { PaymentIcepayApi } from './services/custom/PaymentIcepay';
import { PaymentPaynlApi } from './services/custom/PaymentPaynl';
import { TransactionStartDtoApi } from './services/custom/TransactionStartDto';
import { DiscountCodeApi } from './services/custom/DiscountCode';
import { FlagApi } from './services/custom/Flag';
import { ContainerApi } from './services/custom/Container';
import { OptionTemplateApi } from './services/custom/OptionTemplate';
import { OptionTemplateContainerApi } from './services/custom/OptionTemplateContainer';
import { ProductApi } from './services/custom/Product';
import { ProductieStaatApi } from './services/custom/ProductieStaat';
import { ProductieStaatConfigurationApi } from './services/custom/ProductieStaatConfiguration';
import { ProductieStaatConfigurationVersionApi } from './services/custom/ProductieStaatConfigurationVersion';
import { ProductieStaatValidationApi } from './services/custom/ProductieStaatValidation';
import { ServiceApi } from './services/custom/Service';
import { ServiceProductApi } from './services/custom/ServiceProduct';
import { PriceCategoryApi } from './services/custom/PriceCategory';
import { PriceCategoryItemHistoryApi } from './services/custom/PriceCategoryItemHistory';
import { LogApi } from './services/custom/Log';
import { ServiceTypeApi } from './services/custom/ServiceType';
import { ServiceResponsibilityApi } from './services/custom/ServiceResponsibility';
import { ServiceCategoryApi } from './services/custom/ServiceCategory';
import { ServiceMaterialApi } from './services/custom/ServiceMaterial';
import { RenderApi } from './services/custom/Render';
import { RackApi } from './services/custom/Rack';
import { RackSlotApi } from './services/custom/RackSlot';
import { ShoppingCartApi } from './services/custom/ShoppingCart';
import { ShoppingCartProductApi } from './services/custom/ShoppingCartProduct';
import { ShoppingCartSupplyProductApi } from './services/custom/ShoppingCartSupplyProduct';
import { ShoppingCartStandardProductApi } from './services/custom/ShoppingCartStandardProduct';
import { UserProductieStaatConfigInterfaceApi } from './services/custom/UserProductieStaatConfigInterface';
import { InternalNoteApi } from './services/custom/InternalNote';
import { ProductHistoryApi } from './services/custom/ProductHistory';
import { PaginationMetaApi } from './services/custom/PaginationMeta';
import { StatusFlowApi } from './services/custom/StatusFlow';
import { StatusApi } from './services/custom/Status';
import { StatusTypeApi } from './services/custom/StatusType';
import { SupplyProductApi } from './services/custom/SupplyProduct';
import { SupplyOrderApi } from './services/custom/SupplyOrder';
import { SupplyOrderProductApi } from './services/custom/SupplyOrderProduct';
import { CorrespondenceApi } from './services/custom/Correspondence';
import { ExactTokenApi } from './services/custom/ExactToken';
import { ExactLogApi } from './services/custom/ExactLog';
import { SaleTypeApi } from './services/custom/SaleType';
import { DeliveryQuestionApi } from './services/custom/DeliveryQuestion';
import { DeliveryFormApi } from './services/custom/DeliveryForm';
import { DeliveryFormProductApi } from './services/custom/DeliveryFormProduct';
import { DeliveryFormQuestionApi } from './services/custom/DeliveryFormQuestion';
import { DeliveryFormQuestionAnswerApi } from './services/custom/DeliveryFormQuestionAnswer';
import { FinalCheckQuestionApi } from './services/custom/FinalCheckQuestion';
import { FinalCheckFormQuestionApi } from './services/custom/FinalCheckFormQuestion';
import { FinalCheckFormQuestionAnswerApi } from './services/custom/FinalCheckFormQuestionAnswer';
import { FactoryWorkerApi } from './services/custom/FactoryWorker';
import { DivisionApi } from './services/custom/Division';
import { SaleLocationApi } from './services/custom/SaleLocation';
import { CancelQuestionApi } from './services/custom/CancelQuestion';
import { IcepayIssuerApi } from './services/custom/IcepayIssuer';
import { ExactArticleWarehouseLocationApi } from './services/custom/ExactArticleWarehouseLocation';
import { StandardOrderApi } from './services/custom/StandardOrder';
import { StandardProductApi } from './services/custom/StandardProduct';
import { StandardOrderProductApi } from './services/custom/StandardOrderProduct';
import { StandardProductDeliveryConditionApi } from './services/custom/StandardProductDeliveryCondition';
import { ProductCategoryApi } from './services/custom/ProductCategory';
import { StandardProductCategoryApi } from './services/custom/StandardProductCategory';
import { ProductTypeApi } from './services/custom/ProductType';
import { CorrectionReasonApi } from './services/custom/CorrectionReason';
import { StandardOrderDeliveryApi } from './services/custom/StandardOrderDelivery';
import { ServiceStandardProductApi } from './services/custom/ServiceStandardProduct';
/**
 * @module SDKBrowserModule
 * @description
 * This module should be imported when building a Web Application in the following scenarios:
 *
 *  1.- Regular web application
 *  2.- Angular universal application (Browser Portion)
 *  3.- Progressive applications (Angular Mobile, Ionic, WebViews, etc)
 **/
@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [],
  exports: [],
  providers: [ErrorHandler, SocketConnection],
})
export class SDKBrowserModule {
  static forRoot(
    internalStorageProvider: any = {
      provide: InternalStorage,
      useClass: CookieBrowser,
    },
  ): ModuleWithProviders {
    return {
      ngModule: SDKBrowserModule,
      providers: [
        LoopBackAuth,
        LoggerService,
        SDKModels,
        RealTime,
        UserModelApi,
        DeliveryConditionApi,
        DealerApi,
        DeliveryMethodApi,
        WarrantyApi,
        WarrantyPackageApi,
        OrderApi,
        PaymentApi,
        PaymentIcepayApi,
        PaymentPaynlApi,
        TransactionStartDtoApi,
        DiscountCodeApi,
        FlagApi,
        ContainerApi,
        OptionTemplateApi,
        OptionTemplateContainerApi,
        ProductApi,
        ProductieStaatApi,
        ProductieStaatConfigurationApi,
        ProductieStaatConfigurationVersionApi,
        ProductieStaatValidationApi,
        ServiceApi,
        ServiceProductApi,
        PriceCategoryApi,
        PriceCategoryItemHistoryApi,
        LogApi,
        ServiceTypeApi,
        ServiceResponsibilityApi,
        ServiceCategoryApi,
        ServiceMaterialApi,
        RenderApi,
        RackApi,
        RackSlotApi,
        ShoppingCartApi,
        ShoppingCartProductApi,
        ShoppingCartSupplyProductApi,
        ShoppingCartStandardProductApi,
        UserProductieStaatConfigInterfaceApi,
        InternalNoteApi,
        ProductHistoryApi,
        PaginationMetaApi,
        StatusFlowApi,
        StatusApi,
        StatusTypeApi,
        SupplyProductApi,
        SupplyOrderApi,
        SupplyOrderProductApi,
        CorrespondenceApi,
        ExactTokenApi,
        ExactLogApi,
        SaleTypeApi,
        DeliveryQuestionApi,
        DeliveryFormApi,
        DeliveryFormProductApi,
        DeliveryFormQuestionApi,
        DeliveryFormQuestionAnswerApi,
        FinalCheckQuestionApi,
        FinalCheckFormQuestionApi,
        FinalCheckFormQuestionAnswerApi,
        FactoryWorkerApi,
        DivisionApi,
        SaleLocationApi,
        CancelQuestionApi,
        IcepayIssuerApi,
        ExactArticleWarehouseLocationApi,
        StandardOrderApi,
        StandardProductApi,
        StandardOrderProductApi,
        StandardProductDeliveryConditionApi,
        ProductCategoryApi,
        StandardProductCategoryApi,
        ProductTypeApi,
        CorrectionReasonApi,
        StandardOrderDeliveryApi,
        ServiceStandardProductApi,
        internalStorageProvider,
        { provide: SDKStorage, useClass: StorageBrowser },
        { provide: SocketDriver, useClass: SocketBrowser },
      ],
    };
  }
}
/**
 * Have Fun!!!
 * - Jon
 **/
export * from './models/index';
export * from './services/index';
export * from './lb.config';
export * from './storage/storage.swaps';
export { CookieBrowser } from './storage/cookie.browser';
export { StorageBrowser } from './storage/storage.browser';
