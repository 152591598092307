/* tslint:disable */

declare var Object: any;
export interface ServiceCategoryInterface {
  code: string;
  category: string;
  active: boolean;
  applyToOrder?: boolean;
  applyToStandardOrder?: boolean;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
  services?: any[];
}

export class ServiceCategory implements ServiceCategoryInterface {
  'code': string;
  'category': string;
  'active': boolean;
  'applyToOrder': boolean;
  'applyToStandardOrder': boolean;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  services: any[];
  constructor(data?: ServiceCategoryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ServiceCategory`.
   */
  public static getModelName() {
    return 'ServiceCategory';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ServiceCategory for dynamic purposes.
   **/
  public static factory(data: ServiceCategoryInterface): ServiceCategory {
    return new ServiceCategory(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ServiceCategory',
      plural: 'ServiceCategories',
      path: 'ServiceCategories',
      idName: 'id',
      properties: {
        code: {
          name: 'code',
          type: 'string',
        },
        category: {
          name: 'category',
          type: 'string',
        },
        active: {
          name: 'active',
          type: 'boolean',
        },
        applyToOrder: {
          name: 'applyToOrder',
          type: 'boolean',
          default: false,
        },
        applyToStandardOrder: {
          name: 'applyToStandardOrder',
          type: 'boolean',
          default: false,
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {
        services: {
          name: 'services',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'serviceCategoryId',
        },
      },
    };
  }
}
