/* tslint:disable */

declare var Object: any;
export interface ProductInterface {
  orderId: any;
  label?: string;
  doorType: any;
  doorColor: any;
  doorLayout: any;
  glassType: any;
  doorHandle: any;
  extraOptions?: Array<any>;
  preset?: any;
  measurements?: any;
  imageId?: string;
  renderId?: string;
  eigenwijsRender?: string;
  pendingNotiveRender?: boolean;
  amount: number;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
  userIdCreated?: string;
  userIdModified?: string;
  order?: any;
  productieStaat?: any[];
  revisions?: any[];
}

export class Product implements ProductInterface {
  'orderId': any;
  'label': string;
  'doorType': any;
  'doorColor': any;
  'doorLayout': any;
  'glassType': any;
  'doorHandle': any;
  'extraOptions': Array<any>;
  'preset': any;
  'measurements': any;
  'imageId': string;
  'renderId': string;
  'eigenwijsRender': string;
  'pendingNotiveRender': boolean;
  'amount': number;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  'userIdCreated': string;
  'userIdModified': string;
  order: any;
  productieStaat: any[];
  revisions: any[];
  constructor(data?: ProductInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Product`.
   */
  public static getModelName() {
    return 'Product';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Product for dynamic purposes.
   **/
  public static factory(data: ProductInterface): Product {
    return new Product(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Product',
      plural: 'Products',
      path: 'Products',
      idName: 'id',
      properties: {
        orderId: {
          name: 'orderId',
          type: 'any',
        },
        label: {
          name: 'label',
          type: 'string',
        },
        doorType: {
          name: 'doorType',
          type: 'any',
        },
        doorColor: {
          name: 'doorColor',
          type: 'any',
        },
        doorLayout: {
          name: 'doorLayout',
          type: 'any',
        },
        glassType: {
          name: 'glassType',
          type: 'any',
        },
        doorHandle: {
          name: 'doorHandle',
          type: 'any',
        },
        extraOptions: {
          name: 'extraOptions',
          type: 'Array&lt;any&gt;',
        },
        preset: {
          name: 'preset',
          type: 'any',
        },
        measurements: {
          name: 'measurements',
          type: 'any',
          default: <any>null,
        },
        imageId: {
          name: 'imageId',
          type: 'string',
        },
        renderId: {
          name: 'renderId',
          type: 'string',
        },
        eigenwijsRender: {
          name: 'eigenwijsRender',
          type: 'string',
        },
        pendingNotiveRender: {
          name: 'pendingNotiveRender',
          type: 'boolean',
          default: true,
        },
        amount: {
          name: 'amount',
          type: 'number',
          default: 1,
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
        userIdCreated: {
          name: 'userIdCreated',
          type: 'string',
        },
        userIdModified: {
          name: 'userIdModified',
          type: 'string',
        },
      },
      relations: {
        order: {
          name: 'order',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'orderId',
          keyTo: 'id',
        },
        productieStaat: {
          name: 'productieStaat',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'productId',
        },
        revisions: {
          name: 'revisions',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'modelId',
        },
      },
    };
  }
}
