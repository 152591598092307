/* tslint:disable */

declare var Object: any;
export interface SupplyOrderProductInterface {
  name?: string;
  price?: number;
  supplyOrderId: any;
  supplyProductId: any;
  originalProduct: any;
  inStore: boolean;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
  userIdCreated?: string;
  userIdModified?: string;
  product?: any;
  order?: any;
}

export class SupplyOrderProduct implements SupplyOrderProductInterface {
  'name': string;
  'price': number;
  'supplyOrderId': any;
  'supplyProductId': any;
  'originalProduct': any;
  'inStore': boolean;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  'userIdCreated': string;
  'userIdModified': string;
  product: any;
  order: any;
  constructor(data?: SupplyOrderProductInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `SupplyOrderProduct`.
   */
  public static getModelName() {
    return 'SupplyOrderProduct';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of SupplyOrderProduct for dynamic purposes.
   **/
  public static factory(data: SupplyOrderProductInterface): SupplyOrderProduct {
    return new SupplyOrderProduct(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'SupplyOrderProduct',
      plural: 'SupplyOrderProducts',
      path: 'SupplyOrderProducts',
      idName: 'id',
      properties: {
        name: {
          name: 'name',
          type: 'string',
        },
        price: {
          name: 'price',
          type: 'number',
        },
        supplyOrderId: {
          name: 'supplyOrderId',
          type: 'any',
        },
        supplyProductId: {
          name: 'supplyProductId',
          type: 'any',
        },
        originalProduct: {
          name: 'originalProduct',
          type: 'any',
        },
        inStore: {
          name: 'inStore',
          type: 'boolean',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
        userIdCreated: {
          name: 'userIdCreated',
          type: 'string',
        },
        userIdModified: {
          name: 'userIdModified',
          type: 'string',
        },
      },
      relations: {
        product: {
          name: 'product',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'supplyProductId',
          keyTo: 'id',
        },
        order: {
          name: 'order',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'supplyOrderId',
          keyTo: 'id',
        },
      },
    };
  }
}
