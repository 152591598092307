/* tslint:disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi } from '../core/base.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackAuth } from '../core/auth.service';
import { LoopBackFilter } from '../../models/BaseModels';
import { ErrorHandler } from '../core/error.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { OptionTemplate } from '../../models/OptionTemplate';
import { SocketConnection } from '../../sockets/socket.connections';

/**
 * Api services for the `OptionTemplate` model.
 */
@Injectable()
export class OptionTemplateApi extends BaseLoopBackApi {
  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SocketConnection) protected connection: SocketConnection,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler,
  ) {
    super(http, connection, models, auth, errorHandler);
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   * @param {any} id OptionTemplate id
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - An object of model property name/value pairs
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `OptionTemplate` object.)
   * </em>
   */
  public patchAttributes(
    id: any,
    data: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'PATCH';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/OptionTemplates/:id';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Get the download manuals
   *
   * @param {object} res
   *
   * @param {string} type
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `body` – `{file}` -
   *
   *  - `Content-Type` – `{string}` -
   */
  public downloadManualPDF(
    res: any = {},
    type: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/OptionTemplates/downloadManualPDF';
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof type !== 'undefined' && type !== null) _urlParams.type = type;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Get the totalprice from a set of options
   *
   * @param {string} door Type of door
   *
   * @param {string} layout Type of layout
   *
   * @param {string} glass Type of glass
   *
   * @param {string} color Type of color
   *
   * @param {string} handle Type of handle
   *
   * @param {string} extra Extra options.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `OptionTemplate` object.)
   * </em>
   */
  public getPriceFromOptions(
    door: any,
    layout: any,
    glass: any,
    color: any,
    handle: any,
    extra: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/OptionTemplates/getPriceFromOptions';
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof door !== 'undefined' && door !== null) _urlParams.door = door;
    if (typeof layout !== 'undefined' && layout !== null)
      _urlParams.layout = layout;
    if (typeof glass !== 'undefined' && glass !== null)
      _urlParams.glass = glass;
    if (typeof color !== 'undefined' && color !== null)
      _urlParams.color = color;
    if (typeof handle !== 'undefined' && handle !== null)
      _urlParams.handle = handle;
    if (typeof extra !== 'undefined' && extra !== null)
      _urlParams.extra = extra;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Get the OptionTemplates by filter
   *
   * @param {string} door Type of door
   *
   * @param {any} chosenOptions Option codes
   *
   * @param {boolean} isGewoonGers Whether the option is an GewoonGers product
   *
   * @param {boolean} isESD Whether the option is an ESD product
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `OptionTemplate` object.)
   * </em>
   */
  public getOptionsByFilter(
    door: any,
    chosenOptions: any = {},
    isGewoonGers: any = {},
    isESD: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/OptionTemplates/getOptionsByFilter';
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof door !== 'undefined' && door !== null) _urlParams.door = door;
    if (typeof chosenOptions !== 'undefined' && chosenOptions !== null)
      _urlParams.chosenOptions = chosenOptions;
    if (typeof isGewoonGers !== 'undefined' && isGewoonGers !== null)
      _urlParams.isGewoonGers = isGewoonGers;
    if (typeof isESD !== 'undefined' && isESD !== null)
      _urlParams.isESD = isESD;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Returns the xml for the Google Shopping Feed
   *
   * @param {object} res
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `OptionTemplate` object.)
   * </em>
   */
  public getGoogleShoppingFeed(
    res: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/OptionTemplates/getGoogleShoppingFeed';
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `OptionTemplate`.
   */
  public getModelName() {
    return 'OptionTemplate';
  }
}
