/* tslint:disable */
import { ProductieStaatConfigurationVersion } from '../index';

declare var Object: any;
export interface ProductieStaatValidationInterface {
  versionId: any;
  name: string;
  severity: string;
  doorTypes: Array<any>;
  excludeTypeScriptFunction: string;
  excludeJavaScriptFunction?: string;
  generateValidationTypeScriptFunction: string;
  generateValidationJavaScriptFunction?: string;
  disabled: boolean;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
  userIdCreated?: string;
  userIdModified?: string;
  version?: ProductieStaatConfigurationVersion;
}

export class ProductieStaatValidation
  implements ProductieStaatValidationInterface
{
  'versionId': any;
  'name': string;
  'severity': string;
  'doorTypes': Array<any>;
  'excludeTypeScriptFunction': string;
  'excludeJavaScriptFunction': string;
  'generateValidationTypeScriptFunction': string;
  'generateValidationJavaScriptFunction': string;
  'disabled': boolean;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  'userIdCreated': string;
  'userIdModified': string;
  version: ProductieStaatConfigurationVersion;
  constructor(data?: ProductieStaatValidationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ProductieStaatValidation`.
   */
  public static getModelName() {
    return 'ProductieStaatValidation';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ProductieStaatValidation for dynamic purposes.
   **/
  public static factory(
    data: ProductieStaatValidationInterface,
  ): ProductieStaatValidation {
    return new ProductieStaatValidation(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ProductieStaatValidation',
      plural: 'ProductieStaatValidations',
      path: 'ProductieStaatValidations',
      idName: 'id',
      properties: {
        versionId: {
          name: 'versionId',
          type: 'any',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        severity: {
          name: 'severity',
          type: 'string',
        },
        doorTypes: {
          name: 'doorTypes',
          type: 'Array&lt;any&gt;',
        },
        excludeTypeScriptFunction: {
          name: 'excludeTypeScriptFunction',
          type: 'string',
        },
        excludeJavaScriptFunction: {
          name: 'excludeJavaScriptFunction',
          type: 'string',
        },
        generateValidationTypeScriptFunction: {
          name: 'generateValidationTypeScriptFunction',
          type: 'string',
        },
        generateValidationJavaScriptFunction: {
          name: 'generateValidationJavaScriptFunction',
          type: 'string',
        },
        disabled: {
          name: 'disabled',
          type: 'boolean',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
        userIdCreated: {
          name: 'userIdCreated',
          type: 'string',
        },
        userIdModified: {
          name: 'userIdModified',
          type: 'string',
        },
      },
      relations: {
        version: {
          name: 'version',
          type: 'ProductieStaatConfigurationVersion',
          model: 'ProductieStaatConfigurationVersion',
          relationType: 'belongsTo',
          keyFrom: 'versionId',
          keyTo: 'id',
        },
      },
    };
  }
}
