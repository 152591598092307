/* tslint:disable */
import { StandardProduct } from '../index';

declare var Object: any;
export interface DeliveryMethodInterface {
  code: string;
  description?: string;
  name: string;
  price: number;
  prio: number;
  exactArticleCode: string;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
  product?: StandardProduct[];
}

export class DeliveryMethod implements DeliveryMethodInterface {
  'code': string;
  'description': string;
  'name': string;
  'price': number;
  'prio': number;
  'exactArticleCode': string;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  product: StandardProduct[];
  constructor(data?: DeliveryMethodInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `DeliveryMethod`.
   */
  public static getModelName() {
    return 'DeliveryMethod';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of DeliveryMethod for dynamic purposes.
   **/
  public static factory(data: DeliveryMethodInterface): DeliveryMethod {
    return new DeliveryMethod(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'DeliveryMethod',
      plural: 'DeliveryMethods',
      path: 'DeliveryMethods',
      idName: 'id',
      properties: {
        code: {
          name: 'code',
          type: 'string',
        },
        description: {
          name: 'description',
          type: 'string',
          default: '',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        price: {
          name: 'price',
          type: 'number',
        },
        prio: {
          name: 'prio',
          type: 'number',
        },
        exactArticleCode: {
          name: 'exactArticleCode',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {
        product: {
          name: 'product',
          type: 'StandardProduct[]',
          model: 'StandardProduct',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'deliveryMethodId',
        },
      },
    };
  }
}
