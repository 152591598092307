/* tslint:disable */

declare var Object: any;
export interface FactoryWorkerInterface {
  name: string;
  code: string;
  disabled: boolean;
  isDeleted?: boolean;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
  deleted?: boolean;
  dateDeleted?: Date;
}

export class FactoryWorker implements FactoryWorkerInterface {
  'name': string;
  'code': string;
  'disabled': boolean;
  'isDeleted': boolean;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  'deleted': boolean;
  'dateDeleted': Date;
  constructor(data?: FactoryWorkerInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `FactoryWorker`.
   */
  public static getModelName() {
    return 'FactoryWorker';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of FactoryWorker for dynamic purposes.
   **/
  public static factory(data: FactoryWorkerInterface): FactoryWorker {
    return new FactoryWorker(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'FactoryWorker',
      plural: 'FactoryWorkers',
      path: 'FactoryWorkers',
      idName: 'id',
      properties: {
        name: {
          name: 'name',
          type: 'string',
        },
        code: {
          name: 'code',
          type: 'string',
        },
        disabled: {
          name: 'disabled',
          type: 'boolean',
          default: false,
        },
        isDeleted: {
          name: 'isDeleted',
          type: 'boolean',
          default: false,
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
        deleted: {
          name: 'deleted',
          type: 'boolean',
        },
        dateDeleted: {
          name: 'dateDeleted',
          type: 'Date',
        },
      },
      relations: {},
    };
  }
}
