<div class="filter-title-bar">
  <div class="container-fluid">
    <div
      class="d-flex flex-row align-items-center justify-content-between mb-3"
    >
      <h5>Kies een product</h5>
    </div>

    <div
      class="my-input-group input-group mb-3 col-sm-6 col-md-6 col-lg-5 px-0"
    >
      <input
        type="text"
        class="form-control py-2 border-right-0"
        [formControl]="searchInput"
        id="searchInput"
        placeholder="Zoek product"
      />
      <span class="input-group-append">
        <div class="input-group-text bg-transparent">
          <i class="fa fa-search"></i>
        </div>
      </span>
    </div>
  </div>
</div>
<div class="supply-product-content">
  <app-standard-product-add
    [standardProducts]="filteredProducts"
    (add)="add($event)"
  ></app-standard-product-add>
</div>

<ng-template #confirmInvalidDialog let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Bevestiging</h4>
  </div>
  <div class="modal-body mb-4">
    Weet u zeker dat u deze accessoire wilt toevoegen?
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.dismiss()"
    >
      Annuleren
    </button>
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('confirm')"
    >
      Toevoegen
    </button>
  </div>
</ng-template>
