/* tslint:disable */

declare var Object: any;
export interface ShoppingCartSupplyProductInterface {
  name?: string;
  price?: number;
  shoppingCartId: any;
  supplyProductId: any;
  originalProduct: any;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
  userIdCreated?: string;
  userIdModified?: string;
  order?: any;
  product?: any;
}

export class ShoppingCartSupplyProduct
  implements ShoppingCartSupplyProductInterface
{
  'name': string;
  'price': number;
  'shoppingCartId': any;
  'supplyProductId': any;
  'originalProduct': any;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  'userIdCreated': string;
  'userIdModified': string;
  order: any;
  product: any;
  constructor(data?: ShoppingCartSupplyProductInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ShoppingCartSupplyProduct`.
   */
  public static getModelName() {
    return 'ShoppingCartSupplyProduct';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ShoppingCartSupplyProduct for dynamic purposes.
   **/
  public static factory(
    data: ShoppingCartSupplyProductInterface,
  ): ShoppingCartSupplyProduct {
    return new ShoppingCartSupplyProduct(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ShoppingCartSupplyProduct',
      plural: 'ShoppingCartSupplyProducts',
      path: 'ShoppingCartSupplyProducts',
      idName: 'id',
      properties: {
        name: {
          name: 'name',
          type: 'string',
        },
        price: {
          name: 'price',
          type: 'number',
        },
        shoppingCartId: {
          name: 'shoppingCartId',
          type: 'any',
        },
        supplyProductId: {
          name: 'supplyProductId',
          type: 'any',
        },
        originalProduct: {
          name: 'originalProduct',
          type: 'any',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
        userIdCreated: {
          name: 'userIdCreated',
          type: 'string',
        },
        userIdModified: {
          name: 'userIdModified',
          type: 'string',
        },
      },
      relations: {
        order: {
          name: 'order',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'shoppingCartId',
          keyTo: 'id',
        },
        product: {
          name: 'product',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'supplyProductId',
          keyTo: 'id',
        },
      },
    };
  }
}
