/* tslint:disable */

declare var Object: any;
export interface PaginationMetaInterface {
  current_page?: number;
  total_pages?: number;
  page_count?: number;
}

export class PaginationMeta implements PaginationMetaInterface {
  'current_page': number;
  'total_pages': number;
  'page_count': number;
  constructor(data?: PaginationMetaInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PaginationMeta`.
   */
  public static getModelName() {
    return 'PaginationMeta';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of PaginationMeta for dynamic purposes.
   **/
  public static factory(data: PaginationMetaInterface): PaginationMeta {
    return new PaginationMeta(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'PaginationMeta',
      plural: 'PaginationMetas',
      path: 'PaginationMetas',
      idName: 'id',
      properties: {
        current_page: {
          name: 'current_page',
          type: 'number',
        },
        total_pages: {
          name: 'total_pages',
          type: 'number',
        },
        page_count: {
          name: 'page_count',
          type: 'number',
        },
      },
      relations: {},
    };
  }
}
