/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./standard-product-list-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../standard-product-add/standard-product-add.component.ngfactory";
import * as i4 from "../standard-product-add/standard-product-add.component";
import * as i5 from "./standard-product-list-modal.component";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
import * as i7 from "../../../_sdk/services/custom/StandardProduct";
var styles_StandardProductListModalComponent = [i0.styles];
var RenderType_StandardProductListModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StandardProductListModalComponent, data: {} });
export { RenderType_StandardProductListModalComponent as RenderType_StandardProductListModalComponent };
function View_StandardProductListModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"], ["id", "modal-basic-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Bevestiging"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "modal-body mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Weet u zeker dat u deze accessoire wilt toevoegen? "])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "btn btn-outline-dark"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.$implicit.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Annuleren "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn-outline-dark"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.$implicit.close("confirm") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Toevoegen "]))], null, null); }
export function View_StandardProductListModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "filter-title-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "d-flex flex-row align-items-center justify-content-between mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Kies een product"])), (_l()(), i1.ɵeld(5, 0, null, null, 9, "div", [["class", "my-input-group input-group mb-3 col-sm-6 col-md-6 col-lg-5 px-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "input", [["class", "form-control py-2 border-right-0"], ["id", "searchInput"], ["placeholder", "Zoek product"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 7)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(9, 540672, null, 0, i2.FormControlDirective, [[8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_q]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlDirective]), i1.ɵdid(11, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "span", [["class", "input-group-append"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "input-group-text bg-transparent"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "i", [["class", "fa fa-search"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "supply-product-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "app-standard-product-add", [], null, [[null, "add"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("add" === en)) {
        var pd_0 = (_co.add($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_StandardProductAddComponent_0, i3.RenderType_StandardProductAddComponent)), i1.ɵdid(17, 49152, null, 0, i4.StandardProductAddComponent, [], { standardProducts: [0, "standardProducts"] }, { add: "add" }), (_l()(), i1.ɵand(0, [["confirmInvalidDialog", 2]], null, 0, null, View_StandardProductListModalComponent_1))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.searchInput; _ck(_v, 9, 0, currVal_7); var currVal_8 = _co.filteredProducts; _ck(_v, 17, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 11).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 11).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 11).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 11).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 11).ngClassValid; var currVal_5 = i1.ɵnov(_v, 11).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 11).ngClassPending; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_StandardProductListModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-standard-product-list-modal", [], null, null, null, View_StandardProductListModalComponent_0, RenderType_StandardProductListModalComponent)), i1.ɵdid(1, 245760, null, 0, i5.StandardProductListModalComponent, [i6.NgbActiveModal, i7.StandardProductApi], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StandardProductListModalComponentNgFactory = i1.ɵccf("app-standard-product-list-modal", i5.StandardProductListModalComponent, View_StandardProductListModalComponent_Host_0, { standardProducts: "standardProducts" }, {}, []);
export { StandardProductListModalComponentNgFactory as StandardProductListModalComponentNgFactory };
