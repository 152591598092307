//////////////////////////////
//                          //
//         Angular          //
//                          //
//////////////////////////////



//////////////////////////////
//                          //
//       Dependencies       //
//                          //
//////////////////////////////



//////////////////////////////
//                          //
//        App Imports       //
//                          //
//////////////////////////////
import { LoopBackApiService } from './loopback-api.service';
import { LoopBackFilter } from '../../../_sdk';


export function LoopBackApiFactory(modelApi: any, filter?: LoopBackFilter): LoopBackApiService {
  return new LoopBackApiService(modelApi, filter);
}
