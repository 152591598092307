/* tslint:disable */

declare var Object: any;
export interface OptionTemplateContainerInterface {
  id?: number;
}

export class OptionTemplateContainer
  implements OptionTemplateContainerInterface
{
  'id': number;
  constructor(data?: OptionTemplateContainerInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `OptionTemplateContainer`.
   */
  public static getModelName() {
    return 'OptionTemplateContainer';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of OptionTemplateContainer for dynamic purposes.
   **/
  public static factory(
    data: OptionTemplateContainerInterface,
  ): OptionTemplateContainer {
    return new OptionTemplateContainer(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'OptionTemplateContainer',
      plural: 'OptionTemplateContainers',
      path: 'OptionTemplateContainers',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'number',
        },
      },
      relations: {},
    };
  }
}
