/* tslint:disable */

declare var Object: any;
export interface ServiceTypeInterface {
  code: string;
  name: string;
  order: number;
  duration: number;
  invisible: boolean;
  applyToOrder?: boolean;
  applyToStandardOrder?: boolean;
  costCenterMaterials: string;
  priceMontage: number;
  responsible: string;
  id?: any;
  parentId?: any;
  dateCreated?: Date;
  dateModified?: Date;
  parent?: any;
}

export class ServiceType implements ServiceTypeInterface {
  'code': string;
  'name': string;
  'order': number;
  'duration': number;
  'invisible': boolean;
  'applyToOrder': boolean;
  'applyToStandardOrder': boolean;
  'costCenterMaterials': string;
  'priceMontage': number;
  'responsible': string;
  'id': any;
  'parentId': any;
  'dateCreated': Date;
  'dateModified': Date;
  parent: any;
  constructor(data?: ServiceTypeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ServiceType`.
   */
  public static getModelName() {
    return 'ServiceType';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ServiceType for dynamic purposes.
   **/
  public static factory(data: ServiceTypeInterface): ServiceType {
    return new ServiceType(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ServiceType',
      plural: 'ServiceTypes',
      path: 'ServiceTypes',
      idName: 'id',
      properties: {
        code: {
          name: 'code',
          type: 'string',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        order: {
          name: 'order',
          type: 'number',
          default: 1,
        },
        duration: {
          name: 'duration',
          type: 'number',
        },
        invisible: {
          name: 'invisible',
          type: 'boolean',
          default: false,
        },
        applyToOrder: {
          name: 'applyToOrder',
          type: 'boolean',
          default: false,
        },
        applyToStandardOrder: {
          name: 'applyToStandardOrder',
          type: 'boolean',
          default: false,
        },
        costCenterMaterials: {
          name: 'costCenterMaterials',
          type: 'string',
        },
        priceMontage: {
          name: 'priceMontage',
          type: 'number',
          default: 0,
        },
        responsible: {
          name: 'responsible',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        parentId: {
          name: 'parentId',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {
        parent: {
          name: 'parent',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'parentId',
          keyTo: 'id',
        },
      },
    };
  }
}
