/* tslint:disable */

declare var Object: any;
export interface StatusInterface {
  name: string;
  primary: number;
  enabled: boolean;
  started: boolean;
  finished: boolean;
  rejected: boolean;
  types?: any;
  dateStarted?: Date;
  dateFinished?: Date;
  userIdStarted?: string;
  userNameStarted?: string;
  userIdFinished?: string;
  userNameFinished?: string;
}

export class Status implements StatusInterface {
  'name': string;
  'primary': number;
  'enabled': boolean;
  'started': boolean;
  'finished': boolean;
  'rejected': boolean;
  'types': any;
  'dateStarted': Date;
  'dateFinished': Date;
  'userIdStarted': string;
  'userNameStarted': string;
  'userIdFinished': string;
  'userNameFinished': string;
  constructor(data?: StatusInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Status`.
   */
  public static getModelName() {
    return 'Status';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Status for dynamic purposes.
   **/
  public static factory(data: StatusInterface): Status {
    return new Status(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Status',
      plural: 'Statuses',
      path: 'Statuses',
      idName: '',
      properties: {
        name: {
          name: 'name',
          type: 'string',
        },
        primary: {
          name: 'primary',
          type: 'number',
        },
        enabled: {
          name: 'enabled',
          type: 'boolean',
        },
        started: {
          name: 'started',
          type: 'boolean',
        },
        finished: {
          name: 'finished',
          type: 'boolean',
        },
        rejected: {
          name: 'rejected',
          type: 'boolean',
        },
        types: {
          name: 'types',
          type: 'any',
        },
        dateStarted: {
          name: 'dateStarted',
          type: 'Date',
        },
        dateFinished: {
          name: 'dateFinished',
          type: 'Date',
        },
        userIdStarted: {
          name: 'userIdStarted',
          type: 'string',
        },
        userNameStarted: {
          name: 'userNameStarted',
          type: 'string',
        },
        userIdFinished: {
          name: 'userIdFinished',
          type: 'string',
        },
        userNameFinished: {
          name: 'userNameFinished',
          type: 'string',
        },
      },
      relations: {},
    };
  }
}
