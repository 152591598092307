/* tslint:disable */
import { Warranty } from '../index';

declare var Object: any;
export interface DeliveryConditionInterface {
  days: number;
  code: string;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
  panel?: Warranty[];
}

export class DeliveryCondition implements DeliveryConditionInterface {
  'days': number;
  'code': string;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  panel: Warranty[];
  constructor(data?: DeliveryConditionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `DeliveryCondition`.
   */
  public static getModelName() {
    return 'DeliveryCondition';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of DeliveryCondition for dynamic purposes.
   **/
  public static factory(data: DeliveryConditionInterface): DeliveryCondition {
    return new DeliveryCondition(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'DeliveryCondition',
      plural: 'DeliveryConditions',
      path: 'DeliveryConditions',
      idName: 'id',
      properties: {
        days: {
          name: 'days',
          type: 'number',
        },
        code: {
          name: 'code',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {
        panel: {
          name: 'panel',
          type: 'Warranty[]',
          model: 'Warranty',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'packageId',
        },
      },
    };
  }
}
