/* tslint:disable */

declare var Object: any;
export interface FinalCheckQuestionInterface {
  question: any;
  optionTemplates?: Array<any>;
  order: number;
  disabled: boolean;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
}

export class FinalCheckQuestion implements FinalCheckQuestionInterface {
  'question': any;
  'optionTemplates': Array<any>;
  'order': number;
  'disabled': boolean;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  constructor(data?: FinalCheckQuestionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `FinalCheckQuestion`.
   */
  public static getModelName() {
    return 'FinalCheckQuestion';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of FinalCheckQuestion for dynamic purposes.
   **/
  public static factory(data: FinalCheckQuestionInterface): FinalCheckQuestion {
    return new FinalCheckQuestion(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'FinalCheckQuestion',
      plural: 'FinalCheckQuestions',
      path: 'FinalCheckQuestions',
      idName: 'id',
      properties: {
        question: {
          name: 'question',
          type: 'any',
        },
        optionTemplates: {
          name: 'optionTemplates',
          type: 'Array&lt;any&gt;',
        },
        order: {
          name: 'order',
          type: 'number',
          default: 1,
        },
        disabled: {
          name: 'disabled',
          type: 'boolean',
          default: false,
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {},
    };
  }
}
