/* tslint:disable */

declare var Object: any;
export interface UserProductieStaatConfigInterfaceInterface {
  variablesTypeScript: string;
  variablesJavaScript?: string;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
}

export class UserProductieStaatConfigInterface
  implements UserProductieStaatConfigInterfaceInterface
{
  'variablesTypeScript': string;
  'variablesJavaScript': string;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  constructor(data?: UserProductieStaatConfigInterfaceInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UserProductieStaatConfigInterface`.
   */
  public static getModelName() {
    return 'UserProductieStaatConfigInterface';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of UserProductieStaatConfigInterface for dynamic purposes.
   **/
  public static factory(
    data: UserProductieStaatConfigInterfaceInterface,
  ): UserProductieStaatConfigInterface {
    return new UserProductieStaatConfigInterface(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'UserProductieStaatConfigInterface',
      plural: 'UserProductieStaatConfigInterface',
      path: 'UserProductieStaatConfigInterface',
      idName: 'id',
      properties: {
        variablesTypeScript: {
          name: 'variablesTypeScript',
          type: 'string',
        },
        variablesJavaScript: {
          name: 'variablesJavaScript',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {},
    };
  }
}
