//////////////////////////////
//                          //
//         Angular          //
//                          //
//////////////////////////////
import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

//////////////////////////////
//                          //
//       Dependencies       //
//                          //
//////////////////////////////
import { NgbdSortableHeader } from '../table/sortable-header.directive';

//////////////////////////////
//                          //
//        App Imports       //
//                          //
//////////////////////////////
import { SupplyProduct } from '@app/_sdk/models/SupplyProduct';
import { SupplyOrderProduct } from '@app/_sdk';

@Component({
  selector: 'app-supply-product-add',
  templateUrl: './supply-product-add.component.html',
  styleUrls: ['./supply-product-add.component.scss'],
})
export class SupplyProductAddComponent {
  // -----------------------------------------------------------------------------------------------------
  // @ Public properties
  // -----------------------------------------------------------------------------------------------------
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  @Input() supplyProducts: SupplyProduct[] | SupplyOrderProduct[];
  @Output() add: EventEmitter<SupplyProduct | SupplyOrderProduct> =
    new EventEmitter<SupplyProduct | SupplyOrderProduct>();

  /**
   * Constructor
   */
  constructor() {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * @description Emit the supply product
   * @param {SupplyProduct | SupplyOrderProduct} supplyProduct
   * @returns {void}
   */
  onClick(supplyProduct: SupplyProduct | SupplyOrderProduct): void {
    this.add.emit(supplyProduct);
  }
}
