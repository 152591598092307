//////////////////////////////
//                          //
//         Angular          //
//                          //
//////////////////////////////
import { Component, Input } from '@angular/core';

//////////////////////////////
//                          //
//       Dependencies       //
//                          //
//////////////////////////////
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

//////////////////////////////
//                          //
//        App Imports       //
//                          //
//////////////////////////////
import { SupplyProduct } from '@app/_sdk/models/SupplyProduct';

@Component({
  selector: 'app-supply-product-list-modal',
  templateUrl: './supply-product-list-modal.component.html',
  styleUrls: ['./supply-product-list-modal.component.scss'],
})
export class SupplyProductListModalComponent {
  // -----------------------------------------------------------------------------------------------------
  // @ Public properties
  // -----------------------------------------------------------------------------------------------------
  @Input() supplyProducts: SupplyProduct[];

  /**
   * Constructor
   */
  constructor(public activeModal: NgbActiveModal) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * @description Select the SupplyProduct
   * @param {SupplyProduct} supplyProduct
   * @returns {void}
   */
  add(supplyProduct: SupplyProduct): void {
    this.activeModal.close(supplyProduct);
  }
}
