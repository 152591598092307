/* tslint:disable */
import { Warranty } from '../index';

declare var Object: any;
export interface WarrantyPackageInterface {
  attachments?: any;
  description: string;
  duration: number;
  price: number;
  name: string;
  active?: boolean;
  code: string;
  appliedAfterFullyPaid?: boolean;
  exactArticleCode?: string;
  glAccountCode?: string;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
  userIdCreated?: string;
  userIdModified?: string;
  deleted?: boolean;
  dateDeleted?: Date;
  warranties?: Warranty[];
}

export class WarrantyPackage implements WarrantyPackageInterface {
  'attachments': any;
  'description': string;
  'duration': number;
  'price': number;
  'name': string;
  'active': boolean;
  'code': string;
  'appliedAfterFullyPaid': boolean;
  'exactArticleCode': string;
  'glAccountCode': string;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  'userIdCreated': string;
  'userIdModified': string;
  'deleted': boolean;
  'dateDeleted': Date;
  warranties: Warranty[];
  constructor(data?: WarrantyPackageInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `WarrantyPackage`.
   */
  public static getModelName() {
    return 'WarrantyPackage';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of WarrantyPackage for dynamic purposes.
   **/
  public static factory(data: WarrantyPackageInterface): WarrantyPackage {
    return new WarrantyPackage(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'WarrantyPackage',
      plural: 'WarrantyPackages',
      path: 'WarrantyPackages',
      idName: 'id',
      properties: {
        attachments: {
          name: 'attachments',
          type: 'any',
        },
        description: {
          name: 'description',
          type: 'string',
        },
        duration: {
          name: 'duration',
          type: 'number',
        },
        price: {
          name: 'price',
          type: 'number',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        active: {
          name: 'active',
          type: 'boolean',
          default: true,
        },
        code: {
          name: 'code',
          type: 'string',
        },
        appliedAfterFullyPaid: {
          name: 'appliedAfterFullyPaid',
          type: 'boolean',
          default: false,
        },
        exactArticleCode: {
          name: 'exactArticleCode',
          type: 'string',
        },
        glAccountCode: {
          name: 'glAccountCode',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
        userIdCreated: {
          name: 'userIdCreated',
          type: 'string',
        },
        userIdModified: {
          name: 'userIdModified',
          type: 'string',
        },
        deleted: {
          name: 'deleted',
          type: 'boolean',
        },
        dateDeleted: {
          name: 'dateDeleted',
          type: 'Date',
        },
      },
      relations: {
        warranties: {
          name: 'warranties',
          type: 'Warranty[]',
          model: 'Warranty',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'packageId',
        },
      },
    };
  }
}
