<div *ngIf="supplyProducts && supplyProducts.length === 0">
  <hr />
  Geen accessoire gevonden
</div>

<div [hidden]="!supplyProducts || supplyProducts.length === 0">
  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col">Naam</th>
        <th scope="col">Code</th>
        <th scope="col">Beschrijving</th>
        <th scope="col">Prijs</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let supplyProduct of supplyProducts"
        class="pointer"
        (click)="onClick(supplyProduct)"
      >
        <td scope="row">
          {{ supplyProduct.name }}
        </td>
        <td scope="row">
          {{ supplyProduct.code }}
        </td>
        <td scope="row">
          {{ supplyProduct.description }}
        </td>
        <td scope="row">
          {{ supplyProduct.price | currency: 'EUR':'symbol':'1.0' }}
        </td>
        <td class="table-action"><i class="fas fa-angle-right"></i></td>
      </tr>
    </tbody>
  </table>
</div>
