/* tslint:disable */

declare var Object: any;
export interface DealerInterface {
  kvk: string;
  name: string;
  firstName?: string;
  surName?: string;
  email?: string;
  reference?: string;
  phoneNumber?: string;
  taxNumber?: string;
  cocNumber?: string;
  deliveryZipcode?: string;
  deliveryHouseNumber?: string;
  deliveryAddress?: string;
  deliveryCity?: string;
  invoiceZipcode?: string;
  invoiceHouseNumber?: string;
  invoiceAddress?: string;
  invoiceCity?: string;
  discounts?: Array<any>;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
}

export class Dealer implements DealerInterface {
  'kvk': string;
  'name': string;
  'firstName': string;
  'surName': string;
  'email': string;
  'reference': string;
  'phoneNumber': string;
  'taxNumber': string;
  'cocNumber': string;
  'deliveryZipcode': string;
  'deliveryHouseNumber': string;
  'deliveryAddress': string;
  'deliveryCity': string;
  'invoiceZipcode': string;
  'invoiceHouseNumber': string;
  'invoiceAddress': string;
  'invoiceCity': string;
  'discounts': Array<any>;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  constructor(data?: DealerInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Dealer`.
   */
  public static getModelName() {
    return 'Dealer';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Dealer for dynamic purposes.
   **/
  public static factory(data: DealerInterface): Dealer {
    return new Dealer(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Dealer',
      plural: 'Dealers',
      path: 'Dealers',
      idName: 'id',
      properties: {
        kvk: {
          name: 'kvk',
          type: 'string',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        firstName: {
          name: 'firstName',
          type: 'string',
        },
        surName: {
          name: 'surName',
          type: 'string',
        },
        email: {
          name: 'email',
          type: 'string',
        },
        reference: {
          name: 'reference',
          type: 'string',
        },
        phoneNumber: {
          name: 'phoneNumber',
          type: 'string',
        },
        taxNumber: {
          name: 'taxNumber',
          type: 'string',
        },
        cocNumber: {
          name: 'cocNumber',
          type: 'string',
        },
        deliveryZipcode: {
          name: 'deliveryZipcode',
          type: 'string',
        },
        deliveryHouseNumber: {
          name: 'deliveryHouseNumber',
          type: 'string',
        },
        deliveryAddress: {
          name: 'deliveryAddress',
          type: 'string',
        },
        deliveryCity: {
          name: 'deliveryCity',
          type: 'string',
        },
        invoiceZipcode: {
          name: 'invoiceZipcode',
          type: 'string',
        },
        invoiceHouseNumber: {
          name: 'invoiceHouseNumber',
          type: 'string',
        },
        invoiceAddress: {
          name: 'invoiceAddress',
          type: 'string',
        },
        invoiceCity: {
          name: 'invoiceCity',
          type: 'string',
        },
        discounts: {
          name: 'discounts',
          type: 'Array&lt;any&gt;',
          default: <any>[],
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {},
    };
  }
}
