/* tslint:disable */

declare var Object: any;
export interface UserModelInterface {
  firstName: string;
  insertion?: string;
  lastName: string;
  date_last_login?: Date;
  date_account_unlocked?: Date;
  date_account_disabled?: Date;
  divertEmail?: boolean;
  isDisabled?: boolean;
  realm?: string;
  username?: string;
  email: string;
  emailVerified?: boolean;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
  password?: string;
  accessTokens?: any[];
  roles?: any[];
  passwordHistory?: any[];
  internalNote?: any[];
  roleMappings?: any[];
}

export class UserModel implements UserModelInterface {
  'firstName': string;
  'insertion': string;
  'lastName': string;
  'date_last_login': Date;
  'date_account_unlocked': Date;
  'date_account_disabled': Date;
  'divertEmail': boolean;
  'isDisabled': boolean;
  'realm': string;
  'username': string;
  'email': string;
  'emailVerified': boolean;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  'password': string;
  accessTokens: any[];
  roles: any[];
  passwordHistory: any[];
  internalNote: any[];
  roleMappings: any[];
  constructor(data?: UserModelInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UserModel`.
   */
  public static getModelName() {
    return 'UserModel';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of UserModel for dynamic purposes.
   **/
  public static factory(data: UserModelInterface): UserModel {
    return new UserModel(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'UserModel',
      plural: 'UserModels',
      path: 'UserModels',
      idName: 'id',
      properties: {
        firstName: {
          name: 'firstName',
          type: 'string',
        },
        insertion: {
          name: 'insertion',
          type: 'string',
        },
        lastName: {
          name: 'lastName',
          type: 'string',
        },
        date_last_login: {
          name: 'date_last_login',
          type: 'Date',
        },
        date_account_unlocked: {
          name: 'date_account_unlocked',
          type: 'Date',
        },
        date_account_disabled: {
          name: 'date_account_disabled',
          type: 'Date',
        },
        divertEmail: {
          name: 'divertEmail',
          type: 'boolean',
          default: false,
        },
        isDisabled: {
          name: 'isDisabled',
          type: 'boolean',
          default: false,
        },
        realm: {
          name: 'realm',
          type: 'string',
        },
        username: {
          name: 'username',
          type: 'string',
        },
        email: {
          name: 'email',
          type: 'string',
        },
        emailVerified: {
          name: 'emailVerified',
          type: 'boolean',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
        password: {
          name: 'password',
          type: 'string',
        },
      },
      relations: {
        accessTokens: {
          name: 'accessTokens',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'userId',
        },
        roles: {
          name: 'roles',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          modelThrough: 'RoleMapping',
          keyThrough: 'roleId',
          keyFrom: 'id',
          keyTo: 'principalId',
        },
        passwordHistory: {
          name: 'passwordHistory',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'userId',
        },
        internalNote: {
          name: 'internalNote',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'orderId',
        },
        roleMappings: {
          name: 'roleMappings',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'principalId',
        },
      },
    };
  }
}
