import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NgxRolesService } from 'ngx-permissions';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { LoopBackConfig, InternalStorage } from './_sdk';
import { AppConfigurationService } from './_core/_services';
import { NgSelectConfig } from '@ng-select/ng-select';

@Component({
  selector: 'app-root',
  template: `
    <section *ngIf="testEnvironment" class="environment_identifier"></section>
    <notifier-container></notifier-container>
    <router-outlet></router-outlet>
  `,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribeAll = new Subject();
  public testEnvironment: boolean = environment.testEnvironment;

  constructor(
    @Inject(InternalStorage) protected storage: InternalStorage,
    private _rolesService: NgxRolesService,
    private appConfigurationService: AppConfigurationService,
    private ngSelectConfig: NgSelectConfig,
    private titleService: Title,
    public router: Router,
  ) {}

  ngOnInit(): void {
    LoopBackConfig.setBaseURL(environment.apiBaseUrl);
    LoopBackConfig.setApiVersion(environment.apiVersion);

    const cachedRoles = this.storage.get('roles');
    const roles =
      typeof cachedRoles === 'string' ? JSON.parse(cachedRoles) : cachedRoles;

    if (roles) {
      this._rolesService.flushRoles();
      Object.keys(roles).map(role =>
        this._rolesService.addRole(role, () => true),
      );
    }

    this.appConfigurationService.title$
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((title: string) => {
        this.titleService.setTitle(title);
      });

    this.appConfigurationService.setMobileClass();

    // Set global configuration for ng-select
    this.ngSelectConfig.notFoundText = 'Niets gevonden';
    this.ngSelectConfig.loadingText = 'Laden...';
    this.ngSelectConfig.clearAllText = 'Maak leeg';
    this.ngSelectConfig.typeToSearchText = 'Type om te zoeke';
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
