/* tslint:disable */
import { StandardProduct, DeliveryCondition } from '../index';

declare var Object: any;
export interface StandardProductDeliveryConditionInterface {
  deliveryConditionId: any;
  standardProductId: any;
  conditionType: string;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
  standardProduct?: StandardProduct;
  deliveryCondition?: DeliveryCondition;
}

export class StandardProductDeliveryCondition
  implements StandardProductDeliveryConditionInterface
{
  'deliveryConditionId': any;
  'standardProductId': any;
  'conditionType': string;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  standardProduct: StandardProduct;
  deliveryCondition: DeliveryCondition;
  constructor(data?: StandardProductDeliveryConditionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `StandardProductDeliveryCondition`.
   */
  public static getModelName() {
    return 'StandardProductDeliveryCondition';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of StandardProductDeliveryCondition for dynamic purposes.
   **/
  public static factory(
    data: StandardProductDeliveryConditionInterface,
  ): StandardProductDeliveryCondition {
    return new StandardProductDeliveryCondition(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'StandardProductDeliveryCondition',
      plural: 'StandardProductDeliveryConditions',
      path: 'StandardProductDeliveryConditions',
      idName: 'id',
      properties: {
        deliveryConditionId: {
          name: 'deliveryConditionId',
          type: 'any',
        },
        standardProductId: {
          name: 'standardProductId',
          type: 'any',
        },
        conditionType: {
          name: 'conditionType',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {
        standardProduct: {
          name: 'standardProduct',
          type: 'StandardProduct',
          model: 'StandardProduct',
          relationType: 'belongsTo',
          keyFrom: 'standardProductId',
          keyTo: 'id',
        },
        deliveryCondition: {
          name: 'deliveryCondition',
          type: 'DeliveryCondition',
          model: 'DeliveryCondition',
          relationType: 'belongsTo',
          keyFrom: 'deliveryConditionId',
          keyTo: 'id',
        },
      },
    };
  }
}
