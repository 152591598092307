/* tslint:disable */

declare var Object: any;
export interface ServiceResponsibilityInterface {
  code: string;
  name: string;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
}

export class ServiceResponsibility implements ServiceResponsibilityInterface {
  'code': string;
  'name': string;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  constructor(data?: ServiceResponsibilityInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ServiceResponsibility`.
   */
  public static getModelName() {
    return 'ServiceResponsibility';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ServiceResponsibility for dynamic purposes.
   **/
  public static factory(
    data: ServiceResponsibilityInterface,
  ): ServiceResponsibility {
    return new ServiceResponsibility(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ServiceResponsibility',
      plural: 'ServiceResponsibilities',
      path: 'ServiceResponsibilities',
      idName: 'id',
      properties: {
        code: {
          name: 'code',
          type: 'string',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {},
    };
  }
}
