/* tslint:disable */

declare var Object: any;
export interface TransactionStartDtoInterface {
  paymentMethod?: number;
  bank?: number;
  order?: any;
  supplyOrder?: any;
}

export class TransactionStartDto implements TransactionStartDtoInterface {
  'paymentMethod': number;
  'bank': number;
  'order': any;
  'supplyOrder': any;
  constructor(data?: TransactionStartDtoInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TransactionStartDto`.
   */
  public static getModelName() {
    return 'TransactionStartDto';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of TransactionStartDto for dynamic purposes.
   **/
  public static factory(
    data: TransactionStartDtoInterface,
  ): TransactionStartDto {
    return new TransactionStartDto(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'TransactionStartDto',
      plural: 'TransactionStartDtos',
      path: 'TransactionStartDtos',
      idName: '',
      properties: {
        paymentMethod: {
          name: 'paymentMethod',
          type: 'number',
        },
        bank: {
          name: 'bank',
          type: 'number',
        },
        order: {
          name: 'order',
          type: 'any',
        },
        supplyOrder: {
          name: 'supplyOrder',
          type: 'any',
        },
      },
      relations: {},
    };
  }
}
