/* tslint:disable */

declare var Object: any;
export interface ProductieStaatConfigurationVersionInterface {
  name: string;
  active: boolean;
  disabled: boolean;
  id?: any;
  parentVersionId?: any;
  dateCreated?: Date;
  dateModified?: Date;
  userIdCreated?: string;
  userIdModified?: string;
  parentVersion?: any;
}

export class ProductieStaatConfigurationVersion
  implements ProductieStaatConfigurationVersionInterface
{
  'name': string;
  'active': boolean;
  'disabled': boolean;
  'id': any;
  'parentVersionId': any;
  'dateCreated': Date;
  'dateModified': Date;
  'userIdCreated': string;
  'userIdModified': string;
  parentVersion: any;
  constructor(data?: ProductieStaatConfigurationVersionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ProductieStaatConfigurationVersion`.
   */
  public static getModelName() {
    return 'ProductieStaatConfigurationVersion';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ProductieStaatConfigurationVersion for dynamic purposes.
   **/
  public static factory(
    data: ProductieStaatConfigurationVersionInterface,
  ): ProductieStaatConfigurationVersion {
    return new ProductieStaatConfigurationVersion(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ProductieStaatConfigurationVersion',
      plural: 'ProductieStaatConfigurationVersions',
      path: 'ProductieStaatConfigurationVersions',
      idName: 'id',
      properties: {
        name: {
          name: 'name',
          type: 'string',
        },
        active: {
          name: 'active',
          type: 'boolean',
          default: false,
        },
        disabled: {
          name: 'disabled',
          type: 'boolean',
          default: false,
        },
        id: {
          name: 'id',
          type: 'any',
        },
        parentVersionId: {
          name: 'parentVersionId',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
        userIdCreated: {
          name: 'userIdCreated',
          type: 'string',
        },
        userIdModified: {
          name: 'userIdModified',
          type: 'string',
        },
      },
      relations: {
        parentVersion: {
          name: 'parentVersion',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'parentVersionId',
          keyTo: 'id',
        },
      },
    };
  }
}
