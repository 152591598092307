/* tslint:disable */

declare var Object: any;
export interface ExactLogInterface {
  model?: string;
  instanceId: any;
  method: string;
  type: string;
  description?: string;
  requestData?: any;
  responseData?: any;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
  modelInstance?: any;
}

export class ExactLog implements ExactLogInterface {
  'model': string;
  'instanceId': any;
  'method': string;
  'type': string;
  'description': string;
  'requestData': any;
  'responseData': any;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  modelInstance: any;
  constructor(data?: ExactLogInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ExactLog`.
   */
  public static getModelName() {
    return 'ExactLog';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ExactLog for dynamic purposes.
   **/
  public static factory(data: ExactLogInterface): ExactLog {
    return new ExactLog(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ExactLog',
      plural: 'ExactLogs',
      path: 'ExactLogs',
      idName: 'id',
      properties: {
        model: {
          name: 'model',
          type: 'string',
        },
        instanceId: {
          name: 'instanceId',
          type: 'any',
        },
        method: {
          name: 'method',
          type: 'string',
        },
        type: {
          name: 'type',
          type: 'string',
        },
        description: {
          name: 'description',
          type: 'string',
        },
        requestData: {
          name: 'requestData',
          type: 'any',
        },
        responseData: {
          name: 'responseData',
          type: 'any',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {
        modelInstance: {
          name: 'modelInstance',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'instanceId',
          keyTo: 'id',
        },
      },
    };
  }
}
