import { Router, CanLoad, Route } from '@angular/router';
//////////////////////////////
//                          //
//        App Imports       //
//                          //
//////////////////////////////
import { UserModelApi, LoopBackAuth } from '../../_sdk';
import * as i0 from "@angular/core";
import * as i1 from "../../_sdk/services/custom/UserModel";
import * as i2 from "../../_sdk/services/core/auth.service";
import * as i3 from "@angular/router";
export class LoadGuard {
    constructor(auth, lbAuth, router) {
        this.auth = auth;
        this.lbAuth = lbAuth;
        this.router = router;
    }
    canLoad(route) {
        return true;
    }
}
LoadGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoadGuard_Factory() { return new LoadGuard(i0.ɵɵinject(i1.UserModelApi), i0.ɵɵinject(i2.LoopBackAuth), i0.ɵɵinject(i3.Router)); }, token: LoadGuard, providedIn: "root" });
