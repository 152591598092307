import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
export class CustomerServiceMessageModalComponent {
    constructor(activeModal) {
        this.activeModal = activeModal;
        this.customerServiceMessage = '';
    }
    get isValid() {
        return this.customerServiceMessage.length > 0;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () { });
    }
    save() {
        this.activeModal.close({
            sendMessage: 'confirm',
            customerServiceMessage: this.customerServiceMessage,
        });
    }
    reject() {
        this.activeModal.close();
    }
}
