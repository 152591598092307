/* tslint:disable */

declare var Object: any;
export interface OptionTemplateInterface {
  type: string;
  typeRestriction?: any;
  price: number;
  selfAssemblyDiscount?: number;
  name: string;
  slug?: string;
  code?: string;
  description?: string;
  imageId?: string;
  measurements?: any;
  configuration?: any;
  productionDays?: any;
  productionTime?: any;
  exactMaterialDays?: any;
  isCuttableGlass: boolean;
  machineCodeGlass?: string;
  disabled: boolean;
  disabledOnOrderConfirmation: boolean;
  disabledForExternalUsers: boolean;
  default: boolean;
  order: number;
  carrouselImageIds?: Array<any>;
  carrouselImages?: Array<any>;
  exclude?: any;
  bumbalMeasurementDays?: number;
  bumbalPlacementDays?: number;
  bumbalShipmentDays?: number;
  bumbalPlacementTime?: number;
  bumbalShipmentTime?: number;
  bumbalLevel?: number;
  crossSoldOptions?: Array<any>;
  disclaimerOnOrderConfirmation?: string;
  disclaimerOnMeasurements?: string;
  exactArticleCode?: string;
  exactServiceArticleCode?: string;
  itemIconColor?: string;
  colorMatchingGlassId?: any;
  groupLabel?: string;
  minBeamCount?: number;
  threeDColor?: string;
  threeDOpacity?: number;
  threeDTextureFileId?: string;
  threeDModelFileId?: string;
  threeDMetalness?: number;
  threeDRoughness?: number;
  carouselImageAltTexts?: Array<any>;
  mainImageAltText?: string;
  isCornerWallable: boolean;
  material?: any;
  isGewoonGers?: boolean;
  isESD?: boolean;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
  userIdCreated?: string;
  userIdModified?: string;
  colorMatchingGlass?: any;
  priceCategoryItems?: any[];
}

export class OptionTemplate implements OptionTemplateInterface {
  'type': string;
  'typeRestriction': any;
  'price': number;
  'selfAssemblyDiscount': number;
  'name': string;
  'slug': string;
  'code': string;
  'description': string;
  'imageId': string;
  'measurements': any;
  'configuration': any;
  'productionDays': any;
  'productionTime': any;
  'exactMaterialDays': any;
  'isCuttableGlass': boolean;
  'machineCodeGlass': string;
  'disabled': boolean;
  'disabledOnOrderConfirmation': boolean;
  'disabledForExternalUsers': boolean;
  'default': boolean;
  'order': number;
  'carrouselImageIds': Array<any>;
  'carrouselImages': Array<any>;
  'exclude': any;
  'bumbalMeasurementDays': number;
  'bumbalPlacementDays': number;
  'bumbalShipmentDays': number;
  'bumbalPlacementTime': number;
  'bumbalShipmentTime': number;
  'bumbalLevel': number;
  'crossSoldOptions': Array<any>;
  'disclaimerOnOrderConfirmation': string;
  'disclaimerOnMeasurements': string;
  'exactArticleCode': string;
  'exactServiceArticleCode': string;
  'itemIconColor': string;
  'colorMatchingGlassId': any;
  'groupLabel': string;
  'minBeamCount': number;
  'threeDColor': string;
  'threeDOpacity': number;
  'threeDTextureFileId': string;
  'threeDModelFileId': string;
  'threeDMetalness': number;
  'threeDRoughness': number;
  'carouselImageAltTexts': Array<any>;
  'mainImageAltText': string;
  'isCornerWallable': boolean;
  'material': any;
  'isGewoonGers': boolean;
  'isESD': boolean;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  'userIdCreated': string;
  'userIdModified': string;
  colorMatchingGlass: any;
  priceCategoryItems: any[];
  constructor(data?: OptionTemplateInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `OptionTemplate`.
   */
  public static getModelName() {
    return 'OptionTemplate';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of OptionTemplate for dynamic purposes.
   **/
  public static factory(data: OptionTemplateInterface): OptionTemplate {
    return new OptionTemplate(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'OptionTemplate',
      plural: 'OptionTemplates',
      path: 'OptionTemplates',
      idName: 'id',
      properties: {
        type: {
          name: 'type',
          type: 'string',
        },
        typeRestriction: {
          name: 'typeRestriction',
          type: 'any',
          default: <any>null,
        },
        price: {
          name: 'price',
          type: 'number',
          default: 0,
        },
        selfAssemblyDiscount: {
          name: 'selfAssemblyDiscount',
          type: 'number',
          default: 0,
        },
        name: {
          name: 'name',
          type: 'string',
        },
        slug: {
          name: 'slug',
          type: 'string',
        },
        code: {
          name: 'code',
          type: 'string',
        },
        description: {
          name: 'description',
          type: 'string',
          default: '',
        },
        imageId: {
          name: 'imageId',
          type: 'string',
        },
        measurements: {
          name: 'measurements',
          type: 'any',
          default: <any>null,
        },
        configuration: {
          name: 'configuration',
          type: 'any',
          default: <any>null,
        },
        productionDays: {
          name: 'productionDays',
          type: 'any',
          default: <any>null,
        },
        productionTime: {
          name: 'productionTime',
          type: 'any',
          default: <any>null,
        },
        exactMaterialDays: {
          name: 'exactMaterialDays',
          type: 'any',
          default: <any>null,
        },
        isCuttableGlass: {
          name: 'isCuttableGlass',
          type: 'boolean',
          default: false,
        },
        machineCodeGlass: {
          name: 'machineCodeGlass',
          type: 'string',
        },
        disabled: {
          name: 'disabled',
          type: 'boolean',
          default: false,
        },
        disabledOnOrderConfirmation: {
          name: 'disabledOnOrderConfirmation',
          type: 'boolean',
          default: false,
        },
        disabledForExternalUsers: {
          name: 'disabledForExternalUsers',
          type: 'boolean',
          default: false,
        },
        default: {
          name: 'default',
          type: 'boolean',
          default: false,
        },
        order: {
          name: 'order',
          type: 'number',
          default: 1,
        },
        carrouselImageIds: {
          name: 'carrouselImageIds',
          type: 'Array&lt;any&gt;',
          default: <any>[],
        },
        carrouselImages: {
          name: 'carrouselImages',
          type: 'Array&lt;any&gt;',
        },
        exclude: {
          name: 'exclude',
          type: 'any',
          default: <any>null,
        },
        bumbalMeasurementDays: {
          name: 'bumbalMeasurementDays',
          type: 'number',
        },
        bumbalPlacementDays: {
          name: 'bumbalPlacementDays',
          type: 'number',
        },
        bumbalShipmentDays: {
          name: 'bumbalShipmentDays',
          type: 'number',
        },
        bumbalPlacementTime: {
          name: 'bumbalPlacementTime',
          type: 'number',
        },
        bumbalShipmentTime: {
          name: 'bumbalShipmentTime',
          type: 'number',
        },
        bumbalLevel: {
          name: 'bumbalLevel',
          type: 'number',
        },
        crossSoldOptions: {
          name: 'crossSoldOptions',
          type: 'Array&lt;any&gt;',
          default: <any>[],
        },
        disclaimerOnOrderConfirmation: {
          name: 'disclaimerOnOrderConfirmation',
          type: 'string',
          default: '',
        },
        disclaimerOnMeasurements: {
          name: 'disclaimerOnMeasurements',
          type: 'string',
          default: '',
        },
        exactArticleCode: {
          name: 'exactArticleCode',
          type: 'string',
        },
        exactServiceArticleCode: {
          name: 'exactServiceArticleCode',
          type: 'string',
        },
        itemIconColor: {
          name: 'itemIconColor',
          type: 'string',
        },
        colorMatchingGlassId: {
          name: 'colorMatchingGlassId',
          type: 'any',
        },
        groupLabel: {
          name: 'groupLabel',
          type: 'string',
        },
        minBeamCount: {
          name: 'minBeamCount',
          type: 'number',
          default: 0,
        },
        threeDColor: {
          name: 'threeDColor',
          type: 'string',
        },
        threeDOpacity: {
          name: 'threeDOpacity',
          type: 'number',
        },
        threeDTextureFileId: {
          name: 'threeDTextureFileId',
          type: 'string',
        },
        threeDModelFileId: {
          name: 'threeDModelFileId',
          type: 'string',
        },
        threeDMetalness: {
          name: 'threeDMetalness',
          type: 'number',
        },
        threeDRoughness: {
          name: 'threeDRoughness',
          type: 'number',
        },
        carouselImageAltTexts: {
          name: 'carouselImageAltTexts',
          type: 'Array&lt;any&gt;',
        },
        mainImageAltText: {
          name: 'mainImageAltText',
          type: 'string',
        },
        isCornerWallable: {
          name: 'isCornerWallable',
          type: 'boolean',
          default: false,
        },
        material: {
          name: 'material',
          type: 'any',
          default: <any>null,
        },
        isGewoonGers: {
          name: 'isGewoonGers',
          type: 'boolean',
          default: true,
        },
        isESD: {
          name: 'isESD',
          type: 'boolean',
          default: true,
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
        userIdCreated: {
          name: 'userIdCreated',
          type: 'string',
        },
        userIdModified: {
          name: 'userIdModified',
          type: 'string',
        },
      },
      relations: {
        colorMatchingGlass: {
          name: 'colorMatchingGlass',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'colorMatchingGlassId',
          keyTo: 'id',
        },
        priceCategoryItems: {
          name: 'priceCategoryItems',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'optionTemplateId',
        },
      },
    };
  }
}
