/* tslint:disable */
import {
  ProductieStaat,
  Flag,
  UserModel,
  ServiceProduct,
  ServiceCategory,
  InternalNote,
  Correspondence,
  ExactLog,
  DeliveryForm,
  ServiceStandardProduct,
} from '../index';

declare var Object: any;
export interface ServiceInterface {
  orderId: any;
  orderType?: string;
  serviceNumber: string;
  serviceCategoryId?: any;
  status: string;
  description?: string;
  solution?: string;
  isWarranty?: boolean;
  enableOneTimeVoucher?: boolean;
  oneTimeVoucherEmailSent?: boolean;
  images?: Array<any>;
  videos?: Array<any>;
  serviceTypes?: Array<any>;
  serviceMaterials?: Array<any>;
  correspondence?: Array<any>;
  assemblerAmount: number;
  originalMeasurementEmployee?: string;
  originalDeliveryEmployee?: string;
  originalShipmentEmployee?: string;
  exactSalesOrderId?: string;
  bumbalPlacementDays?: number;
  actualBumbalPlacementDay?: string;
  delivery?: any;
  retourDeliveryDate?: Date;
  mendrixOrderId?: number;
  mendrixRetourOrderId?: number;
  id?: any;
  flagId?: any;
  userIdCreated?: string;
  userIdModified?: string;
  dateCreated?: Date;
  dateModified?: Date;
  exactInvoicedAmount?: number;
  exactSalesInvoiceIds?: Array<any>;
  exactSalesInvoiceAttachmentIds?: Array<any>;
  exactInvoiceRemark?: string;
  bumbalDeliveryId?: string;
  bumbalDeliveryExtraTime?: number;
  bumbalDeliveryTotalTime?: number;
  deliveryDate?: Date;
  bumbalDeliveryEmployee?: string;
  bumbalDeliveryStatus?: string;
  appointmentCosts?: Array<any>;
  deliveryCosts?: number;
  bumbalFlow?: string;
  pickupWarehouse?: string;
  dhlDraftId?: string;
  order?: any;
  productieStaat?: ProductieStaat[];
  flag?: Flag;
  userCreated?: UserModel;
  userModified?: UserModel;
  products?: ServiceProduct[];
  serviceCategory?: ServiceCategory;
  internalNote?: InternalNote[];
  correspondences?: Correspondence[];
  exactLogs?: ExactLog[];
  deliveryForm?: DeliveryForm;
  standardProducts?: ServiceStandardProduct[];
}

export class Service implements ServiceInterface {
  'orderId': any;
  'orderType': string;
  'serviceNumber': string;
  'serviceCategoryId': any;
  'status': string;
  'description': string;
  'solution': string;
  'isWarranty': boolean;
  'enableOneTimeVoucher': boolean;
  'oneTimeVoucherEmailSent': boolean;
  'images': Array<any>;
  'videos': Array<any>;
  'serviceTypes': Array<any>;
  'serviceMaterials': Array<any>;
  'correspondence': Array<any>;
  'assemblerAmount': number;
  'originalMeasurementEmployee': string;
  'originalDeliveryEmployee': string;
  'originalShipmentEmployee': string;
  'exactSalesOrderId': string;
  'bumbalPlacementDays': number;
  'actualBumbalPlacementDay': string;
  'delivery': any;
  'retourDeliveryDate': Date;
  'mendrixOrderId': number;
  'mendrixRetourOrderId': number;
  'id': any;
  'flagId': any;
  'userIdCreated': string;
  'userIdModified': string;
  'dateCreated': Date;
  'dateModified': Date;
  'exactInvoicedAmount': number;
  'exactSalesInvoiceIds': Array<any>;
  'exactSalesInvoiceAttachmentIds': Array<any>;
  'exactInvoiceRemark': string;
  'bumbalDeliveryId': string;
  'bumbalDeliveryExtraTime': number;
  'bumbalDeliveryTotalTime': number;
  'deliveryDate': Date;
  'bumbalDeliveryEmployee': string;
  'bumbalDeliveryStatus': string;
  'appointmentCosts': Array<any>;
  'deliveryCosts': number;
  'bumbalFlow': string;
  'pickupWarehouse': string;
  'dhlDraftId': string;
  order: any;
  productieStaat: ProductieStaat[];
  flag: Flag;
  userCreated: UserModel;
  userModified: UserModel;
  products: ServiceProduct[];
  serviceCategory: ServiceCategory;
  internalNote: InternalNote[];
  correspondences: Correspondence[];
  exactLogs: ExactLog[];
  deliveryForm: DeliveryForm;
  standardProducts: ServiceStandardProduct[];
  constructor(data?: ServiceInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Service`.
   */
  public static getModelName() {
    return 'Service';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Service for dynamic purposes.
   **/
  public static factory(data: ServiceInterface): Service {
    return new Service(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Service',
      plural: 'Services',
      path: 'Services',
      idName: 'id',
      properties: {
        orderId: {
          name: 'orderId',
          type: 'any',
        },
        orderType: {
          name: 'orderType',
          type: 'string',
        },
        serviceNumber: {
          name: 'serviceNumber',
          type: 'string',
        },
        serviceCategoryId: {
          name: 'serviceCategoryId',
          type: 'any',
        },
        status: {
          name: 'status',
          type: 'string',
          default: 'in_progress',
        },
        description: {
          name: 'description',
          type: 'string',
        },
        solution: {
          name: 'solution',
          type: 'string',
        },
        isWarranty: {
          name: 'isWarranty',
          type: 'boolean',
          default: false,
        },
        enableOneTimeVoucher: {
          name: 'enableOneTimeVoucher',
          type: 'boolean',
          default: false,
        },
        oneTimeVoucherEmailSent: {
          name: 'oneTimeVoucherEmailSent',
          type: 'boolean',
          default: false,
        },
        images: {
          name: 'images',
          type: 'Array&lt;any&gt;',
        },
        videos: {
          name: 'videos',
          type: 'Array&lt;any&gt;',
        },
        serviceTypes: {
          name: 'serviceTypes',
          type: 'Array&lt;any&gt;',
          default: <any>[],
        },
        serviceMaterials: {
          name: 'serviceMaterials',
          type: 'Array&lt;any&gt;',
          default: <any>[],
        },
        correspondence: {
          name: 'correspondence',
          type: 'Array&lt;any&gt;',
        },
        assemblerAmount: {
          name: 'assemblerAmount',
          type: 'number',
        },
        originalMeasurementEmployee: {
          name: 'originalMeasurementEmployee',
          type: 'string',
        },
        originalDeliveryEmployee: {
          name: 'originalDeliveryEmployee',
          type: 'string',
        },
        originalShipmentEmployee: {
          name: 'originalShipmentEmployee',
          type: 'string',
        },
        exactSalesOrderId: {
          name: 'exactSalesOrderId',
          type: 'string',
        },
        bumbalPlacementDays: {
          name: 'bumbalPlacementDays',
          type: 'number',
        },
        actualBumbalPlacementDay: {
          name: 'actualBumbalPlacementDay',
          type: 'string',
        },
        delivery: {
          name: 'delivery',
          type: 'any',
        },
        retourDeliveryDate: {
          name: 'retourDeliveryDate',
          type: 'Date',
        },
        mendrixOrderId: {
          name: 'mendrixOrderId',
          type: 'number',
        },
        mendrixRetourOrderId: {
          name: 'mendrixRetourOrderId',
          type: 'number',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        flagId: {
          name: 'flagId',
          type: 'any',
        },
        userIdCreated: {
          name: 'userIdCreated',
          type: 'string',
        },
        userIdModified: {
          name: 'userIdModified',
          type: 'string',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
        exactInvoicedAmount: {
          name: 'exactInvoicedAmount',
          type: 'number',
        },
        exactSalesInvoiceIds: {
          name: 'exactSalesInvoiceIds',
          type: 'Array&lt;any&gt;',
        },
        exactSalesInvoiceAttachmentIds: {
          name: 'exactSalesInvoiceAttachmentIds',
          type: 'Array&lt;any&gt;',
        },
        exactInvoiceRemark: {
          name: 'exactInvoiceRemark',
          type: 'string',
        },
        bumbalDeliveryId: {
          name: 'bumbalDeliveryId',
          type: 'string',
        },
        bumbalDeliveryExtraTime: {
          name: 'bumbalDeliveryExtraTime',
          type: 'number',
          default: 0,
        },
        bumbalDeliveryTotalTime: {
          name: 'bumbalDeliveryTotalTime',
          type: 'number',
        },
        deliveryDate: {
          name: 'deliveryDate',
          type: 'Date',
        },
        bumbalDeliveryEmployee: {
          name: 'bumbalDeliveryEmployee',
          type: 'string',
        },
        bumbalDeliveryStatus: {
          name: 'bumbalDeliveryStatus',
          type: 'string',
        },
        appointmentCosts: {
          name: 'appointmentCosts',
          type: 'Array&lt;any&gt;',
        },
        deliveryCosts: {
          name: 'deliveryCosts',
          type: 'number',
          default: 0,
        },
        bumbalFlow: {
          name: 'bumbalFlow',
          type: 'string',
          default: 'default',
        },
        pickupWarehouse: {
          name: 'pickupWarehouse',
          type: 'string',
        },
        dhlDraftId: {
          name: 'dhlDraftId',
          type: 'string',
        },
      },
      relations: {
        order: {
          name: 'order',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'orderId',
          keyTo: 'id',
        },
        productieStaat: {
          name: 'productieStaat',
          type: 'ProductieStaat[]',
          model: 'ProductieStaat',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'serviceId',
        },
        flag: {
          name: 'flag',
          type: 'Flag',
          model: 'Flag',
          relationType: 'belongsTo',
          keyFrom: 'flagId',
          keyTo: 'id',
        },
        userCreated: {
          name: 'userCreated',
          type: 'UserModel',
          model: 'UserModel',
          relationType: 'belongsTo',
          keyFrom: 'userIdCreated',
          keyTo: 'id',
        },
        userModified: {
          name: 'userModified',
          type: 'UserModel',
          model: 'UserModel',
          relationType: 'belongsTo',
          keyFrom: 'userIdModified',
          keyTo: 'id',
        },
        products: {
          name: 'products',
          type: 'ServiceProduct[]',
          model: 'ServiceProduct',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'serviceId',
        },
        serviceCategory: {
          name: 'serviceCategory',
          type: 'ServiceCategory',
          model: 'ServiceCategory',
          relationType: 'belongsTo',
          keyFrom: 'serviceCategoryId',
          keyTo: 'id',
        },
        internalNote: {
          name: 'internalNote',
          type: 'InternalNote[]',
          model: 'InternalNote',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'linkId',
        },
        correspondences: {
          name: 'correspondences',
          type: 'Correspondence[]',
          model: 'Correspondence',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'fkId',
        },
        exactLogs: {
          name: 'exactLogs',
          type: 'ExactLog[]',
          model: 'ExactLog',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'instanceId',
        },
        deliveryForm: {
          name: 'deliveryForm',
          type: 'DeliveryForm',
          model: 'DeliveryForm',
          relationType: 'hasOne',
          keyFrom: 'id',
          keyTo: 'modelId',
        },
        standardProducts: {
          name: 'standardProducts',
          type: 'ServiceStandardProduct[]',
          model: 'ServiceStandardProduct',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'serviceId',
        },
      },
    };
  }
}
