/* tslint:disable */

declare var Object: any;
export interface StandardOrderDeliveryInterface {
  type?: string;
  date?: Date;
}

export class StandardOrderDelivery implements StandardOrderDeliveryInterface {
  'type': string;
  'date': Date;
  constructor(data?: StandardOrderDeliveryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `StandardOrderDelivery`.
   */
  public static getModelName() {
    return 'StandardOrderDelivery';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of StandardOrderDelivery for dynamic purposes.
   **/
  public static factory(
    data: StandardOrderDeliveryInterface,
  ): StandardOrderDelivery {
    return new StandardOrderDelivery(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'StandardOrderDelivery',
      plural: 'StandardOrderDeliveries',
      path: 'StandardOrderDeliveries',
      idName: 'id',
      properties: {
        type: {
          name: 'type',
          type: 'string',
        },
        date: {
          name: 'date',
          type: 'Date',
        },
      },
      relations: {},
    };
  }
}
