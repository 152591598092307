/* tslint:disable */

declare var Object: any;
export interface StatusFlowInterface {
  saw?: any;
  drill?: any;
  compile?: any;
  assemble?: any;
  final_check?: any;
  orderpicking?: any;
  glass_cutter?: any;
  load?: any;
}

export class StatusFlow implements StatusFlowInterface {
  'saw': any;
  'drill': any;
  'compile': any;
  'assemble': any;
  'final_check': any;
  'orderpicking': any;
  'glass_cutter': any;
  'load': any;
  constructor(data?: StatusFlowInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `StatusFlow`.
   */
  public static getModelName() {
    return 'StatusFlow';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of StatusFlow for dynamic purposes.
   **/
  public static factory(data: StatusFlowInterface): StatusFlow {
    return new StatusFlow(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'StatusFlow',
      plural: 'StatusFlows',
      path: 'StatusFlows',
      idName: 'id',
      properties: {
        saw: {
          name: 'saw',
          type: 'any',
        },
        drill: {
          name: 'drill',
          type: 'any',
        },
        compile: {
          name: 'compile',
          type: 'any',
        },
        assemble: {
          name: 'assemble',
          type: 'any',
        },
        final_check: {
          name: 'final_check',
          type: 'any',
        },
        orderpicking: {
          name: 'orderpicking',
          type: 'any',
        },
        glass_cutter: {
          name: 'glass_cutter',
          type: 'any',
        },
        load: {
          name: 'load',
          type: 'any',
        },
      },
      relations: {},
    };
  }
}
