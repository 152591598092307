/* tslint:disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi } from '../core/base.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackAuth } from '../core/auth.service';
import { LoopBackFilter } from '../../models/BaseModels';
import { ErrorHandler } from '../core/error.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Rack } from '../../models/Rack';
import { SocketConnection } from '../../sockets/socket.connections';
import { RackSlot } from '../../models/RackSlot';
import { ProductieStaat } from '../../models/ProductieStaat';

/**
 * Api services for the `Rack` model.
 */
@Injectable()
export class RackApi extends BaseLoopBackApi {
  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SocketConnection) protected connection: SocketConnection,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler,
  ) {
    super(http, connection, models, auth, errorHandler);
  }

  /**
   * Find a related item by id for productieStaten.
   *
   * @param {any} id Rack id
   *
   * @param {any} fk Foreign key for productieStaten
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Rack` object.)
   * </em>
   */
  public findByIdProductieStaten(
    id: any,
    fk: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/Racks/:id/productieStaten/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Delete a related item by id for productieStaten.
   *
   * @param {any} id Rack id
   *
   * @param {any} fk Foreign key for productieStaten
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdProductieStaten(
    id: any,
    fk: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/Racks/:id/productieStaten/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Update a related item by id for productieStaten.
   *
   * @param {any} id Rack id
   *
   * @param {any} fk Foreign key for productieStaten
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Rack` object.)
   * </em>
   */
  public updateByIdProductieStaten(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/Racks/:id/productieStaten/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Add a related item by id for productieStaten.
   *
   * @param {any} id Rack id
   *
   * @param {any} fk Foreign key for productieStaten
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Rack` object.)
   * </em>
   */
  public linkProductieStaten(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/Racks/:id/productieStaten/rel/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Remove the productieStaten relation to an item by id.
   *
   * @param {any} id Rack id
   *
   * @param {any} fk Foreign key for productieStaten
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public unlinkProductieStaten(
    id: any,
    fk: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/Racks/:id/productieStaten/rel/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Check the existence of productieStaten relation to an item by id.
   *
   * @param {any} id Rack id
   *
   * @param {any} fk Foreign key for productieStaten
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Rack` object.)
   * </em>
   */
  public existsProductieStaten(
    id: any,
    fk: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'HEAD';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/Racks/:id/productieStaten/rel/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Find a related item by id for rackSlots.
   *
   * @param {any} id Rack id
   *
   * @param {any} fk Foreign key for rackSlots
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Rack` object.)
   * </em>
   */
  public findByIdRackSlots(
    id: any,
    fk: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/Racks/:id/rackSlots/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Delete a related item by id for rackSlots.
   *
   * @param {any} id Rack id
   *
   * @param {any} fk Foreign key for rackSlots
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdRackSlots(
    id: any,
    fk: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/Racks/:id/rackSlots/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Update a related item by id for rackSlots.
   *
   * @param {any} id Rack id
   *
   * @param {any} fk Foreign key for rackSlots
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Rack` object.)
   * </em>
   */
  public updateByIdRackSlots(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/Racks/:id/rackSlots/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Queries productieStaten of Rack.
   *
   * @param {any} id Rack id
   *
   * @param {object} filter
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Rack` object.)
   * </em>
   */
  public getProductieStaten(
    id: any,
    filter: LoopBackFilter = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/Racks/:id/productieStaten';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null)
      _urlParams.filter = filter;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Creates a new instance in productieStaten of this model.
   *
   * @param {any} id Rack id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Rack` object.)
   * </em>
   */
  public createProductieStaten(
    id: any,
    data: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/Racks/:id/productieStaten';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Deletes all productieStaten of this model.
   *
   * @param {any} id Rack id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteProductieStaten(
    id: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/Racks/:id/productieStaten';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Counts productieStaten of Rack.
   *
   * @param {any} id Rack id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` -
   */
  public countProductieStaten(
    id: any,
    where: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/Racks/:id/productieStaten/count';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null)
      _urlParams.where = where;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Queries rackSlots of Rack.
   *
   * @param {any} id Rack id
   *
   * @param {object} filter
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Rack` object.)
   * </em>
   */
  public getRackSlots(
    id: any,
    filter: LoopBackFilter = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/Racks/:id/rackSlots';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null)
      _urlParams.filter = filter;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Creates a new instance in rackSlots of this model.
   *
   * @param {any} id Rack id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Rack` object.)
   * </em>
   */
  public createRackSlots(
    id: any,
    data: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/Racks/:id/rackSlots';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Deletes all rackSlots of this model.
   *
   * @param {any} id Rack id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteRackSlots(id: any, customHeaders?: Function): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/Racks/:id/rackSlots';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Counts rackSlots of Rack.
   *
   * @param {any} id Rack id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` -
   */
  public countRackSlots(
    id: any,
    where: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/Racks/:id/rackSlots/count';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null)
      _urlParams.where = where;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Patch an existing model instance or insert a new one into the data source.
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - Model instance data
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Rack` object.)
   * </em>
   */
  public patchOrCreate(
    data: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'PATCH';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/Racks';
    let _routeParams: any = {};
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   * @param {any} id Rack id
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - An object of model property name/value pairs
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Rack` object.)
   * </em>
   */
  public patchAttributes(
    id: any,
    data: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'PATCH';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/Racks/:id';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Get the configs of a rack
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Rack` object.)
   * </em>
   */
  public getConfigs(customHeaders?: Function): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/Racks/getConfigs';
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Creates a new instance in productieStaten of this model.
   *
   * @param {any} id Rack id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Rack` object.)
   * </em>
   */
  public createManyProductieStaten(
    id: any,
    data: any[] = [],
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/Racks/:id/productieStaten';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Creates a new instance in rackSlots of this model.
   *
   * @param {any} id Rack id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Rack` object.)
   * </em>
   */
  public createManyRackSlots(
    id: any,
    data: any[] = [],
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/Racks/:id/rackSlots';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Rack`.
   */
  public getModelName() {
    return 'Rack';
  }
}
