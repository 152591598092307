/* tslint:disable */
import {
  ShoppingCartProduct,
  ShoppingCartSupplyProduct,
  ShoppingCartStandardProduct,
  InternalNote,
  Correspondence,
} from '../index';

declare var Object: any;
export interface ShoppingCartInterface {
  shoppingCartId?: string;
  orderId?: any;
  standardOrderId?: any;
  email: string;
  wordpressCallbackStatus: boolean;
  sampleSaleReferenceNumber?: string;
  sampleSaleRotation?: string;
  sampleSaleMeasurement?: string;
  sampleSaleRabbetSize?: string;
  saleLocation?: string;
  notes?: string;
  warrantyPackageId?: any;
  selectedReasonDeleted?: string;
  isSelfAssembly?: boolean;
  sampleSaleUUID?: string;
  deliveryCosts?: number;
  delivery?: any;
  status: string;
  discountRecords?: Array<any>;
  extraProductDiscount?: number;
  disableCommunication: boolean;
  id?: any;
  flagId?: any;
  dateCreated?: Date;
  dateModified?: Date;
  userIdCreated?: string;
  userIdModified?: string;
  firstName?: string;
  insertion?: string;
  lastName?: string;
  emailAddress?: string;
  shippingStreet?: string;
  shippingHouseNumber?: string;
  shippingZip?: string;
  shippingCity?: string;
  shippingPhone?: string;
  shippingProvince?: string;
  shippingMunicipality?: string;
  shippingLocation?: any;
  invoiceStreet?: string;
  invoiceHouseNumber?: string;
  invoiceZip?: string;
  invoiceCity?: string;
  invoiceEmail?: string;
  invoicePhone?: string;
  invoiceCompany?: string;
  invoiceReference?: string;
  invoiceVAT?: string;
  invoiceProvince?: string;
  invoiceMunicipality?: string;
  invoiceLocation?: any;
  exactAccountId?: string;
  callAppointment?: Date;
  shoppingCartNumber: string;
  deleted?: boolean;
  dateDeleted?: Date;
  bumbalDeliveryId?: string;
  bumbalDeliveryExtraTime?: number;
  bumbalDeliveryTotalTime?: number;
  deliveryDate?: Date;
  bumbalDeliveryEmployee?: string;
  bumbalDeliveryStatus?: string;
  appointmentCosts?: Array<any>;
  bumbalFlow?: string;
  bumbalShipmentId?: string;
  bumbalShipmentExtraTime?: number;
  bumbalShipmentTotalTime?: number;
  shipmentDate?: Date;
  bumbalShipmentEmployee?: string;
  bumbalShipmentStatus?: string;
  order?: any;
  flag?: any;
  warrantyPackage?: any;
  products?: ShoppingCartProduct[];
  supplyProducts?: ShoppingCartSupplyProduct[];
  standardProducts?: ShoppingCartStandardProduct[];
  price?: any;
  internalNote?: InternalNote[];
  revisions?: any[];
  correspondences?: Correspondence[];
  standardOrder?: any;
}

export class ShoppingCart implements ShoppingCartInterface {
  'shoppingCartId': string;
  'orderId': any;
  'standardOrderId': any;
  'email': string;
  'wordpressCallbackStatus': boolean;
  'sampleSaleReferenceNumber': string;
  'sampleSaleRotation': string;
  'sampleSaleMeasurement': string;
  'sampleSaleRabbetSize': string;
  'saleLocation': string;
  'notes': string;
  'warrantyPackageId': any;
  'selectedReasonDeleted': string;
  'isSelfAssembly': boolean;
  'sampleSaleUUID': string;
  'deliveryCosts': number;
  'delivery': any;
  'status': string;
  'discountRecords': Array<any>;
  'extraProductDiscount': number;
  'disableCommunication': boolean;
  'id': any;
  'flagId': any;
  'dateCreated': Date;
  'dateModified': Date;
  'userIdCreated': string;
  'userIdModified': string;
  'firstName': string;
  'insertion': string;
  'lastName': string;
  'emailAddress': string;
  'shippingStreet': string;
  'shippingHouseNumber': string;
  'shippingZip': string;
  'shippingCity': string;
  'shippingPhone': string;
  'shippingProvince': string;
  'shippingMunicipality': string;
  'shippingLocation': any;
  'invoiceStreet': string;
  'invoiceHouseNumber': string;
  'invoiceZip': string;
  'invoiceCity': string;
  'invoiceEmail': string;
  'invoicePhone': string;
  'invoiceCompany': string;
  'invoiceReference': string;
  'invoiceVAT': string;
  'invoiceProvince': string;
  'invoiceMunicipality': string;
  'invoiceLocation': any;
  'exactAccountId': string;
  'callAppointment': Date;
  'shoppingCartNumber': string;
  'deleted': boolean;
  'dateDeleted': Date;
  'bumbalDeliveryId': string;
  'bumbalDeliveryExtraTime': number;
  'bumbalDeliveryTotalTime': number;
  'deliveryDate': Date;
  'bumbalDeliveryEmployee': string;
  'bumbalDeliveryStatus': string;
  'appointmentCosts': Array<any>;
  'bumbalFlow': string;
  'bumbalShipmentId': string;
  'bumbalShipmentExtraTime': number;
  'bumbalShipmentTotalTime': number;
  'shipmentDate': Date;
  'bumbalShipmentEmployee': string;
  'bumbalShipmentStatus': string;
  order: any;
  flag: any;
  warrantyPackage: any;
  products: ShoppingCartProduct[];
  supplyProducts: ShoppingCartSupplyProduct[];
  standardProducts: ShoppingCartStandardProduct[];
  price: any;
  internalNote: InternalNote[];
  revisions: any[];
  correspondences: Correspondence[];
  standardOrder: any;
  constructor(data?: ShoppingCartInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ShoppingCart`.
   */
  public static getModelName() {
    return 'ShoppingCart';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ShoppingCart for dynamic purposes.
   **/
  public static factory(data: ShoppingCartInterface): ShoppingCart {
    return new ShoppingCart(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ShoppingCart',
      plural: 'ShoppingCart',
      path: 'ShoppingCart',
      idName: 'id',
      properties: {
        shoppingCartId: {
          name: 'shoppingCartId',
          type: 'string',
        },
        orderId: {
          name: 'orderId',
          type: 'any',
        },
        standardOrderId: {
          name: 'standardOrderId',
          type: 'any',
        },
        email: {
          name: 'email',
          type: 'string',
        },
        wordpressCallbackStatus: {
          name: 'wordpressCallbackStatus',
          type: 'boolean',
          default: false,
        },
        sampleSaleReferenceNumber: {
          name: 'sampleSaleReferenceNumber',
          type: 'string',
        },
        sampleSaleRotation: {
          name: 'sampleSaleRotation',
          type: 'string',
        },
        sampleSaleMeasurement: {
          name: 'sampleSaleMeasurement',
          type: 'string',
        },
        sampleSaleRabbetSize: {
          name: 'sampleSaleRabbetSize',
          type: 'string',
        },
        saleLocation: {
          name: 'saleLocation',
          type: 'string',
        },
        notes: {
          name: 'notes',
          type: 'string',
        },
        warrantyPackageId: {
          name: 'warrantyPackageId',
          type: 'any',
        },
        selectedReasonDeleted: {
          name: 'selectedReasonDeleted',
          type: 'string',
        },
        isSelfAssembly: {
          name: 'isSelfAssembly',
          type: 'boolean',
          default: false,
        },
        sampleSaleUUID: {
          name: 'sampleSaleUUID',
          type: 'string',
        },
        deliveryCosts: {
          name: 'deliveryCosts',
          type: 'number',
          default: 0,
        },
        delivery: {
          name: 'delivery',
          type: 'any',
        },
        status: {
          name: 'status',
          type: 'string',
          default: 'created',
        },
        discountRecords: {
          name: 'discountRecords',
          type: 'Array&lt;any&gt;',
        },
        extraProductDiscount: {
          name: 'extraProductDiscount',
          type: 'number',
          default: 0,
        },
        disableCommunication: {
          name: 'disableCommunication',
          type: 'boolean',
          default: false,
        },
        id: {
          name: 'id',
          type: 'any',
        },
        flagId: {
          name: 'flagId',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
        userIdCreated: {
          name: 'userIdCreated',
          type: 'string',
        },
        userIdModified: {
          name: 'userIdModified',
          type: 'string',
        },
        firstName: {
          name: 'firstName',
          type: 'string',
        },
        insertion: {
          name: 'insertion',
          type: 'string',
        },
        lastName: {
          name: 'lastName',
          type: 'string',
        },
        emailAddress: {
          name: 'emailAddress',
          type: 'string',
        },
        shippingStreet: {
          name: 'shippingStreet',
          type: 'string',
        },
        shippingHouseNumber: {
          name: 'shippingHouseNumber',
          type: 'string',
        },
        shippingZip: {
          name: 'shippingZip',
          type: 'string',
        },
        shippingCity: {
          name: 'shippingCity',
          type: 'string',
        },
        shippingPhone: {
          name: 'shippingPhone',
          type: 'string',
        },
        shippingProvince: {
          name: 'shippingProvince',
          type: 'string',
        },
        shippingMunicipality: {
          name: 'shippingMunicipality',
          type: 'string',
        },
        shippingLocation: {
          name: 'shippingLocation',
          type: 'any',
        },
        invoiceStreet: {
          name: 'invoiceStreet',
          type: 'string',
        },
        invoiceHouseNumber: {
          name: 'invoiceHouseNumber',
          type: 'string',
        },
        invoiceZip: {
          name: 'invoiceZip',
          type: 'string',
        },
        invoiceCity: {
          name: 'invoiceCity',
          type: 'string',
        },
        invoiceEmail: {
          name: 'invoiceEmail',
          type: 'string',
        },
        invoicePhone: {
          name: 'invoicePhone',
          type: 'string',
        },
        invoiceCompany: {
          name: 'invoiceCompany',
          type: 'string',
        },
        invoiceReference: {
          name: 'invoiceReference',
          type: 'string',
        },
        invoiceVAT: {
          name: 'invoiceVAT',
          type: 'string',
        },
        invoiceProvince: {
          name: 'invoiceProvince',
          type: 'string',
        },
        invoiceMunicipality: {
          name: 'invoiceMunicipality',
          type: 'string',
        },
        invoiceLocation: {
          name: 'invoiceLocation',
          type: 'any',
        },
        exactAccountId: {
          name: 'exactAccountId',
          type: 'string',
        },
        callAppointment: {
          name: 'callAppointment',
          type: 'Date',
        },
        shoppingCartNumber: {
          name: 'shoppingCartNumber',
          type: 'string',
        },
        deleted: {
          name: 'deleted',
          type: 'boolean',
        },
        dateDeleted: {
          name: 'dateDeleted',
          type: 'Date',
        },
        bumbalDeliveryId: {
          name: 'bumbalDeliveryId',
          type: 'string',
        },
        bumbalDeliveryExtraTime: {
          name: 'bumbalDeliveryExtraTime',
          type: 'number',
          default: 0,
        },
        bumbalDeliveryTotalTime: {
          name: 'bumbalDeliveryTotalTime',
          type: 'number',
        },
        deliveryDate: {
          name: 'deliveryDate',
          type: 'Date',
        },
        bumbalDeliveryEmployee: {
          name: 'bumbalDeliveryEmployee',
          type: 'string',
        },
        bumbalDeliveryStatus: {
          name: 'bumbalDeliveryStatus',
          type: 'string',
        },
        appointmentCosts: {
          name: 'appointmentCosts',
          type: 'Array&lt;any&gt;',
        },
        bumbalFlow: {
          name: 'bumbalFlow',
          type: 'string',
          default: 'default',
        },
        bumbalShipmentId: {
          name: 'bumbalShipmentId',
          type: 'string',
        },
        bumbalShipmentExtraTime: {
          name: 'bumbalShipmentExtraTime',
          type: 'number',
          default: 0,
        },
        bumbalShipmentTotalTime: {
          name: 'bumbalShipmentTotalTime',
          type: 'number',
        },
        shipmentDate: {
          name: 'shipmentDate',
          type: 'Date',
        },
        bumbalShipmentEmployee: {
          name: 'bumbalShipmentEmployee',
          type: 'string',
        },
        bumbalShipmentStatus: {
          name: 'bumbalShipmentStatus',
          type: 'string',
        },
      },
      relations: {
        order: {
          name: 'order',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'orderId',
          keyTo: 'id',
        },
        flag: {
          name: 'flag',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'flagId',
          keyTo: 'id',
        },
        warrantyPackage: {
          name: 'warrantyPackage',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'warrantyPackageId',
          keyTo: 'id',
        },
        products: {
          name: 'products',
          type: 'ShoppingCartProduct[]',
          model: 'ShoppingCartProduct',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'shoppingCartId',
        },
        supplyProducts: {
          name: 'supplyProducts',
          type: 'ShoppingCartSupplyProduct[]',
          model: 'ShoppingCartSupplyProduct',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'shoppingCartId',
        },
        standardProducts: {
          name: 'standardProducts',
          type: 'ShoppingCartStandardProduct[]',
          model: 'ShoppingCartStandardProduct',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'shoppingCartId',
        },
        price: {
          name: 'price',
          type: 'any',
          model: '',
          relationType: 'hasOne',
          keyFrom: 'id',
          keyTo: 'shoppingCartId',
        },
        internalNote: {
          name: 'internalNote',
          type: 'InternalNote[]',
          model: 'InternalNote',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'linkId',
        },
        revisions: {
          name: 'revisions',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'modelId',
        },
        correspondences: {
          name: 'correspondences',
          type: 'Correspondence[]',
          model: 'Correspondence',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'fkId',
        },
        standardOrder: {
          name: 'standardOrder',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'standardOrderId',
          keyTo: 'id',
        },
      },
    };
  }
}
