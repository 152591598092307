/* tslint:disable */

declare var Object: any;
export interface FlagInterface {
  name: string;
  color: string;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
}

export class Flag implements FlagInterface {
  'name': string;
  'color': string;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  constructor(data?: FlagInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Flag`.
   */
  public static getModelName() {
    return 'Flag';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Flag for dynamic purposes.
   **/
  public static factory(data: FlagInterface): Flag {
    return new Flag(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Flag',
      plural: 'Flags',
      path: 'Flags',
      idName: 'id',
      properties: {
        name: {
          name: 'name',
          type: 'string',
        },
        color: {
          name: 'color',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {},
    };
  }
}
