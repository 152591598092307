/* tslint:disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi } from '../core/base.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackAuth } from '../core/auth.service';
import { LoopBackFilter, SDKToken, AccessToken } from '../../models/BaseModels';
import { ErrorHandler } from '../core/error.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserModel } from '../../models/UserModel';
import { SocketConnection } from '../../sockets/socket.connections';

/**
 * Api services for the `UserModel` model.
 */
@Injectable()
export class UserModelApi extends BaseLoopBackApi {
  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SocketConnection) protected connection: SocketConnection,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler,
  ) {
    super(http, connection, models, auth, errorHandler);
  }

  /**
   * Find a related item by id for roleMappings.
   *
   * @param {any} id UserModel id
   *
   * @param {any} fk Foreign key for roleMappings
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UserModel` object.)
   * </em>
   */
  public findByIdRoleMappings(
    id: any,
    fk: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/UserModels/:id/roleMappings/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Delete a related item by id for roleMappings.
   *
   * @param {any} id UserModel id
   *
   * @param {any} fk Foreign key for roleMappings
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdRoleMappings(
    id: any,
    fk: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/UserModels/:id/roleMappings/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Update a related item by id for roleMappings.
   *
   * @param {any} id UserModel id
   *
   * @param {any} fk Foreign key for roleMappings
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UserModel` object.)
   * </em>
   */
  public updateByIdRoleMappings(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/UserModels/:id/roleMappings/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Queries roleMappings of UserModel.
   *
   * @param {any} id UserModel id
   *
   * @param {object} filter
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UserModel` object.)
   * </em>
   */
  public getRoleMappings(
    id: any,
    filter: LoopBackFilter = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/UserModels/:id/roleMappings';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null)
      _urlParams.filter = filter;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Creates a new instance in roleMappings of this model.
   *
   * @param {any} id UserModel id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UserModel` object.)
   * </em>
   */
  public createRoleMappings(
    id: any,
    data: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/UserModels/:id/roleMappings';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Deletes all roleMappings of this model.
   *
   * @param {any} id UserModel id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteRoleMappings(
    id: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/UserModels/:id/roleMappings';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Counts roleMappings of UserModel.
   *
   * @param {any} id UserModel id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` -
   */
  public countRoleMappings(
    id: any,
    where: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/UserModels/:id/roleMappings/count';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null)
      _urlParams.where = where;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   * @param {any} id UserModel id
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - An object of model property name/value pairs
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UserModel` object.)
   * </em>
   */
  public patchAttributes(
    id: any,
    data: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'PATCH';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/UserModels/:id';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Login a user with username/email and password.
   *
   * @param {string} include Related objects to include in the response. See the description of return value for more details.
   *   Default value: `user`.
   *
   *  - `rememberMe` - `boolean` - Whether the authentication credentials
   *     should be remembered in localStorage across app/browser restarts.
   *     Default: `true`.
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * The response body contains properties of the AccessToken created on login.
   * Depending on the value of `include` parameter, the body may contain additional properties:
   *
   *   - `user` - `U+007BUserU+007D` - Data of the currently logged in user. (`include=user`)
   *
   *
   */
  public login(
    credentials: any,
    include: any = 'user',
    rememberMe: boolean = true,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/UserModels/login';
    let _routeParams: any = {};
    let _postBody: any = {
      credentials: credentials,
    };
    let _urlParams: any = {};
    if (typeof include !== 'undefined' && include !== null)
      _urlParams.include = include;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    ).pipe(
      map((response: any) => {
        response.ttl = parseInt(response.ttl);
        response.rememberMe = rememberMe;
        this.auth.setToken(response);
        return response;
      }),
    );
    return result;
  }

  /**
   * Logout a user with access token.
   *
   * @param {object} data Request data.
   *
   * This method does not accept any data. Supply an empty object.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public logout(customHeaders?: Function): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/UserModels/logout';
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    _urlParams.access_token = this.auth.getAccessTokenId();
    this.auth.clear();
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Reset password for a user with email.
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public resetPassword(
    options: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/UserModels/reset';
    let _routeParams: any = {};
    let _postBody: any = {
      options: options,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * <em>
   * (The remote method definition does not provide any description.)
   * </em>
   *
   * @param {any} id UserModel id
   *
   * @param {object} data Request data.
   *
   *  - `role` – `{string}` -
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UserModel` object.)
   * </em>
   */
  public setRole(
    id: any,
    role: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/UserModels/:id/set-role';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof role !== 'undefined' && role !== null) _urlParams.role = role;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * <em>
   * (The remote method definition does not provide any description.)
   * </em>
   *
   * @param {object} data Request data.
   *
   *  - `token` – `{string}` - The user email, just for verification
   *
   *  - `password` – `{string}` -
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UserModel` object.)
   * </em>
   */
  public setResetPassword(
    token: any,
    password: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/UserModels/reset-password';
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof token !== 'undefined' && token !== null)
      _urlParams.token = token;
    if (typeof password !== 'undefined' && password !== null)
      _urlParams.password = password;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Allows a logged user to change his/her password.
   *
   * @param {object} data Request data.
   *
   *  - `email` – `{string}` - The user email, just for verification
   *
   *  - `oldPassword` – `{string}` - The user old password
   *
   *  - `newPassword` – `{string}` - The user NEW password
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UserModel` object.)
   * </em>
   */
  public updatePassword(
    email: any,
    oldPassword: any,
    newPassword: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/UserModels/updatePassword';
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof email !== 'undefined' && email !== null)
      _urlParams.email = email;
    if (typeof oldPassword !== 'undefined' && oldPassword !== null)
      _urlParams.oldPassword = oldPassword;
    if (typeof newPassword !== 'undefined' && newPassword !== null)
      _urlParams.newPassword = newPassword;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Log in as the given user
   *
   * @param {object} data Request data.
   *
   *  - `email` – `{string}` - The user email
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UserModel` object.)
   * </em>
   */
  public loginAs(email: any, customHeaders?: Function): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/UserModels/loginAs';
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof email !== 'undefined' && email !== null)
      _urlParams.email = email;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Revokes the users access.
   *
   * @param {any} id UserModel id
   *
   * @param {object} data Request data.
   *
   * This method does not accept any data. Supply an empty object.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UserModel` object.)
   * </em>
   */
  public revokeAccess(id: any, customHeaders?: Function): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/UserModels/:id/access-revoke';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Reinstate the users access.
   *
   * @param {any} id UserModel id
   *
   * @param {object} data Request data.
   *
   * This method does not accept any data. Supply an empty object.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UserModel` object.)
   * </em>
   */
  public reinstateAccess(id: any, customHeaders?: Function): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/UserModels/:id/access-reinstate';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Creates a new instance in roleMappings of this model.
   *
   * @param {any} id UserModel id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UserModel` object.)
   * </em>
   */
  public createManyRoleMappings(
    id: any,
    data: any[] = [],
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/UserModels/:id/roleMappings';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }
  /**
   * @ngdoc method
   * @name sdk.UserModel#getCurrent
   * @methodOf sdk.UserModel
   *
   * @description
   *
   * Get data of the currently logged user. Fail with HTTP result 401
   * when there is no user logged in.
   *
   * @returns object An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   */
  public getCurrent(filter: LoopBackFilter = {}): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/UserModels' +
      '/:id';
    let id: any = this.auth.getCurrentUserId();
    if (id == null) id = '__anonymous__';
    let _routeParams: any = { id: id };
    let _urlParams: any = {};
    let _postBody: any = {};
    if (filter) _urlParams.filter = filter;
    return this.request(_method, _url, _routeParams, _urlParams, _postBody);
  }
  /**
   * Get data of the currently logged user that was returned by the last
   * call to {@link sdk.UserModel#login} or
   * {@link sdk.UserModel#getCurrent}. Return null when there
   * is no user logged in or the data of the current user were not fetched
   * yet.
   *
   * @returns object An Account instance.
   */
  public getCachedCurrent() {
    return this.auth.getCurrentUserData();
  }
  /**
   * Get data of the currently logged access tokern that was returned by the last
   * call to {@link sdk.UserModel#login}
   *
   * @returns object An AccessToken instance.
   */
  public getCurrentToken(): AccessToken {
    return this.auth.getToken();
  }
  /**
   * @name sdk.UserModel#isAuthenticated
   *
   * @returns {boolean} True if the current user is authenticated (logged in).
   */
  public isAuthenticated() {
    return !(
      this.getCurrentId() === '' ||
      this.getCurrentId() == null ||
      this.getCurrentId() == 'null'
    );
  }

  /**
   * @name sdk.UserModel#getCurrentId
   *
   * @returns object Id of the currently logged-in user or null.
   */
  public getCurrentId() {
    return this.auth.getCurrentUserId();
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `UserModel`.
   */
  public getModelName() {
    return 'UserModel';
  }
}
