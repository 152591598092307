/* tslint:disable */

declare var Object: any;
export interface DeliveryQuestionInterface {
  question: any;
  types: Array<any>;
  optionTemplates?: Array<any>;
  general: boolean;
  order: number;
  disabled: boolean;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
}

export class DeliveryQuestion implements DeliveryQuestionInterface {
  'question': any;
  'types': Array<any>;
  'optionTemplates': Array<any>;
  'general': boolean;
  'order': number;
  'disabled': boolean;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  constructor(data?: DeliveryQuestionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `DeliveryQuestion`.
   */
  public static getModelName() {
    return 'DeliveryQuestion';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of DeliveryQuestion for dynamic purposes.
   **/
  public static factory(data: DeliveryQuestionInterface): DeliveryQuestion {
    return new DeliveryQuestion(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'DeliveryQuestion',
      plural: 'DeliveryQuestions',
      path: 'DeliveryQuestions',
      idName: 'id',
      properties: {
        question: {
          name: 'question',
          type: 'any',
        },
        types: {
          name: 'types',
          type: 'Array&lt;any&gt;',
        },
        optionTemplates: {
          name: 'optionTemplates',
          type: 'Array&lt;any&gt;',
        },
        general: {
          name: 'general',
          type: 'boolean',
          default: false,
        },
        order: {
          name: 'order',
          type: 'number',
          default: 1,
        },
        disabled: {
          name: 'disabled',
          type: 'boolean',
          default: false,
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {},
    };
  }
}
