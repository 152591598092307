/* tslint:disable */

declare var Object: any;
export interface LogInterface {
  date: Date;
  model: string;
  instanceId: any;
  userId?: string;
  userName: string;
  text: string;
  properties?: Array<any>;
  oldInstance?: any;
  newInstance?: any;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
}

export class Log implements LogInterface {
  'date': Date;
  'model': string;
  'instanceId': any;
  'userId': string;
  'userName': string;
  'text': string;
  'properties': Array<any>;
  'oldInstance': any;
  'newInstance': any;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  constructor(data?: LogInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Log`.
   */
  public static getModelName() {
    return 'Log';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Log for dynamic purposes.
   **/
  public static factory(data: LogInterface): Log {
    return new Log(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Log',
      plural: 'Logs',
      path: 'Logs',
      idName: 'id',
      properties: {
        date: {
          name: 'date',
          type: 'Date',
        },
        model: {
          name: 'model',
          type: 'string',
        },
        instanceId: {
          name: 'instanceId',
          type: 'any',
        },
        userId: {
          name: 'userId',
          type: 'string',
        },
        userName: {
          name: 'userName',
          type: 'string',
        },
        text: {
          name: 'text',
          type: 'string',
        },
        properties: {
          name: 'properties',
          type: 'Array&lt;any&gt;',
        },
        oldInstance: {
          name: 'oldInstance',
          type: 'any',
        },
        newInstance: {
          name: 'newInstance',
          type: 'any',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {},
    };
  }
}
