import { Router } from '@angular/router';
//////////////////////////////
//                          //
//       Dependencies       //
//                          //
//////////////////////////////
import { Observable } from 'rxjs';
//////////////////////////////
//                          //
//        App Imports       //
//                          //
//////////////////////////////
import { UserModelApi, LoopBackAuth } from '../../_sdk';
import * as i0 from "@angular/core";
import * as i1 from "../../_sdk/services/custom/UserModel";
import * as i2 from "../../_sdk/services/core/auth.service";
import * as i3 from "@angular/router";
export class LoginGuard {
    constructor(auth, lbAuth, router) {
        this.auth = auth;
        this.lbAuth = lbAuth;
        this.router = router;
    }
    isLoginRoute(state) {
        return state.url.indexOf('login') !== -1;
    }
    passOnLoginRoute(state, observer) {
        // prevent login state from preventing itself..
        if (this.isLoginRoute(state)) {
            // allow the route
            observer.next(true);
            observer.complete();
        }
        else {
            // disallow the route
            observer.next(false);
            observer.complete();
            // navigate the the login state
            this.router.navigate(['admin/login']);
        }
    }
    canActivate(route, state) {
        return Observable.create(observer => {
            // check whether we even have a cookie/session and prevent login state from preventing itself..
            if (!this.auth.isAuthenticated()) {
                return this.passOnLoginRoute(state, observer);
            }
            // do have a cookie so check with the api if its still a valid session
            this.auth.getCurrent().subscribe(result => {
                // prevent login state if logged in..
                if (this.isLoginRoute(state)) {
                    // navigate the the admin state
                    this.router.navigate(['admin']);
                }
                // allow the route
                observer.next(true);
                observer.complete();
            }, err => {
                // clear browser cookies
                this.lbAuth.clear();
                // prevent login state from preventing itself..
                this.passOnLoginRoute(state, observer);
            });
        });
    }
}
LoginGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginGuard_Factory() { return new LoginGuard(i0.ɵɵinject(i1.UserModelApi), i0.ɵɵinject(i2.LoopBackAuth), i0.ɵɵinject(i3.Router)); }, token: LoginGuard, providedIn: "root" });
