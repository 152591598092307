//////////////////////////////
//                          //
//         Angular          //
//                          //
//////////////////////////////
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

//////////////////////////////
//                          //
//       Dependencies       //
//                          //
//////////////////////////////
import { Observable } from 'rxjs';
import { saveAs } from 'file-saver';

//////////////////////////////
//                          //
//        App Imports       //
//                          //
//////////////////////////////
import { LoopBackAuth, LoopBackConfig } from '@app/_sdk';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DownloadService {
  /**
   * @description Constructor
   */
  constructor(
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    private http: HttpClient,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * @description Download file
   * @param {string} id Id of the instance
   * @param {string} model Model of the instance
   * @param {string} method Method where to download
   * @param {string} filename Filename of the download
   * @returns {Promise<void>}
   */
  async download(
    id: string,
    model: string,
    method: string,
    filename: string,
    addExtension = false,
    queryParams?: URLSearchParams,
  ): Promise<void> {
    let url = this.getFileDownloadUrl(id, model, method);
    if (queryParams) {
      url += `?${queryParams.toString()}`;
    }

    const blob = await this.get(url).toPromise();

    if (addExtension && blob && blob.type) {
      switch (blob.type) {
        case 'application/pdf':
          filename += '.pdf';
          break;
        case 'application/zip':
          filename += '.zip';
          break;
        case 'application/zpl':
          filename += '.zpl';
          break;
      }
    }

    saveAs(blob, filename);
  }

  parseErrorBlob(err: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();

    const obs = Observable.create((observer: any) => {
      reader.onloadend = (e: any) => {
        observer.error(JSON.parse(reader.result as any));
        observer.complete();
      };
    });
    reader.readAsText(err.error);
    return obs;
  }

  /**
   * @description Perform a http request
   * @param {string} url
   * @param {HttpHeaders} headers
   * @returns : Observable<Blob>
   */
  public get(
    url: string,
    headers: HttpHeaders = new HttpHeaders(),
  ): Observable<Blob> {
    return this.http
      .get(url, {
        responseType: 'blob',
        withCredentials: LoopBackConfig.getRequestOptionsCredentials(),
        headers: this.setAuthHeaders(headers),
      })
      .pipe(catchError(this.parseErrorBlob));
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * @returns Generate a download URL
   * @param {string} id Id of the instance
   * @param {string} model Model of the instance
   * @param {string} method Method where to download
   * @returns {string} URL for the download
   */
  private getFileDownloadUrl(
    id: string,
    model: string,
    method: string,
  ): string {
    let url = '';
    url += `${LoopBackConfig.getPath()}/`;
    url += `${LoopBackConfig.getApiVersion()}/`;
    url += `${model}/`;
    url += `${id}/`;
    url += method;
    return url;
  }

  /**
   * @description Set auth headers with Loopback authorization
   * @param {HttpHeaders} headers
   * @returns {HttpHeaders}
   */
  private setAuthHeaders(
    headers: HttpHeaders = new HttpHeaders(),
  ): HttpHeaders {
    if (this.auth.getAccessTokenId()) {
      headers = headers.append(
        'Authorization',
        LoopBackConfig.getAuthPrefix() + this.auth.getAccessTokenId(),
      );
    }

    return headers;
  }
}
