export const environment = {
  production: false,
  testEnvironment: true,
  configuratorUrl: 'https://gewoongers.accept.tabs-spaces.nl/webshop/',
  apiBaseUrl: 'https://acc.configurator.gewoongers.nl',
  apiVersion: 'api',
  webBaseUrl: 'https://acc.configurator.gewoongers.nl',
  shoppingUrl: 'https://staging.gewoongers.nl',
  defaultLogo: '/assets/img/logo.svg',
  defaultTitle: 'GewoonGers® - Gewoon de gerste deuren',
  defaultExtraDoorDiscount: 50,
  cookieDomain: window.location.hostname,
  maxImageSize: 0.6,
  wrongPresetUrl: 'https://acc.configurator.gewoongers.nl',
  notFoundPleaseContactUrl: 'https://gewoongers.nl/404-neem-contact-op/',
  permissions: {
    roles: [
      {
        value: 'admin',
        viewValue: 'Admin',
        role: 'ADMIN',
      },
      {
        value: 'superadmin',
        viewValue: 'Superadmin',
        role: 'SUPERADMIN',
      },
      {
        value: 'user',
        viewValue: 'Monteur',
        role: 'USER',
      },
      {
        value: 'backoffice',
        viewValue: 'Backoffice',
        role: 'BACKOFFICE',
      },
      {
        value: 'machine',
        viewValue: 'Machine',
        role: 'MACHINE',
      },
    ],
  },
  exact: {
    showOrderSalesOrderBtn: true,
    showOrderDeleteSalesOrderBtn: true,
    showOrderFinishBtn: true,
    showOrderInvoiceBtn: true,
    showOrderDownloadInvoiceBtn: true,

    showPanelOrderInvoiceBtn: true,
    showPanelOrderDownloadInvoiceBtn: true,

    showSupplyOrderSalesOrderBtn: true,
    showSupplyOrderFinishBtn: true,
    showSupplyOrderDownloadInvoiceBtn: true,
    showSupplyOrderInvoiceBtn: true,

    showServiceSalesOrderBtn: true,
    showServiceDeleteSalesOrderBtn: true,
    showServiceInvoiceBtn: true,
    showServiceDownloadInvoiceBtn: true,

    showRefundBtn: false,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
