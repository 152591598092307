/* tslint:disable */
import { WarrantyPackage, Order } from '../index';

declare var Object: any;
export interface WarrantyInterface {
  orderId: any;
  packageId: any;
  dateStarted?: Date;
  dateEnded?: Date;
  pdfName?: string;
  exactSalesInvoiceId?: string;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
  deleted?: boolean;
  dateDeleted?: Date;
  package?: WarrantyPackage;
  order?: Order;
}

export class Warranty implements WarrantyInterface {
  'orderId': any;
  'packageId': any;
  'dateStarted': Date;
  'dateEnded': Date;
  'pdfName': string;
  'exactSalesInvoiceId': string;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  'deleted': boolean;
  'dateDeleted': Date;
  package: WarrantyPackage;
  order: Order;
  constructor(data?: WarrantyInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Warranty`.
   */
  public static getModelName() {
    return 'Warranty';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Warranty for dynamic purposes.
   **/
  public static factory(data: WarrantyInterface): Warranty {
    return new Warranty(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Warranty',
      plural: 'Warranties',
      path: 'Warranties',
      idName: 'id',
      properties: {
        orderId: {
          name: 'orderId',
          type: 'any',
        },
        packageId: {
          name: 'packageId',
          type: 'any',
        },
        dateStarted: {
          name: 'dateStarted',
          type: 'Date',
        },
        dateEnded: {
          name: 'dateEnded',
          type: 'Date',
        },
        pdfName: {
          name: 'pdfName',
          type: 'string',
        },
        exactSalesInvoiceId: {
          name: 'exactSalesInvoiceId',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
        deleted: {
          name: 'deleted',
          type: 'boolean',
        },
        dateDeleted: {
          name: 'dateDeleted',
          type: 'Date',
        },
      },
      relations: {
        package: {
          name: 'package',
          type: 'WarrantyPackage',
          model: 'WarrantyPackage',
          relationType: 'belongsTo',
          keyFrom: 'packageId',
          keyTo: 'id',
        },
        order: {
          name: 'order',
          type: 'Order',
          model: 'Order',
          relationType: 'belongsTo',
          keyFrom: 'orderId',
          keyTo: 'id',
        },
      },
    };
  }
}
