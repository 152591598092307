/* tslint:disable */
import { StandardProduct } from '../index';

declare var Object: any;
export interface ProductTypeInterface {
  description?: string;
  code: string;
  name: string;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
  product?: StandardProduct[];
}

export class ProductType implements ProductTypeInterface {
  'description': string;
  'code': string;
  'name': string;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  product: StandardProduct[];
  constructor(data?: ProductTypeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ProductType`.
   */
  public static getModelName() {
    return 'ProductType';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ProductType for dynamic purposes.
   **/
  public static factory(data: ProductTypeInterface): ProductType {
    return new ProductType(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ProductType',
      plural: 'ProductTypes',
      path: 'ProductTypes',
      idName: 'id',
      properties: {
        description: {
          name: 'description',
          type: 'string',
        },
        code: {
          name: 'code',
          type: 'string',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {
        product: {
          name: 'product',
          type: 'StandardProduct[]',
          model: 'StandardProduct',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'productTypeId',
        },
      },
    };
  }
}
