/* tslint:disable */

declare var Object: any;
export interface SaleTypeInterface {
  type?: string;
  isGewoonGersDefault: boolean;
  isCustomerDefault: boolean;
  code: string;
  invisible: boolean;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
}

export class SaleType implements SaleTypeInterface {
  'type': string;
  'isGewoonGersDefault': boolean;
  'isCustomerDefault': boolean;
  'code': string;
  'invisible': boolean;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  constructor(data?: SaleTypeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `SaleType`.
   */
  public static getModelName() {
    return 'SaleType';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of SaleType for dynamic purposes.
   **/
  public static factory(data: SaleTypeInterface): SaleType {
    return new SaleType(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'SaleType',
      plural: 'SaleTypes',
      path: 'SaleTypes',
      idName: 'id',
      properties: {
        type: {
          name: 'type',
          type: 'string',
        },
        isGewoonGersDefault: {
          name: 'isGewoonGersDefault',
          type: 'boolean',
          default: false,
        },
        isCustomerDefault: {
          name: 'isCustomerDefault',
          type: 'boolean',
          default: false,
        },
        code: {
          name: 'code',
          type: 'string',
        },
        invisible: {
          name: 'invisible',
          type: 'boolean',
          default: false,
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {},
    };
  }
}
