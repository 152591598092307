/* tslint:disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi } from '../core/base.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackAuth } from '../core/auth.service';
import { LoopBackFilter } from '../../models/BaseModels';
import { ErrorHandler } from '../core/error.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { StandardOrder } from '../../models/StandardOrder';
import { SocketConnection } from '../../sockets/socket.connections';
import { InternalNote } from '../../models/InternalNote';
import { Correspondence } from '../../models/Correspondence';
import { StandardOrderProduct } from '../../models/StandardOrderProduct';

/**
 * Api services for the `StandardOrder` model.
 */
@Injectable()
export class StandardOrderApi extends BaseLoopBackApi {
  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SocketConnection) protected connection: SocketConnection,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler,
  ) {
    super(http, connection, models, auth, errorHandler);
  }

  /**
   * Delete a related item by id for internalNote.
   *
   * @param {any} id StandardOrder id
   *
   * @param {any} fk Foreign key for internalNote
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdInternalNote(
    id: any,
    fk: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/internalNote/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Update a related item by id for internalNote.
   *
   * @param {any} id StandardOrder id
   *
   * @param {any} fk Foreign key for internalNote
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `StandardOrder` object.)
   * </em>
   */
  public updateByIdInternalNote(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/internalNote/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Delete a related item by id for products.
   *
   * @param {any} id StandardOrder id
   *
   * @param {any} fk Foreign key for products
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdProducts(
    id: any,
    fk: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/products/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Update a related item by id for products.
   *
   * @param {any} id StandardOrder id
   *
   * @param {any} fk Foreign key for products
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `StandardOrder` object.)
   * </em>
   */
  public updateByIdProducts(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/products/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Queries internalNote of StandardOrder.
   *
   * @param {any} id StandardOrder id
   *
   * @param {object} filter
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `StandardOrder` object.)
   * </em>
   */
  public getInternalNote(
    id: any,
    filter: LoopBackFilter = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/internalNote';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null)
      _urlParams.filter = filter;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Creates a new instance in internalNote of this model.
   *
   * @param {any} id StandardOrder id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `StandardOrder` object.)
   * </em>
   */
  public createInternalNote(
    id: any,
    data: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/internalNote';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Queries correspondences of StandardOrder.
   *
   * @param {any} id StandardOrder id
   *
   * @param {object} filter
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `StandardOrder` object.)
   * </em>
   */
  public getCorrespondences(
    id: any,
    filter: LoopBackFilter = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/correspondences';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null)
      _urlParams.filter = filter;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Queries products of StandardOrder.
   *
   * @param {any} id StandardOrder id
   *
   * @param {object} filter
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `StandardOrder` object.)
   * </em>
   */
  public getProducts(
    id: any,
    filter: LoopBackFilter = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/products';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null)
      _urlParams.filter = filter;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Creates a new instance in products of this model.
   *
   * @param {any} id StandardOrder id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `StandardOrder` object.)
   * </em>
   */
  public createProducts(
    id: any,
    data: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/products';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Counts products of StandardOrder.
   *
   * @param {any} id StandardOrder id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` -
   */
  public countProducts(
    id: any,
    where: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/products/count';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null)
      _urlParams.where = where;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   * @param {any} id StandardOrder id
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - An object of model property name/value pairs
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `StandardOrder` object.)
   * </em>
   */
  public patchAttributes(
    id: any,
    data: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'PATCH';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * <em>
   * (The remote method definition does not provide any description.)
   * </em>
   *
   * @param {string} id StandardOrder id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `StandardOrder` object.)
   * </em>
   */
  public getTotalPrice(id: any, customHeaders?: Function): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/getTotalPrice';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Download a PDF file from an StandardOrder
   *
   * @param {string} id StandardOrder id
   *
   * @param {object} res
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `body` – `{file}` -
   *
   *  - `Content-Type` – `{string}` -
   */
  public downloadOrderConfirmationPDF(
    id: any,
    res: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/downloadPDF';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * <em>
   * (The remote method definition does not provide any description.)
   * </em>
   *
   * @param {string} id StandardOrder id
   *
   * @param {string} type Bumbal appointment type
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `StandardOrder` object.)
   * </em>
   */
  public getBumbalBeforeDays(
    id: any,
    type: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/getBumbalBeforeDays';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof type !== 'undefined' && type !== null) _urlParams.type = type;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * <em>
   * (The remote method definition does not provide any description.)
   * </em>
   *
   * @param {object} data Request data.
   *
   *  - `id` – `{string}` - StandardOrder id
   *
   *  - `voucherCode` – `{string}` -
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `StandardOrder` object.)
   * </em>
   */
  public setVoucher(
    id: any,
    voucherCode: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'PATCH';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/setVoucher';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof voucherCode !== 'undefined' && voucherCode !== null)
      _urlParams.voucherCode = voucherCode;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * <em>
   * (The remote method definition does not provide any description.)
   * </em>
   *
   * @param {object} data Request data.
   *
   *  - `id` – `{string}` - StandardOrder id
   *
   *  - `paymentData` – `{object}` -
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `StandardOrder` object.)
   * </em>
   */
  public createPaymentLink(
    id: any,
    paymentData: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/createPaymentLink';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      paymentData: paymentData,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Sends the customer the pickup notice e-mail
   *
   * @param {string} id StandardOrder id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public sendPickupNoticeMail(
    id: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/send-pickup-notice-mail';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Download a PDF file from a standard order
   *
   * @param {string} id StandardOrder id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `body` – `{file}` -
   *
   *  - `Content-Type` – `{string}` -
   */
  public downloadStandardOrderConfirmationPDF(
    id: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/downloadPanelPDF';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Gets the delivery date from the MendriX order
   *
   * @param {any} id StandardOrder id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `StandardOrder` object.)
   * </em>
   */
  public getMendrixPlannedDeliveryDate(
    id: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/mendrix/get-planned-delivery-date';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Adds the create mendrix order job
   *
   * @param {any} id StandardOrder id
   *
   * @param {object} data Request data.
   *
   *  - `date` – `{Date}` - The date on which the standard order needs to be deliverd.
   *
   *  - `retour` – `{boolean}` - Whether its a retour (pickup) appointment
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public createMendrixOrder(
    id: any,
    date: any,
    retour: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/mendrix/create-order';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof date !== 'undefined' && date !== null) _urlParams.date = date;
    if (typeof retour !== 'undefined' && retour !== null)
      _urlParams.retour = retour;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Adds the cancel mendrix order job
   *
   * @param {any} id StandardOrder id
   *
   * @param {object} data Request data.
   *
   *  - `retour` – `{boolean}` - Whether its a retour (pickup) appointment
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public cancelMendrixOrder(
    id: any,
    retour: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/mendrix/cancel-order';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof retour !== 'undefined' && retour !== null)
      _urlParams.retour = retour;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * <em>
   * (The remote method definition does not provide any description.)
   * </em>
   *
   * @param {string} id standard order id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `StandardOrder` object.)
   * </em>
   */
  public getDeliveryPrice(id: any, customHeaders?: Function): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/getDeliveryPrice';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Send mail manually
   *
   * @param {any} id StandardOrder id
   *
   * @param {object} data Request data.
   *
   *  - `type` – `{string}` - mail type
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public manuallyMail(
    id: any,
    type: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/manuallyMail';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof type !== 'undefined' && type !== null) _urlParams.type = type;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Get the metrics to fill the financial page
   *
   * @param {date} dateFrom Date from for metrics
   *
   * @param {date} dateTo Date to for metrics
   *
   * @param {any} productTypes Product type Id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `StandardOrder` object.)
   * </em>
   */
  public getFinancialMetrics(
    dateFrom: any,
    dateTo: any,
    productTypes: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/getFinancialMetrics';
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof dateFrom !== 'undefined' && dateFrom !== null)
      _urlParams.dateFrom = dateFrom;
    if (typeof dateTo !== 'undefined' && dateTo !== null)
      _urlParams.dateTo = dateTo;
    if (typeof productTypes !== 'undefined' && productTypes !== null)
      _urlParams.productTypes = productTypes;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Get the metrics to fill the dashboard
   *
   * @param {date} dateFrom Date from for metrics
   *
   * @param {date} dateTo Date to for metrics
   *
   * @param {any} productTypes Product type Id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `StandardOrder` object.)
   * </em>
   */
  public getDashboardMetrics(
    dateFrom: any,
    dateTo: any,
    productTypes: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/getDashboardMetrics';
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof dateFrom !== 'undefined' && dateFrom !== null)
      _urlParams.dateFrom = dateFrom;
    if (typeof dateTo !== 'undefined' && dateTo !== null)
      _urlParams.dateTo = dateTo;
    if (typeof productTypes !== 'undefined' && productTypes !== null)
      _urlParams.productTypes = productTypes;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * <em>
   * (The remote method definition does not provide any description.)
   * </em>
   *
   * @param {object} data Request data.
   *
   *  - `standardOrderId` – `{string}` -
   *
   *  - `newDiscountRecords` – `{any}` -
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public replaceDiscountRecord(
    standardOrderId: any = {},
    newDiscountRecords: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'PATCH';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/replaceDiscountRecord';
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof standardOrderId !== 'undefined' && standardOrderId !== null)
      _urlParams.standardOrderId = standardOrderId;
    if (
      typeof newDiscountRecords !== 'undefined' &&
      newDiscountRecords !== null
    )
      _urlParams.newDiscountRecords = newDiscountRecords;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * <em>
   * (The remote method definition does not provide any description.)
   * </em>
   *
   * @param {object} data Request data.
   *
   *  - `standardOrderId` – `{string}` -
   *
   *  - `newPrice` – `{number}` -
   *
   *  - `correctionReasonId` – `{string}` -
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public editPrice(
    standardOrderId: any = {},
    newPrice: any = {},
    correctionReasonId: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'PATCH';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/editPrice';
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof standardOrderId !== 'undefined' && standardOrderId !== null)
      _urlParams.standardOrderId = standardOrderId;
    if (typeof newPrice !== 'undefined' && newPrice !== null)
      _urlParams.newPrice = newPrice;
    if (
      typeof correctionReasonId !== 'undefined' &&
      correctionReasonId !== null
    )
      _urlParams.correctionReasonId = correctionReasonId;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * <em>
   * (The remote method definition does not provide any description.)
   * </em>
   *
   * @param {object} data Request data.
   *
   *  - `id` – `{string}` - StandardOrder id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public createPicqerOrder(id: any, customHeaders?: Function): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/createPicqerOrder';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * <em>
   * (The remote method definition does not provide any description.)
   * </em>
   *
   * @param {string} id StandardOrder id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deletePicqerOrder(id: any, customHeaders?: Function): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/deletePicqerOrder';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * <em>
   * (The remote method definition does not provide any description.)
   * </em>
   *
   * @param {object} data Request data.
   *
   *  - `id` – `{string}` - Instance id
   *
   *  - `address` – `{object}` -
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `StandardOrder` object.)
   * </em>
   */
  public updateAddress(
    id: any,
    address: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'PATCH';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/updateAddress';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      address: address,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * <em>
   * (The remote method definition does not provide any description.)
   * </em>
   *
   * @param {object} data Request data.
   *
   *  - `id` – `{string}` - Instance id
   *
   *  - `appointmentDate` – `{date}` - Date of the appointment
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `StandardOrder` object.)
   * </em>
   */
  public setAppointment(
    id: any,
    appointmentDate: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/setAppointment';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof appointmentDate !== 'undefined' && appointmentDate !== null)
      _urlParams.appointmentDate = appointmentDate;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * <em>
   * (The remote method definition does not provide any description.)
   * </em>
   *
   * @param {object} data Request data.
   *
   *  - `id` – `{string}` - Instance id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `StandardOrder` object.)
   * </em>
   */
  public clearAppointment(id: any, customHeaders?: Function): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/clearAppointment';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Runs the Exact invoice procedure for the given instance
   *
   * @param {object} data Request data.
   *
   *  - `id` – `{string}` - Model id
   *
   *  - `paymentId` – `{string}` - Payment model id
   *
   *  - `constructionDepot` – `{boolean}` - Model id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `success` – `{boolean}` -
   */
  public runExactInvoiceProcedure(
    id: any,
    paymentId: any = {},
    constructionDepot: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/exact/invoice-procedure';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof paymentId !== 'undefined' && paymentId !== null)
      _urlParams.paymentId = paymentId;
    if (typeof constructionDepot !== 'undefined' && constructionDepot !== null)
      _urlParams.constructionDepot = constructionDepot;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Checks whether there is a procedure running for the given model id
   *
   * @param {string} id Model id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public hasRunningExactInvoiceProcedure(
    id: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'HEAD';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/exact/invoice-procedure';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Runs the Exact sales order procedure for the given instance
   *
   * @param {object} data Request data.
   *
   *  - `id` – `{string}` - Model id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public runExactSalesOrderProcedure(
    id: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/exact/sales-order-procedure';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Checks whether there is a procedure running for the given model id
   *
   * @param {string} id Model id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public hasRunningExactSalesOrderProcedure(
    id: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'HEAD';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/exact/sales-order-procedure';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Runs the Exact finish procedure for the given instance
   *
   * @param {object} data Request data.
   *
   *  - `id` – `{string}` - Model id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `success` – `{boolean}` -
   */
  public runExactFinishProcedure(
    id: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/exact/finish-procedure';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Checks whether there is a procedure running for the given model id
   *
   * @param {string} id Model id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public hasRunningExactFinishProcedure(
    id: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'HEAD';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/exact/finish-procedure';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Remove the Exact sales order procedure for the given instance
   *
   * @param {object} data Request data.
   *
   *  - `id` – `{string}` - Model id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `success` – `{boolean}` -
   */
  public runExactDeleteSalesOrderProcedure(
    id: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/exact/delete-sales-order-procedure';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Checks whether there is a procedure running for the given model id
   *
   * @param {string} id Model id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public hasRunningExactDeleteSalesOrderProcedure(
    id: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'HEAD';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/exact/delete-sales-order-procedure';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Download the Exact invoices
   *
   * @param {string} id Model id
   *
   * @param {object} res
   *
   * @param {boolean} returnBuffer
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `body` – `{file}` -
   *
   *  - `Content-Type` – `{string}` -
   */
  public downloadExactInvoice(
    id: any,
    res: any = {},
    returnBuffer: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/exact/download-invoice';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof returnBuffer !== 'undefined' && returnBuffer !== null)
      _urlParams.returnBuffer = returnBuffer;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * <em>
   * (The remote method definition does not provide any description.)
   * </em>
   *
   * @param {number} mendrixOrderIds
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `StandardOrder` object.)
   * </em>
   */
  public getOrderByIds(
    mendrixOrderIds: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/mendrix/orders-by-ids';
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof mendrixOrderIds !== 'undefined' && mendrixOrderIds !== null)
      _urlParams.mendrixOrderIds = mendrixOrderIds;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * <em>
   * (The remote method definition does not provide any description.)
   * </em>
   *
   * @param {date} startDate
   *
   * @param {date} endDate
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `StandardOrder` object.)
   * </em>
   */
  public getOrders(
    startDate: any,
    endDate: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/mendrix/orders-by-date';
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof startDate !== 'undefined' && startDate !== null)
      _urlParams.startDate = startDate;
    if (typeof endDate !== 'undefined' && endDate !== null)
      _urlParams.endDate = endDate;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * <em>
   * (The remote method definition does not provide any description.)
   * </em>
   *
   * @param {number} mendrixOrderId
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `StandardOrder` object.)
   * </em>
   */
  public getPlannedDeliveryDate(
    mendrixOrderId: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/mendrix/delivery-date-by-id';
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof mendrixOrderId !== 'undefined' && mendrixOrderId !== null)
      _urlParams.mendrixOrderId = mendrixOrderId;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Callback for bumbal
   *
   * @param {object} data Request data.
   *
   *  - `target_id` – `{number}` - The bumbal id
   *
   *  - `condition` – `{object}` -
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `StandardOrder` object.)
   * </em>
   */
  public updateBumbal(
    target_id: any = {},
    condition: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/updateBumbal';
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof target_id !== 'undefined' && target_id !== null)
      _urlParams.target_id = target_id;
    if (typeof condition !== 'undefined' && condition !== null)
      _urlParams.condition = condition;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Get the available dates for planning a measurement date
   *
   * @param {any} id StandardOrder id
   *
   * @param {string} type Type of appointment
   *
   * @param {string} dateFrom The start from where Bumbal has to search
   *
   * @param {string} dateTo The end to where Bumbal has to search
   *
   * @param {string} limit The amount of days Bumbal has to search
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `StandardOrder` object.)
   * </em>
   */
  public availableBumbalDates(
    id: any,
    type: any = {},
    dateFrom: any = {},
    dateTo: any = {},
    limit: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/availableBumbalDates';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof type !== 'undefined' && type !== null) _urlParams.type = type;
    if (typeof dateFrom !== 'undefined' && dateFrom !== null)
      _urlParams.dateFrom = dateFrom;
    if (typeof dateTo !== 'undefined' && dateTo !== null)
      _urlParams.dateTo = dateTo;
    if (typeof limit !== 'undefined' && limit !== null)
      _urlParams.limit = limit;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Get the available dates for planning a measurement date
   *
   * @param {any} id StandardOrder id
   *
   * @param {string} type Type of appointment
   *
   * @param {string} dateFrom The start from where Bumbal has to search. Format: YYYY-MM-DD
   *
   * @param {string} dateTo The end to where Bumbal has to search. Format: YYYY-MM-DD
   *
   * @param {string} limit The maximum amount of days Bumbal has to search
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `StandardOrder` object.)
   * </em>
   */
  public availableBumbalDatesV2(
    id: any,
    type: any,
    dateFrom: any = {},
    dateTo: any = {},
    limit: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/v2/availableBumbalDates';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof type !== 'undefined' && type !== null) _urlParams.type = type;
    if (typeof dateFrom !== 'undefined' && dateFrom !== null)
      _urlParams.dateFrom = dateFrom;
    if (typeof dateTo !== 'undefined' && dateTo !== null)
      _urlParams.dateTo = dateTo;
    if (typeof limit !== 'undefined' && limit !== null)
      _urlParams.limit = limit;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Sends the selected date for the measurement appointment to bumbal
   *
   * @param {any} id StandardOrder id
   *
   * @param {object} data Request data.
   *
   *  - `type` – `{string}` - Type of appointment
   *
   *  - `dateFrom` – `{string}` - The start of the selected appointment
   *
   *  - `dateTo` – `{string}` - The end of the selected appointment
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public makeBumbalAppointment(
    id: any,
    type: any,
    dateFrom: any = {},
    dateTo: any = {},
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/makeBumbalAppointment';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof type !== 'undefined' && type !== null) _urlParams.type = type;
    if (typeof dateFrom !== 'undefined' && dateFrom !== null)
      _urlParams.dateFrom = dateFrom;
    if (typeof dateTo !== 'undefined' && dateTo !== null)
      _urlParams.dateTo = dateTo;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Deletes the instances given types bumbal appointment
   *
   * @param {any} id StandardOrder id
   *
   * @param {string} type Type of appointment
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteBumbalAppointment(
    id: any,
    type: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/deleteBumbalAppointment';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof type !== 'undefined' && type !== null) _urlParams.type = type;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Send instance to bumbal for an appointment of `activityType`
   *
   * @param {any} id StandardOrder id
   *
   * @param {object} data Request data.
   *
   *  - `activityType` – `{string}` - The appointment type
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public sendBumbalMail(
    id: any,
    activityType: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/sendBumbalMail';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof activityType !== 'undefined' && activityType !== null)
      _urlParams.activityType = activityType;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Get the existing bumbal information for an appointment of `activityType`
   *
   * @param {any} id StandardOrder id
   *
   * @param {string} type The appointment type
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `StandardOrder` object.)
   * </em>
   */
  public getBumbalInfo(
    id: any,
    type: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/bumbalInfo';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof type !== 'undefined' && type !== null) _urlParams.type = type;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Adds the create dhl draft job
   *
   * @param {any} id StandardOrder id
   *
   * @param {object} data Request data.
   *
   *  - `date` – `{Date}` - The date on which the standard order needs to be deliverd.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public createDhlDraft(
    id: any,
    date: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/dhl/create-draft';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof date !== 'undefined' && date !== null) _urlParams.date = date;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Adds the cancel dhl draft job
   *
   * @param {any} id StandardOrder id
   *
   * @param {object} data Request data.
   *
   * This method does not accept any data. Supply an empty object.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public cancelDhlDraft(id: any, customHeaders?: Function): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/dhl/cancel-draft';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * <em>
   * (The remote method definition does not provide any description.)
   * </em>
   *
   * @param {any} id StandardOrder id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `StandardOrder` object.)
   * </em>
   */
  public updateDealerDiscounts(
    id: any,
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/update-dealer-discounts';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Creates a new instance in internalNote of this model.
   *
   * @param {any} id StandardOrder id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `StandardOrder` object.)
   * </em>
   */
  public createManyInternalNote(
    id: any,
    data: any[] = [],
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/internalNote';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * Creates a new instance in products of this model.
   *
   * @param {any} id StandardOrder id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `StandardOrder` object.)
   * </em>
   */
  public createManyProducts(
    id: any,
    data: any[] = [],
    customHeaders?: Function,
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      '/StandardOrders/:id/products';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders,
    );
    return result;
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `StandardOrder`.
   */
  public getModelName() {
    return 'StandardOrder';
  }
}
