import { registerLocaleData } from '@angular/common';
import localeNL from '@angular/common/locales/nl';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierModule } from 'angular-notifier';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CoreModule } from './_core/core.module';
import { SDKBrowserModule } from './_sdk';
import { SharedModule } from './_shared/shared.module';
import { AppComponent } from './app.component';
import { AppRouter } from './app.router';
registerLocaleData(localeNL, 'nl');

@NgModule({
  imports: [
    BrowserModule,
    CoreModule.forRoot(),
    SDKBrowserModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    AppRouter,
    SharedModule,
    BrowserAnimationsModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'middle',
        },
        vertical: {
          position: 'top',
        },
      },
      behaviour: {
        autoHide: 5000,
        showDismissButton: true,
      },
    }),
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    { provide: LOCALE_ID, useValue: 'nl-NL' },
    NgbActiveModal,
    { provide: 'googleTagManagerId', useValue: 'GTM-TNKND2N' },
  ],
})
export class AppModule {}
