/* tslint:disable */

declare var Object: any;
export interface ExactArticleWarehouseLocationInterface {
  exactId: string;
  itemId: string;
  itemCode: string;
  itemDescription: string;
  itemStock: number;
  storageLocationId?: string;
  storageLocationCode?: string;
  storageLocationDescription?: string;
  warehouseId: string;
  warehouseCode?: string;
  warehouseDescription: string;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
}

export class ExactArticleWarehouseLocation
  implements ExactArticleWarehouseLocationInterface
{
  'exactId': string;
  'itemId': string;
  'itemCode': string;
  'itemDescription': string;
  'itemStock': number;
  'storageLocationId': string;
  'storageLocationCode': string;
  'storageLocationDescription': string;
  'warehouseId': string;
  'warehouseCode': string;
  'warehouseDescription': string;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  constructor(data?: ExactArticleWarehouseLocationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ExactArticleWarehouseLocation`.
   */
  public static getModelName() {
    return 'ExactArticleWarehouseLocation';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ExactArticleWarehouseLocation for dynamic purposes.
   **/
  public static factory(
    data: ExactArticleWarehouseLocationInterface,
  ): ExactArticleWarehouseLocation {
    return new ExactArticleWarehouseLocation(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ExactArticleWarehouseLocation',
      plural: 'ExactArticleWarehouseLocations',
      path: 'ExactArticleWarehouseLocations',
      idName: 'id',
      properties: {
        exactId: {
          name: 'exactId',
          type: 'string',
        },
        itemId: {
          name: 'itemId',
          type: 'string',
        },
        itemCode: {
          name: 'itemCode',
          type: 'string',
        },
        itemDescription: {
          name: 'itemDescription',
          type: 'string',
        },
        itemStock: {
          name: 'itemStock',
          type: 'number',
        },
        storageLocationId: {
          name: 'storageLocationId',
          type: 'string',
        },
        storageLocationCode: {
          name: 'storageLocationCode',
          type: 'string',
        },
        storageLocationDescription: {
          name: 'storageLocationDescription',
          type: 'string',
        },
        warehouseId: {
          name: 'warehouseId',
          type: 'string',
        },
        warehouseCode: {
          name: 'warehouseCode',
          type: 'string',
        },
        warehouseDescription: {
          name: 'warehouseDescription',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {},
    };
  }
}
