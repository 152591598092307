/* tslint:disable */
import { StandardProduct, ProductCategory } from '../index';

declare var Object: any;
export interface StandardProductCategoryInterface {
  productCategoryId: any;
  standardProductId: any;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
  standardProduct?: StandardProduct;
  productCategory?: ProductCategory;
}

export class StandardProductCategory
  implements StandardProductCategoryInterface
{
  'productCategoryId': any;
  'standardProductId': any;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  standardProduct: StandardProduct;
  productCategory: ProductCategory;
  constructor(data?: StandardProductCategoryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `StandardProductCategory`.
   */
  public static getModelName() {
    return 'StandardProductCategory';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of StandardProductCategory for dynamic purposes.
   **/
  public static factory(
    data: StandardProductCategoryInterface,
  ): StandardProductCategory {
    return new StandardProductCategory(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'StandardProductCategory',
      plural: 'StandardProductCategories',
      path: 'StandardProductCategories',
      idName: 'id',
      properties: {
        productCategoryId: {
          name: 'productCategoryId',
          type: 'any',
        },
        standardProductId: {
          name: 'standardProductId',
          type: 'any',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {
        standardProduct: {
          name: 'standardProduct',
          type: 'StandardProduct',
          model: 'StandardProduct',
          relationType: 'belongsTo',
          keyFrom: 'standardProductId',
          keyTo: 'id',
        },
        productCategory: {
          name: 'productCategory',
          type: 'ProductCategory',
          model: 'ProductCategory',
          relationType: 'belongsTo',
          keyFrom: 'productCategoryId',
          keyTo: 'id',
        },
      },
    };
  }
}
