import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { LoadGuard, LoginGuard } from './_guards';
import { AppConfigurationService, DownloadService } from './_services';

@NgModule()
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only',
      );
    }
  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [].concat(
        AppConfigurationService,
        LoginGuard,
        LoadGuard,
        DownloadService,
      ),
    };
  }
}
