//////////////////////////////
//                          //
//         Angular          //
//                          //
//////////////////////////////
import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

//////////////////////////////
//                          //
//       Dependencies       //
//                          //
//////////////////////////////
import { NgbdSortableHeader } from '../table/sortable-header.directive';

//////////////////////////////
//                          //
//        App Imports       //
//                          //
//////////////////////////////

import { StandardOrderProduct, StandardProduct } from '@app/_sdk';

@Component({
  selector: 'app-standard-product-add',
  templateUrl: './standard-product-add.component.html',
  styleUrls: ['./standard-product-add.component.scss'],
})
export class StandardProductAddComponent {
  // -----------------------------------------------------------------------------------------------------
  // @ Public properties
  // -----------------------------------------------------------------------------------------------------
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  @Input() standardProducts: StandardProduct[] | StandardOrderProduct[];
  @Output() add: EventEmitter<StandardProduct | StandardOrderProduct> =
    new EventEmitter<StandardProduct | StandardOrderProduct>();

  /**
   * Constructor
   */
  constructor() {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * @description Emit the standard product
   * @returns
   * @param standardProduct
   */
  onClick(standardProduct: StandardProduct | StandardOrderProduct): void {
    this.add.emit(standardProduct);
  }

  countStock(standardProduct: StandardProduct) {
    if (!Array.isArray(standardProduct.exactArticleWarehouseLocation)) {
      return 0;
    }

    return standardProduct.exactArticleWarehouseLocation.reduce(
      (prev, curr) => prev + curr.itemStock,
      0,
    );
  }
  getDimensions(product: StandardProduct) {
    let dimensions = '';
    const width = product.width ? product.width : '0';
    const height = product.height ? product.height : '0';
    const length = product.length ? product.length : '0';
    const weight = product.weight ? product.weight : '0';
    return width + '*' + height + '*' + length + '/' + weight;
  }
}
