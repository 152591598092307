/* tslint:disable */

declare var Object: any;
export interface DeliveryFormQuestionAnswerInterface {
  value: string;
  type: string;
  name: string;
  label: string;
}

export class DeliveryFormQuestionAnswer
  implements DeliveryFormQuestionAnswerInterface
{
  'value': string;
  'type': string;
  'name': string;
  'label': string;
  constructor(data?: DeliveryFormQuestionAnswerInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `DeliveryFormQuestionAnswer`.
   */
  public static getModelName() {
    return 'DeliveryFormQuestionAnswer';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of DeliveryFormQuestionAnswer for dynamic purposes.
   **/
  public static factory(
    data: DeliveryFormQuestionAnswerInterface,
  ): DeliveryFormQuestionAnswer {
    return new DeliveryFormQuestionAnswer(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'DeliveryFormQuestionAnswer',
      plural: 'DeliveryFormQuestionAnswer',
      path: 'DeliveryFormQuestionAnswer',
      idName: '',
      properties: {
        value: {
          name: 'value',
          type: 'string',
        },
        type: {
          name: 'type',
          type: 'string',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        label: {
          name: 'label',
          type: 'string',
        },
      },
      relations: {},
    };
  }
}
