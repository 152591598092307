<div class="modal-header">
  <h4 class="modal-title">Bericht aan klantenservice</h4>
</div>
<div class="modal-body">
  <div class="form-group">
    <label for="notes"
      >We horen graag waarmee we je kunnen helpen, zodat we ons alvast kunnen
      voorbereiden op ons contact!</label
    >
    <textarea
      class="form-control"
      id="notes"
      placeholder="Waar heeft u hulp bij nodig?"
      [(ngModel)]="customerServiceMessage"
    ></textarea>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="reject()">
    Annuleren
  </button>
  <button
    type="button"
    class="btn btn-outline-success"
    [disabled]="!isValid"
    (click)="save()"
  >
    Versturen
  </button>
</div>
