/* tslint:disable */
import {
  Order,
  Payment,
  InternalNote,
  SupplyOrderProduct,
  Correspondence,
  ExactLog,
  Division,
} from '../index';

declare var Object: any;
export interface SupplyOrderInterface {
  notes?: string;
  paymentStatus: string;
  status: string;
  discountRecords?: Array<any>;
  orderId?: any;
  picqerOrderId?: number;
  exactFinishSalesOrder?: boolean;
  inStoreProductsStatus: string;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
  supplyNumber: string;
  exactInvoicedAmount?: number;
  exactSalesOrderId?: string;
  exactSalesInvoiceIds?: Array<any>;
  exactSalesInvoiceAttachmentIds?: Array<any>;
  exactInvoiceRemark?: string;
  userIdCreated?: string;
  userIdModified?: string;
  firstName: string;
  insertion?: string;
  lastName: string;
  emailAddress: string;
  shippingStreet: string;
  shippingHouseNumber: string;
  shippingZip: string;
  shippingCity: string;
  shippingPhone: string;
  shippingProvince?: string;
  shippingMunicipality?: string;
  shippingLocation?: any;
  invoiceStreet: string;
  invoiceHouseNumber: string;
  invoiceZip: string;
  invoiceCity: string;
  invoiceEmail: string;
  invoicePhone: string;
  invoiceCompany?: string;
  invoiceReference?: string;
  invoiceVAT?: string;
  invoiceProvince?: string;
  invoiceMunicipality?: string;
  invoiceLocation?: any;
  exactAccountId?: string;
  invoiceDivisionId?: any;
  shippingDivisionId?: any;
  order?: Order;
  payments?: Payment[];
  internalNote?: InternalNote[];
  products?: SupplyOrderProduct[];
  correspondences?: Correspondence[];
  exactLogs?: ExactLog[];
  invoiceDivision?: Division;
  shippingDivision?: Division;
}

export class SupplyOrder implements SupplyOrderInterface {
  'notes': string;
  'paymentStatus': string;
  'status': string;
  'discountRecords': Array<any>;
  'orderId': any;
  'picqerOrderId': number;
  'exactFinishSalesOrder': boolean;
  'inStoreProductsStatus': string;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  'supplyNumber': string;
  'exactInvoicedAmount': number;
  'exactSalesOrderId': string;
  'exactSalesInvoiceIds': Array<any>;
  'exactSalesInvoiceAttachmentIds': Array<any>;
  'exactInvoiceRemark': string;
  'userIdCreated': string;
  'userIdModified': string;
  'firstName': string;
  'insertion': string;
  'lastName': string;
  'emailAddress': string;
  'shippingStreet': string;
  'shippingHouseNumber': string;
  'shippingZip': string;
  'shippingCity': string;
  'shippingPhone': string;
  'shippingProvince': string;
  'shippingMunicipality': string;
  'shippingLocation': any;
  'invoiceStreet': string;
  'invoiceHouseNumber': string;
  'invoiceZip': string;
  'invoiceCity': string;
  'invoiceEmail': string;
  'invoicePhone': string;
  'invoiceCompany': string;
  'invoiceReference': string;
  'invoiceVAT': string;
  'invoiceProvince': string;
  'invoiceMunicipality': string;
  'invoiceLocation': any;
  'exactAccountId': string;
  'invoiceDivisionId': any;
  'shippingDivisionId': any;
  order: Order;
  payments: Payment[];
  internalNote: InternalNote[];
  products: SupplyOrderProduct[];
  correspondences: Correspondence[];
  exactLogs: ExactLog[];
  invoiceDivision: Division;
  shippingDivision: Division;
  constructor(data?: SupplyOrderInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `SupplyOrder`.
   */
  public static getModelName() {
    return 'SupplyOrder';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of SupplyOrder for dynamic purposes.
   **/
  public static factory(data: SupplyOrderInterface): SupplyOrder {
    return new SupplyOrder(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'SupplyOrder',
      plural: 'SupplyOrders',
      path: 'SupplyOrders',
      idName: 'id',
      properties: {
        notes: {
          name: 'notes',
          type: 'string',
        },
        paymentStatus: {
          name: 'paymentStatus',
          type: 'string',
          default: 'notPaid',
        },
        status: {
          name: 'status',
          type: 'string',
        },
        discountRecords: {
          name: 'discountRecords',
          type: 'Array&lt;any&gt;',
        },
        orderId: {
          name: 'orderId',
          type: 'any',
        },
        picqerOrderId: {
          name: 'picqerOrderId',
          type: 'number',
        },
        exactFinishSalesOrder: {
          name: 'exactFinishSalesOrder',
          type: 'boolean',
        },
        inStoreProductsStatus: {
          name: 'inStoreProductsStatus',
          type: 'string',
          default: 'toDo',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
        supplyNumber: {
          name: 'supplyNumber',
          type: 'string',
        },
        exactInvoicedAmount: {
          name: 'exactInvoicedAmount',
          type: 'number',
        },
        exactSalesOrderId: {
          name: 'exactSalesOrderId',
          type: 'string',
        },
        exactSalesInvoiceIds: {
          name: 'exactSalesInvoiceIds',
          type: 'Array&lt;any&gt;',
        },
        exactSalesInvoiceAttachmentIds: {
          name: 'exactSalesInvoiceAttachmentIds',
          type: 'Array&lt;any&gt;',
        },
        exactInvoiceRemark: {
          name: 'exactInvoiceRemark',
          type: 'string',
        },
        userIdCreated: {
          name: 'userIdCreated',
          type: 'string',
        },
        userIdModified: {
          name: 'userIdModified',
          type: 'string',
        },
        firstName: {
          name: 'firstName',
          type: 'string',
        },
        insertion: {
          name: 'insertion',
          type: 'string',
        },
        lastName: {
          name: 'lastName',
          type: 'string',
        },
        emailAddress: {
          name: 'emailAddress',
          type: 'string',
        },
        shippingStreet: {
          name: 'shippingStreet',
          type: 'string',
        },
        shippingHouseNumber: {
          name: 'shippingHouseNumber',
          type: 'string',
        },
        shippingZip: {
          name: 'shippingZip',
          type: 'string',
        },
        shippingCity: {
          name: 'shippingCity',
          type: 'string',
        },
        shippingPhone: {
          name: 'shippingPhone',
          type: 'string',
        },
        shippingProvince: {
          name: 'shippingProvince',
          type: 'string',
        },
        shippingMunicipality: {
          name: 'shippingMunicipality',
          type: 'string',
        },
        shippingLocation: {
          name: 'shippingLocation',
          type: 'any',
        },
        invoiceStreet: {
          name: 'invoiceStreet',
          type: 'string',
        },
        invoiceHouseNumber: {
          name: 'invoiceHouseNumber',
          type: 'string',
        },
        invoiceZip: {
          name: 'invoiceZip',
          type: 'string',
        },
        invoiceCity: {
          name: 'invoiceCity',
          type: 'string',
        },
        invoiceEmail: {
          name: 'invoiceEmail',
          type: 'string',
        },
        invoicePhone: {
          name: 'invoicePhone',
          type: 'string',
        },
        invoiceCompany: {
          name: 'invoiceCompany',
          type: 'string',
        },
        invoiceReference: {
          name: 'invoiceReference',
          type: 'string',
        },
        invoiceVAT: {
          name: 'invoiceVAT',
          type: 'string',
        },
        invoiceProvince: {
          name: 'invoiceProvince',
          type: 'string',
        },
        invoiceMunicipality: {
          name: 'invoiceMunicipality',
          type: 'string',
        },
        invoiceLocation: {
          name: 'invoiceLocation',
          type: 'any',
        },
        exactAccountId: {
          name: 'exactAccountId',
          type: 'string',
        },
        invoiceDivisionId: {
          name: 'invoiceDivisionId',
          type: 'any',
        },
        shippingDivisionId: {
          name: 'shippingDivisionId',
          type: 'any',
        },
      },
      relations: {
        order: {
          name: 'order',
          type: 'Order',
          model: 'Order',
          relationType: 'belongsTo',
          keyFrom: 'orderId',
          keyTo: 'id',
        },
        payments: {
          name: 'payments',
          type: 'Payment[]',
          model: 'Payment',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'orderId',
        },
        internalNote: {
          name: 'internalNote',
          type: 'InternalNote[]',
          model: 'InternalNote',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'linkId',
        },
        products: {
          name: 'products',
          type: 'SupplyOrderProduct[]',
          model: 'SupplyOrderProduct',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'supplyOrderId',
        },
        correspondences: {
          name: 'correspondences',
          type: 'Correspondence[]',
          model: 'Correspondence',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'fkId',
        },
        exactLogs: {
          name: 'exactLogs',
          type: 'ExactLog[]',
          model: 'ExactLog',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'instanceId',
        },
        invoiceDivision: {
          name: 'invoiceDivision',
          type: 'Division',
          model: 'Division',
          relationType: 'belongsTo',
          keyFrom: 'invoiceDivisionId',
          keyTo: 'id',
        },
        shippingDivision: {
          name: 'shippingDivision',
          type: 'Division',
          model: 'Division',
          relationType: 'belongsTo',
          keyFrom: 'shippingDivisionId',
          keyTo: 'id',
        },
      },
    };
  }
}
