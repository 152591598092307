import * as tslib_1 from "tslib";
//////////////////////////////
//                          //
//         Angular          //
//                          //
//////////////////////////////
import { OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
//////////////////////////////
//                          //
//       Dependencies       //
//                          //
//////////////////////////////
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
//////////////////////////////
//                          //
//        App Imports       //
//                          //
//////////////////////////////
import { StandardProductApi } from '@app/_sdk';
export class StandardProductListModalComponent {
    /**
     * Constructor
     */
    constructor(activeModal, standardProductApi) {
        this.activeModal = activeModal;
        this.standardProductApi = standardProductApi;
        // Set the public defaults
        this.searchInput = new FormControl('');
        // Set the private defaults
        this.unsubscribeAll = new Subject();
    }
    /**
     * @description On init
     */
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // Get standard products if no list is given
            if (!this.standardProducts) {
                this.standardProducts = yield this.standardProductApi
                    .find({
                    order: 'name',
                    include: [
                        {
                            relation: 'exactArticleWarehouseLocation',
                            scope: {
                                fields: ['itemStock'],
                            },
                        },
                        {
                            relation: 'deliveryCondition',
                            scope: {
                                include: {
                                    relation: 'deliveryCondition',
                                },
                            },
                        },
                        {
                            relation: 'deliveryMethod',
                        },
                        {
                            relation: 'productType',
                        },
                        {
                            relation: 'productCategory',
                            scope: {
                                include: {
                                    relation: 'productCategory',
                                },
                            },
                        },
                    ],
                })
                    .toPromise();
            }
            this.filteredProducts = this.standardProducts;
            this.searchInput.valueChanges
                .pipe(takeUntil(this.unsubscribeAll), debounceTime(300), distinctUntilChanged())
                .subscribe(searchText => {
                this.filteredProducts = this.standardProducts.filter(product => product.name.toLowerCase().includes(searchText.toLowerCase()));
            });
        });
    }
    /**
     * On destroy
     */
    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * @description Select the standardProduct
     * @param standardProduct
     * @returns
     */
    add(standardProduct) {
        this.activeModal.close(standardProduct);
    }
}
