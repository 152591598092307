/* tslint:disable */

declare var Object: any;
export interface CancelQuestionInterface {
  text: string;
  code: string;
  invisible: boolean;
  customerCancelation: boolean;
  model?: string;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
}

export class CancelQuestion implements CancelQuestionInterface {
  'text': string;
  'code': string;
  'invisible': boolean;
  'customerCancelation': boolean;
  'model': string;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  constructor(data?: CancelQuestionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CancelQuestion`.
   */
  public static getModelName() {
    return 'CancelQuestion';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of CancelQuestion for dynamic purposes.
   **/
  public static factory(data: CancelQuestionInterface): CancelQuestion {
    return new CancelQuestion(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'CancelQuestion',
      plural: 'CancelQuestions',
      path: 'CancelQuestions',
      idName: 'id',
      properties: {
        text: {
          name: 'text',
          type: 'string',
        },
        code: {
          name: 'code',
          type: 'string',
        },
        invisible: {
          name: 'invisible',
          type: 'boolean',
          default: false,
        },
        customerCancelation: {
          name: 'customerCancelation',
          type: 'boolean',
          default: false,
        },
        model: {
          name: 'model',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {},
    };
  }
}
