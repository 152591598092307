/* tslint:disable */
import { Injectable } from '@angular/core';
import { UserModel } from '../../models/UserModel';
import { DeliveryCondition } from '../../models/DeliveryCondition';
import { Dealer } from '../../models/Dealer';
import { DeliveryMethod } from '../../models/DeliveryMethod';
import { Warranty } from '../../models/Warranty';
import { WarrantyPackage } from '../../models/WarrantyPackage';
import { Order } from '../../models/Order';
import { Payment } from '../../models/Payment';
import { PaymentIcepay } from '../../models/PaymentIcepay';
import { PaymentPaynl } from '../../models/PaymentPaynl';
import { TransactionStartDto } from '../../models/TransactionStartDto';
import { DiscountCode } from '../../models/DiscountCode';
import { Flag } from '../../models/Flag';
import { Container } from '../../models/Container';
import { OptionTemplate } from '../../models/OptionTemplate';
import { OptionTemplateContainer } from '../../models/OptionTemplateContainer';
import { Product } from '../../models/Product';
import { ProductieStaat } from '../../models/ProductieStaat';
import { ProductieStaatConfiguration } from '../../models/ProductieStaatConfiguration';
import { ProductieStaatConfigurationVersion } from '../../models/ProductieStaatConfigurationVersion';
import { ProductieStaatValidation } from '../../models/ProductieStaatValidation';
import { Service } from '../../models/Service';
import { ServiceProduct } from '../../models/ServiceProduct';
import { PriceCategory } from '../../models/PriceCategory';
import { PriceCategoryItemHistory } from '../../models/PriceCategoryItemHistory';
import { Log } from '../../models/Log';
import { ServiceType } from '../../models/ServiceType';
import { ServiceResponsibility } from '../../models/ServiceResponsibility';
import { ServiceCategory } from '../../models/ServiceCategory';
import { ServiceMaterial } from '../../models/ServiceMaterial';
import { Render } from '../../models/Render';
import { Rack } from '../../models/Rack';
import { RackSlot } from '../../models/RackSlot';
import { ShoppingCart } from '../../models/ShoppingCart';
import { ShoppingCartProduct } from '../../models/ShoppingCartProduct';
import { ShoppingCartSupplyProduct } from '../../models/ShoppingCartSupplyProduct';
import { ShoppingCartStandardProduct } from '../../models/ShoppingCartStandardProduct';
import { UserProductieStaatConfigInterface } from '../../models/UserProductieStaatConfigInterface';
import { InternalNote } from '../../models/InternalNote';
import { ProductHistory } from '../../models/ProductHistory';
import { PaginationMeta } from '../../models/PaginationMeta';
import { StatusFlow } from '../../models/StatusFlow';
import { Status } from '../../models/Status';
import { StatusType } from '../../models/StatusType';
import { SupplyProduct } from '../../models/SupplyProduct';
import { SupplyOrder } from '../../models/SupplyOrder';
import { SupplyOrderProduct } from '../../models/SupplyOrderProduct';
import { Correspondence } from '../../models/Correspondence';
import { ExactToken } from '../../models/ExactToken';
import { ExactLog } from '../../models/ExactLog';
import { SaleType } from '../../models/SaleType';
import { DeliveryQuestion } from '../../models/DeliveryQuestion';
import { DeliveryForm } from '../../models/DeliveryForm';
import { DeliveryFormProduct } from '../../models/DeliveryFormProduct';
import { DeliveryFormQuestion } from '../../models/DeliveryFormQuestion';
import { DeliveryFormQuestionAnswer } from '../../models/DeliveryFormQuestionAnswer';
import { FinalCheckQuestion } from '../../models/FinalCheckQuestion';
import { FinalCheckFormQuestion } from '../../models/FinalCheckFormQuestion';
import { FinalCheckFormQuestionAnswer } from '../../models/FinalCheckFormQuestionAnswer';
import { FactoryWorker } from '../../models/FactoryWorker';
import { Division } from '../../models/Division';
import { SaleLocation } from '../../models/SaleLocation';
import { CancelQuestion } from '../../models/CancelQuestion';
import { IcepayIssuer } from '../../models/IcepayIssuer';
import { ExactArticleWarehouseLocation } from '../../models/ExactArticleWarehouseLocation';
import { StandardOrder } from '../../models/StandardOrder';
import { StandardProduct } from '../../models/StandardProduct';
import { StandardOrderProduct } from '../../models/StandardOrderProduct';
import { StandardProductDeliveryCondition } from '../../models/StandardProductDeliveryCondition';
import { ProductCategory } from '../../models/ProductCategory';
import { StandardProductCategory } from '../../models/StandardProductCategory';
import { ProductType } from '../../models/ProductType';
import { CorrectionReason } from '../../models/CorrectionReason';
import { StandardOrderDelivery } from '../../models/StandardOrderDelivery';
import { ServiceStandardProduct } from '../../models/ServiceStandardProduct';

export interface Models {
  [name: string]: any;
}

@Injectable()
export class SDKModels {
  private models: Models = {
    UserModel: UserModel,
    DeliveryCondition: DeliveryCondition,
    Dealer: Dealer,
    DeliveryMethod: DeliveryMethod,
    Warranty: Warranty,
    WarrantyPackage: WarrantyPackage,
    Order: Order,
    Payment: Payment,
    PaymentIcepay: PaymentIcepay,
    PaymentPaynl: PaymentPaynl,
    TransactionStartDto: TransactionStartDto,
    DiscountCode: DiscountCode,
    Flag: Flag,
    Container: Container,
    OptionTemplate: OptionTemplate,
    OptionTemplateContainer: OptionTemplateContainer,
    Product: Product,
    ProductieStaat: ProductieStaat,
    ProductieStaatConfiguration: ProductieStaatConfiguration,
    ProductieStaatConfigurationVersion: ProductieStaatConfigurationVersion,
    ProductieStaatValidation: ProductieStaatValidation,
    Service: Service,
    ServiceProduct: ServiceProduct,
    PriceCategory: PriceCategory,
    PriceCategoryItemHistory: PriceCategoryItemHistory,
    Log: Log,
    ServiceType: ServiceType,
    ServiceResponsibility: ServiceResponsibility,
    ServiceCategory: ServiceCategory,
    ServiceMaterial: ServiceMaterial,
    Render: Render,
    Rack: Rack,
    RackSlot: RackSlot,
    ShoppingCart: ShoppingCart,
    ShoppingCartProduct: ShoppingCartProduct,
    ShoppingCartSupplyProduct: ShoppingCartSupplyProduct,
    ShoppingCartStandardProduct: ShoppingCartStandardProduct,
    UserProductieStaatConfigInterface: UserProductieStaatConfigInterface,
    InternalNote: InternalNote,
    ProductHistory: ProductHistory,
    PaginationMeta: PaginationMeta,
    StatusFlow: StatusFlow,
    Status: Status,
    StatusType: StatusType,
    SupplyProduct: SupplyProduct,
    SupplyOrder: SupplyOrder,
    SupplyOrderProduct: SupplyOrderProduct,
    Correspondence: Correspondence,
    ExactToken: ExactToken,
    ExactLog: ExactLog,
    SaleType: SaleType,
    DeliveryQuestion: DeliveryQuestion,
    DeliveryForm: DeliveryForm,
    DeliveryFormProduct: DeliveryFormProduct,
    DeliveryFormQuestion: DeliveryFormQuestion,
    DeliveryFormQuestionAnswer: DeliveryFormQuestionAnswer,
    FinalCheckQuestion: FinalCheckQuestion,
    FinalCheckFormQuestion: FinalCheckFormQuestion,
    FinalCheckFormQuestionAnswer: FinalCheckFormQuestionAnswer,
    FactoryWorker: FactoryWorker,
    Division: Division,
    SaleLocation: SaleLocation,
    CancelQuestion: CancelQuestion,
    IcepayIssuer: IcepayIssuer,
    ExactArticleWarehouseLocation: ExactArticleWarehouseLocation,
    StandardOrder: StandardOrder,
    StandardProduct: StandardProduct,
    StandardOrderProduct: StandardOrderProduct,
    StandardProductDeliveryCondition: StandardProductDeliveryCondition,
    ProductCategory: ProductCategory,
    StandardProductCategory: StandardProductCategory,
    ProductType: ProductType,
    CorrectionReason: CorrectionReason,
    StandardOrderDelivery: StandardOrderDelivery,
    ServiceStandardProduct: ServiceStandardProduct,
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
