<div class="filter-title-bar">
  <div class="container-fluid">
    <div
      class="
        d-flex
        flex-row
        align-items-center
        justify-content-between
        mb-3
        mt-3
      "
    >
      <h5>Kies een accessoire</h5>
    </div>
  </div>
</div>
<div class="supply-product-content">
  <app-supply-product-add
    [supplyProducts]="supplyProducts"
    (add)="add($event)"
  ></app-supply-product-add>
</div>

<ng-template #confirmInvalidDialog let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Bevestiging</h4>
  </div>
  <div class="modal-body mb-4">
    Weet u zeker dat u deze accessoire wilt toevoegen?
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.dismiss()"
    >
      Annuleren
    </button>
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('confirm')"
    >
      Toevoegen
    </button>
  </div>
</ng-template>
