/* tslint:disable */

declare var Object: any;
export interface SaleLocationInterface {
  name: string;
  key: string;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
}

export class SaleLocation implements SaleLocationInterface {
  'name': string;
  'key': string;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  constructor(data?: SaleLocationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `SaleLocation`.
   */
  public static getModelName() {
    return 'SaleLocation';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of SaleLocation for dynamic purposes.
   **/
  public static factory(data: SaleLocationInterface): SaleLocation {
    return new SaleLocation(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'SaleLocation',
      plural: 'SaleLocations',
      path: 'SaleLocations',
      idName: 'id',
      properties: {
        name: {
          name: 'name',
          type: 'string',
        },
        key: {
          name: 'key',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {},
    };
  }
}
