import { Injectable } from '@angular/core';
import * as RecordRTC from 'recordrtc';
import { Observable, Subject } from 'rxjs';
import * as moment from 'moment';

export interface RecordedAudioOutput {
  recording: Blob;
  title: string;
}

@Injectable()
export class AudioRecordingService {
  private stream;
  private recorder;
  private interval;
  private startTime;
  private _recorded = new Subject<RecordedAudioOutput>();
  private _recordingStarted = new Subject<boolean>();
  private _recordingTime = new Subject<string>();
  private _recordingFailed = new Subject<string>();

  constructor() {}

  get recordedBlob(): Observable<RecordedAudioOutput> {
    return this._recorded.asObservable();
  }

  get recordedTime(): Observable<string> {
    return this._recordingTime.asObservable();
  }

  recordingFailed(): Observable<string> {
    return this._recordingFailed.asObservable();
  }

  get recordingStarted(): Observable<boolean> {
    return this._recordingStarted.asObservable();
  }

  resetTimer() {
    if (this.recorder) {
      return;
    }

    this._recordingTime.next('00:00');
  }

  startRecording() {
    if (this.recorder) {
      // Recording is already started
      return;
    }
    this._recordingTime.next('00:00');
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(s => {
        this.stream = s;
        this.record();
      })
      .catch(error => {
        this._recordingFailed.next();
      });
  }

  stopRecording() {
    if (this.recorder) {
      this.recorder.stop(
        blob => {
          if (this.startTime) {
            const mp3Name = encodeURIComponent(
              'audio_' + new Date().getTime() + '.wav',
            );
            this.stopMedia();
            this._recorded.next({ recording: blob, title: mp3Name });
          }
        },
        () => {
          this.stopMedia();
          this._recordingFailed.next();
        },
      );
    }
  }

  abortRecording() {
    this.stopMedia();
  }

  private record() {
    this.recorder = new RecordRTC.StereoAudioRecorder(this.stream, {
      type: 'audio',
      mimeType: 'audio/wav',
    });
    this._recordingStarted.next(true);
    this.recorder.record();
    this.startTime = moment();
    this.interval = setInterval(() => {
      const currentTime = moment();
      const diffTime = moment.duration(currentTime.diff(this.startTime));
      const time =
        this.toString(diffTime.minutes()) +
        ':' +
        this.toString(diffTime.seconds());
      this._recordingTime.next(time);
    }, 1000);
  }

  private stopMedia() {
    if (this.recorder) {
      this.recorder = null;
      this._recordingStarted.next(false);
      clearInterval(this.interval);
      this.startTime = null;
      if (this.stream) {
        this.stream.getAudioTracks().forEach(track => track.stop());
        this.stream = null;
      }
    }
  }

  private toString(value) {
    let val = value;
    if (!value) {
      val = '00';
    }
    if (value < 10) {
      val = '0' + value;
    }
    return val;
  }
}
