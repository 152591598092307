/* tslint:disable */

declare var Object: any;
export interface RenderInterface {
  type: string;
  image: any;
  options: Array<any>;
  order?: number;
  disabled: boolean;
  dimensionType?: string;
  doorhandleType?: string;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
}

export class Render implements RenderInterface {
  'type': string;
  'image': any;
  'options': Array<any>;
  'order': number;
  'disabled': boolean;
  'dimensionType': string;
  'doorhandleType': string;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  constructor(data?: RenderInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Render`.
   */
  public static getModelName() {
    return 'Render';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Render for dynamic purposes.
   **/
  public static factory(data: RenderInterface): Render {
    return new Render(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Render',
      plural: 'Renders',
      path: 'Renders',
      idName: 'id',
      properties: {
        type: {
          name: 'type',
          type: 'string',
        },
        image: {
          name: 'image',
          type: 'any',
        },
        options: {
          name: 'options',
          type: 'Array&lt;any&gt;',
          default: <any>[],
        },
        order: {
          name: 'order',
          type: 'number',
          default: 5,
        },
        disabled: {
          name: 'disabled',
          type: 'boolean',
          default: false,
        },
        dimensionType: {
          name: 'dimensionType',
          type: 'string',
          default: 'false',
        },
        doorhandleType: {
          name: 'doorhandleType',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {},
    };
  }
}
