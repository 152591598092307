/* tslint:disable */

declare var Object: any;
export interface FinalCheckFormQuestionInterface {
  name: string;
  label: string;
}

export class FinalCheckFormQuestion implements FinalCheckFormQuestionInterface {
  'name': string;
  'label': string;
  constructor(data?: FinalCheckFormQuestionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `FinalCheckFormQuestion`.
   */
  public static getModelName() {
    return 'FinalCheckFormQuestion';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of FinalCheckFormQuestion for dynamic purposes.
   **/
  public static factory(
    data: FinalCheckFormQuestionInterface,
  ): FinalCheckFormQuestion {
    return new FinalCheckFormQuestion(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'FinalCheckFormQuestion',
      plural: 'FinalCheckFormQuestions',
      path: 'FinalCheckFormQuestions',
      idName: '',
      properties: {
        name: {
          name: 'name',
          type: 'string',
        },
        label: {
          name: 'label',
          type: 'string',
        },
      },
      relations: {},
    };
  }
}
