/* tslint:disable */

declare var Object: any;
export interface IcepayIssuerInterface {
  issuerKeyword?: string;
  description?: string;
}

export class IcepayIssuer implements IcepayIssuerInterface {
  'issuerKeyword': string;
  'description': string;
  constructor(data?: IcepayIssuerInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `IcepayIssuer`.
   */
  public static getModelName() {
    return 'IcepayIssuer';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of IcepayIssuer for dynamic purposes.
   **/
  public static factory(data: IcepayIssuerInterface): IcepayIssuer {
    return new IcepayIssuer(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'IcepayIssuer',
      plural: 'IcepayIssuers',
      path: 'IcepayIssuers',
      idName: 'id',
      properties: {
        issuerKeyword: {
          name: 'issuerKeyword',
          type: 'string',
        },
        description: {
          name: 'description',
          type: 'string',
        },
      },
      relations: {},
    };
  }
}
