/* tslint:disable */

declare var Object: any;
export interface ExactTokenInterface {
  expires?: Date;
  token?: any;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
}

export class ExactToken implements ExactTokenInterface {
  'expires': Date;
  'token': any;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  constructor(data?: ExactTokenInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ExactToken`.
   */
  public static getModelName() {
    return 'ExactToken';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ExactToken for dynamic purposes.
   **/
  public static factory(data: ExactTokenInterface): ExactToken {
    return new ExactToken(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ExactToken',
      plural: 'ExactToken',
      path: 'ExactToken',
      idName: 'id',
      properties: {
        expires: {
          name: 'expires',
          type: 'Date',
        },
        token: {
          name: 'token',
          type: 'any',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {},
    };
  }
}
