/* tslint:disable */

declare var Object: any;
export interface SupplyProductInterface {
  code: string;
  name: string;
  description?: string;
  price: number;
  imageId?: string;
  picqerProductId?: string;
  exactArticleCode?: string;
  disabled: boolean;
  exclude?: any;
  inStore: boolean;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
}

export class SupplyProduct implements SupplyProductInterface {
  'code': string;
  'name': string;
  'description': string;
  'price': number;
  'imageId': string;
  'picqerProductId': string;
  'exactArticleCode': string;
  'disabled': boolean;
  'exclude': any;
  'inStore': boolean;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  constructor(data?: SupplyProductInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `SupplyProduct`.
   */
  public static getModelName() {
    return 'SupplyProduct';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of SupplyProduct for dynamic purposes.
   **/
  public static factory(data: SupplyProductInterface): SupplyProduct {
    return new SupplyProduct(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'SupplyProduct',
      plural: 'SupplyProducts',
      path: 'SupplyProducts',
      idName: 'id',
      properties: {
        code: {
          name: 'code',
          type: 'string',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        description: {
          name: 'description',
          type: 'string',
        },
        price: {
          name: 'price',
          type: 'number',
        },
        imageId: {
          name: 'imageId',
          type: 'string',
        },
        picqerProductId: {
          name: 'picqerProductId',
          type: 'string',
        },
        exactArticleCode: {
          name: 'exactArticleCode',
          type: 'string',
        },
        disabled: {
          name: 'disabled',
          type: 'boolean',
          default: false,
        },
        exclude: {
          name: 'exclude',
          type: 'any',
          default: <any>null,
        },
        inStore: {
          name: 'inStore',
          type: 'boolean',
          default: false,
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {},
    };
  }
}
