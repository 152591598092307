/* tslint:disable */
import { ProductieStaat, RackSlot } from '../index';

declare var Object: any;
export interface RackInterface {
  capacity: number;
  label: number;
  lastUsed?: Date;
  typeViewName: string;
  type: string;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
  productieStaten?: ProductieStaat[];
  rackSlots?: RackSlot[];
}

export class Rack implements RackInterface {
  'capacity': number;
  'label': number;
  'lastUsed': Date;
  'typeViewName': string;
  'type': string;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  productieStaten: ProductieStaat[];
  rackSlots: RackSlot[];
  constructor(data?: RackInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Rack`.
   */
  public static getModelName() {
    return 'Rack';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Rack for dynamic purposes.
   **/
  public static factory(data: RackInterface): Rack {
    return new Rack(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Rack',
      plural: 'Racks',
      path: 'Racks',
      idName: 'id',
      properties: {
        capacity: {
          name: 'capacity',
          type: 'number',
        },
        label: {
          name: 'label',
          type: 'number',
        },
        lastUsed: {
          name: 'lastUsed',
          type: 'Date',
        },
        typeViewName: {
          name: 'typeViewName',
          type: 'string',
        },
        type: {
          name: 'type',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {
        productieStaten: {
          name: 'productieStaten',
          type: 'ProductieStaat[]',
          model: 'ProductieStaat',
          relationType: 'hasMany',
          modelThrough: 'RackSlot',
          keyThrough: 'productieStaatId',
          keyFrom: 'id',
          keyTo: 'rackId',
        },
        rackSlots: {
          name: 'rackSlots',
          type: 'RackSlot[]',
          model: 'RackSlot',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'rackId',
        },
      },
    };
  }
}
