//////////////////////////////
//                          //
//         Angular          //
//                          //
//////////////////////////////
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

//////////////////////////////
//                          //
//       Dependencies       //
//                          //
//////////////////////////////
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';

//////////////////////////////
//                          //
//        App Imports       //
//                          //
//////////////////////////////
import { StandardProduct, StandardProductApi } from '@app/_sdk';

@Component({
  selector: 'app-standard-product-list-modal',
  templateUrl: './standard-product-list-modal.component.html',
  styleUrls: ['./standard-product-list-modal.component.scss'],
})
export class StandardProductListModalComponent implements OnInit {
  // -----------------------------------------------------------------------------------------------------
  // @ Public properties
  // -----------------------------------------------------------------------------------------------------
  @Input() standardProducts: StandardProduct[];
  searchInput: FormControl;
  filteredProducts: StandardProduct[];

  // -----------------------------------------------------------------------------------------------------
  // @ Private properties
  // -----------------------------------------------------------------------------------------------------
  private unsubscribeAll: Subject<any>;

  /**
   * Constructor
   */
  constructor(
    public activeModal: NgbActiveModal,
    private standardProductApi: StandardProductApi,
  ) {
    // Set the public defaults
    this.searchInput = new FormControl('');

    // Set the private defaults
    this.unsubscribeAll = new Subject();
  }

  /**
   * @description On init
   */
  async ngOnInit() {
    // Get standard products if no list is given
    if (!this.standardProducts) {
      this.standardProducts = await this.standardProductApi
        .find<StandardProduct>({
          order: 'name',
          include: [
            {
              relation: 'exactArticleWarehouseLocation',
              scope: {
                fields: ['itemStock'],
              },
            },
            {
              relation: 'deliveryCondition',
              scope: {
                include: {
                  relation: 'deliveryCondition',
                },
              },
            },
            {
              relation: 'deliveryMethod',
            },
            {
              relation: 'productType',
            },
            {
              relation: 'productCategory',
              scope: {
                include: {
                  relation: 'productCategory',
                },
              },
            },
          ],
        })
        .toPromise();
    }
    this.filteredProducts = this.standardProducts;

    this.searchInput.valueChanges
      .pipe(
        takeUntil(this.unsubscribeAll),
        debounceTime(300),
        distinctUntilChanged(),
      )
      .subscribe(searchText => {
        this.filteredProducts = this.standardProducts.filter(product =>
          product.name.toLowerCase().includes(searchText.toLowerCase()),
        );
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * @description Select the standardProduct
   * @param standardProduct
   * @returns
   */
  add(standardProduct: StandardProduct): void {
    this.activeModal.close(standardProduct);
  }
}
