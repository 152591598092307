/* tslint:disable */

declare var Object: any;
export interface InternalNoteInterface {
  linkType?: string;
  linkId: any;
  message?: string;
  id?: any;
  userId?: any;
  orderId?: any;
  dateCreated?: Date;
  dateModified?: Date;
  link?: any;
  user?: any;
}

export class InternalNote implements InternalNoteInterface {
  'linkType': string;
  'linkId': any;
  'message': string;
  'id': any;
  'userId': any;
  'orderId': any;
  'dateCreated': Date;
  'dateModified': Date;
  link: any;
  user: any;
  constructor(data?: InternalNoteInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `InternalNote`.
   */
  public static getModelName() {
    return 'InternalNote';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of InternalNote for dynamic purposes.
   **/
  public static factory(data: InternalNoteInterface): InternalNote {
    return new InternalNote(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'InternalNote',
      plural: 'InternalNotes',
      path: 'InternalNotes',
      idName: 'id',
      properties: {
        linkType: {
          name: 'linkType',
          type: 'string',
        },
        linkId: {
          name: 'linkId',
          type: 'any',
        },
        message: {
          name: 'message',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        userId: {
          name: 'userId',
          type: 'any',
        },
        orderId: {
          name: 'orderId',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {
        link: {
          name: 'link',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'linkId',
          keyTo: 'id',
        },
        user: {
          name: 'user',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'userId',
          keyTo: 'id',
        },
      },
    };
  }
}
