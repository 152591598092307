/* tslint:disable */

declare var Object: any;
export interface DiscountCodeInterface {
  code: string;
  name: string;
  type: string;
  value: number;
  startDate: Date;
  endDate: Date;
  applyAlways?: boolean;
  applyOnce?: boolean;
  applyToOrder?: boolean;
  applyToSupplyOrder?: boolean;
  applyToStandardOrder?: boolean;
  appliedAt?: Date;
  batchParent?: boolean;
  batchId?: any;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
  batchChildren?: any[];
  batchParents?: any;
}

export class DiscountCode implements DiscountCodeInterface {
  'code': string;
  'name': string;
  'type': string;
  'value': number;
  'startDate': Date;
  'endDate': Date;
  'applyAlways': boolean;
  'applyOnce': boolean;
  'applyToOrder': boolean;
  'applyToSupplyOrder': boolean;
  'applyToStandardOrder': boolean;
  'appliedAt': Date;
  'batchParent': boolean;
  'batchId': any;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  batchChildren: any[];
  batchParents: any;
  constructor(data?: DiscountCodeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `DiscountCode`.
   */
  public static getModelName() {
    return 'DiscountCode';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of DiscountCode for dynamic purposes.
   **/
  public static factory(data: DiscountCodeInterface): DiscountCode {
    return new DiscountCode(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'DiscountCode',
      plural: 'DiscountCodes',
      path: 'DiscountCodes',
      idName: 'id',
      properties: {
        code: {
          name: 'code',
          type: 'string',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        type: {
          name: 'type',
          type: 'string',
        },
        value: {
          name: 'value',
          type: 'number',
        },
        startDate: {
          name: 'startDate',
          type: 'Date',
        },
        endDate: {
          name: 'endDate',
          type: 'Date',
        },
        applyAlways: {
          name: 'applyAlways',
          type: 'boolean',
          default: false,
        },
        applyOnce: {
          name: 'applyOnce',
          type: 'boolean',
          default: false,
        },
        applyToOrder: {
          name: 'applyToOrder',
          type: 'boolean',
          default: false,
        },
        applyToSupplyOrder: {
          name: 'applyToSupplyOrder',
          type: 'boolean',
          default: false,
        },
        applyToStandardOrder: {
          name: 'applyToStandardOrder',
          type: 'boolean',
          default: false,
        },
        appliedAt: {
          name: 'appliedAt',
          type: 'Date',
        },
        batchParent: {
          name: 'batchParent',
          type: 'boolean',
        },
        batchId: {
          name: 'batchId',
          type: 'any',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {
        batchChildren: {
          name: 'batchChildren',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'batchId',
        },
        batchParents: {
          name: 'batchParents',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'batchId',
          keyTo: 'id',
        },
      },
    };
  }
}
