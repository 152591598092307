import { NgbDateParserFormatter, } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
moment.locale('nl');
export class MomentDateFormatter extends NgbDateParserFormatter {
    constructor() {
        super(...arguments);
        this.DT_FORMAT = 'DD/MM/YYYY';
    }
    parse(value) {
        if (value) {
            value = value.trim();
            let mdt = moment(value, this.DT_FORMAT);
        }
        return null;
    }
    format(date) {
        if (!date)
            return '';
        let mdt = moment([date.year, date.month - 1, date.day]);
        if (!mdt.isValid())
            return '';
        return mdt.format(this.DT_FORMAT);
    }
}
