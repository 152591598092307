/* tslint:disable */

declare var Object: any;
export interface StandardProductInterface {
  name: string;
  slug: string;
  price: number;
  disabled: boolean;
  exactArticleCode: string;
  imageId?: string;
  mainImageAltText?: string;
  code: string;
  description?: string;
  inStore: boolean;
  productTypeId: any;
  deliveryMethodId: any;
  length: number;
  width: number;
  height: number;
  weight: number;
  priceBefore?: number;
  thumbnailId?: string;
  pdfId?: string;
  images?: Array<any>;
  shortname?: string;
  sortOrder?: number;
  picqerProductId?: string;
  id?: any;
  dateCreated?: Date;
  dateModified?: Date;
  userIdCreated?: string;
  userIdModified?: string;
  exactArticleWarehouseLocation?: any[];
  deliveryMethod?: any;
  deliveryCondition?: any[];
  productCategory?: any[];
  productType?: any;
}

export class StandardProduct implements StandardProductInterface {
  'name': string;
  'slug': string;
  'price': number;
  'disabled': boolean;
  'exactArticleCode': string;
  'imageId': string;
  'mainImageAltText': string;
  'code': string;
  'description': string;
  'inStore': boolean;
  'productTypeId': any;
  'deliveryMethodId': any;
  'length': number;
  'width': number;
  'height': number;
  'weight': number;
  'priceBefore': number;
  'thumbnailId': string;
  'pdfId': string;
  'images': Array<any>;
  'shortname': string;
  'sortOrder': number;
  'picqerProductId': string;
  'id': any;
  'dateCreated': Date;
  'dateModified': Date;
  'userIdCreated': string;
  'userIdModified': string;
  exactArticleWarehouseLocation: any[];
  deliveryMethod: any;
  deliveryCondition: any[];
  productCategory: any[];
  productType: any;
  constructor(data?: StandardProductInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `StandardProduct`.
   */
  public static getModelName() {
    return 'StandardProduct';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of StandardProduct for dynamic purposes.
   **/
  public static factory(data: StandardProductInterface): StandardProduct {
    return new StandardProduct(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'StandardProduct',
      plural: 'StandardProducts',
      path: 'StandardProducts',
      idName: 'id',
      properties: {
        name: {
          name: 'name',
          type: 'string',
        },
        slug: {
          name: 'slug',
          type: 'string',
        },
        price: {
          name: 'price',
          type: 'number',
        },
        disabled: {
          name: 'disabled',
          type: 'boolean',
          default: false,
        },
        exactArticleCode: {
          name: 'exactArticleCode',
          type: 'string',
        },
        imageId: {
          name: 'imageId',
          type: 'string',
        },
        mainImageAltText: {
          name: 'mainImageAltText',
          type: 'string',
        },
        code: {
          name: 'code',
          type: 'string',
        },
        description: {
          name: 'description',
          type: 'string',
        },
        inStore: {
          name: 'inStore',
          type: 'boolean',
          default: false,
        },
        productTypeId: {
          name: 'productTypeId',
          type: 'any',
        },
        deliveryMethodId: {
          name: 'deliveryMethodId',
          type: 'any',
        },
        length: {
          name: 'length',
          type: 'number',
        },
        width: {
          name: 'width',
          type: 'number',
        },
        height: {
          name: 'height',
          type: 'number',
        },
        weight: {
          name: 'weight',
          type: 'number',
        },
        priceBefore: {
          name: 'priceBefore',
          type: 'number',
        },
        thumbnailId: {
          name: 'thumbnailId',
          type: 'string',
        },
        pdfId: {
          name: 'pdfId',
          type: 'string',
        },
        images: {
          name: 'images',
          type: 'Array&lt;any&gt;',
        },
        shortname: {
          name: 'shortname',
          type: 'string',
        },
        sortOrder: {
          name: 'sortOrder',
          type: 'number',
        },
        picqerProductId: {
          name: 'picqerProductId',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
        userIdCreated: {
          name: 'userIdCreated',
          type: 'string',
        },
        userIdModified: {
          name: 'userIdModified',
          type: 'string',
        },
      },
      relations: {
        exactArticleWarehouseLocation: {
          name: 'exactArticleWarehouseLocation',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          keyFrom: 'exactArticleCode',
          keyTo: 'itemCode',
        },
        deliveryMethod: {
          name: 'deliveryMethod',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'deliveryMethodId',
          keyTo: 'id',
        },
        deliveryCondition: {
          name: 'deliveryCondition',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'standardProductId',
        },
        productCategory: {
          name: 'productCategory',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'standardProductId',
        },
        productType: {
          name: 'productType',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'productTypeId',
          keyTo: 'id',
        },
      },
    };
  }
}
