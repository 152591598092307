import * as tslib_1 from "tslib";
import { Renderer2, RendererFactory2 } from '@angular/core';
import { Router } from '@angular/router';
import { InternalStorage, LoopBackAuth } from 'app/_sdk';
import { environment } from 'environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxRolesService } from 'ngx-permissions';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../../_sdk/services/core/auth.service";
import * as i2 from "../../_sdk/storage/storage.swaps";
import * as i3 from "ngx-device-detector";
import * as i4 from "ngx-permissions";
import * as i5 from "@angular/router";
export class AppConfigurationService {
    constructor(auth, storage, deviceService, rolesService, router, renderer) {
        this.auth = auth;
        this.storage = storage;
        this.deviceService = deviceService;
        this.rolesService = rolesService;
        this.router = router;
        this.title$ = new BehaviorSubject(environment.defaultTitle);
        this.getLogo().then(logo => (this.logo = logo));
        this.renderer = renderer.createRenderer(null, null);
    }
    setMobileClass() {
        if (this.deviceService.isMobile()) {
            if (this.deviceService.device === 'android') {
                if (this.deviceService.getDeviceInfo().userAgent.match(/Mobile/i) !== null) {
                    this.renderer.addClass(document.body, 'isMobile');
                }
            }
            else {
                this.renderer.addClass(document.body, 'isMobile');
            }
        }
    }
    setDefaultTitle() {
        this.setTitle(environment.defaultTitle);
    }
    setTitle(title) {
        this.title$.next(title);
    }
    getLogo(checkAuthenticated = false) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return environment.defaultLogo;
        });
    }
    loginProcedure(token, saveCurrentUser = false) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            /* fetch the ttl were using to save the cookie **/
            const expires = new Date(new Date().getTime() + token.ttl * 1000);
            /** get a copy of the current token and store it as the previous user */
            if (saveCurrentUser) {
                this.storage.set('previousUser', JSON.stringify(this.auth.getToken()), expires);
            }
            /** set the new token as the logged-in user */
            this.auth.setToken(token);
            /** check which roles the user has and register them with ngx-perms */
            this.rolesService.flushRoles();
            environment.permissions.roles.map(r => {
                if (token.user.roles.indexOf(r.value) !== -1) {
                    this.rolesService.addRole(r.role, () => true);
                }
            });
            /** save the the ngx-perms in our storage */
            this.storage.set('roles', JSON.stringify(this.rolesService.getRoles()), expires);
            /** go to our admin route which handles where which role should be navigated */
            this.router.navigate(['/admin']);
        });
    }
    getBase64ImageFromUrl(imageUrl) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const res = yield fetch(imageUrl);
            const blob = yield res.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.addEventListener('load', function () {
                    resolve(reader.result);
                }, false);
                reader.onerror = () => {
                    return reject(this);
                };
                reader.readAsDataURL(blob);
            });
        });
    }
}
AppConfigurationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppConfigurationService_Factory() { return new AppConfigurationService(i0.ɵɵinject(i1.LoopBackAuth), i0.ɵɵinject(i2.InternalStorage), i0.ɵɵinject(i3.DeviceDetectorService), i0.ɵɵinject(i4.NgxRolesService), i0.ɵɵinject(i5.Router), i0.ɵɵinject(i0.RendererFactory2)); }, token: AppConfigurationService, providedIn: "root" });
