import * as tslib_1 from "tslib";
//////////////////////////////
//                          //
//         Angular          //
//                          //
//////////////////////////////
import { HttpClient, HttpHeaders, } from '@angular/common/http';
//////////////////////////////
//                          //
//       Dependencies       //
//                          //
//////////////////////////////
import { Observable } from 'rxjs';
import { saveAs } from 'file-saver';
//////////////////////////////
//                          //
//        App Imports       //
//                          //
//////////////////////////////
import { LoopBackAuth, LoopBackConfig } from '@app/_sdk';
import { catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../_sdk/services/core/auth.service";
import * as i2 from "@angular/common/http";
export class DownloadService {
    /**
     * @description Constructor
     */
    constructor(auth, http) {
        this.auth = auth;
        this.http = http;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * @description Download file
     * @param {string} id Id of the instance
     * @param {string} model Model of the instance
     * @param {string} method Method where to download
     * @param {string} filename Filename of the download
     * @returns {Promise<void>}
     */
    download(id, model, method, filename, addExtension = false, queryParams) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let url = this.getFileDownloadUrl(id, model, method);
            if (queryParams) {
                url += `?${queryParams.toString()}`;
            }
            const blob = yield this.get(url).toPromise();
            if (addExtension && blob && blob.type) {
                switch (blob.type) {
                    case 'application/pdf':
                        filename += '.pdf';
                        break;
                    case 'application/zip':
                        filename += '.zip';
                        break;
                    case 'application/zpl':
                        filename += '.zpl';
                        break;
                }
            }
            saveAs(blob, filename);
        });
    }
    parseErrorBlob(err) {
        const reader = new FileReader();
        const obs = Observable.create((observer) => {
            reader.onloadend = (e) => {
                observer.error(JSON.parse(reader.result));
                observer.complete();
            };
        });
        reader.readAsText(err.error);
        return obs;
    }
    /**
     * @description Perform a http request
     * @param {string} url
     * @param {HttpHeaders} headers
     * @returns : Observable<Blob>
     */
    get(url, headers = new HttpHeaders()) {
        return this.http
            .get(url, {
            responseType: 'blob',
            withCredentials: LoopBackConfig.getRequestOptionsCredentials(),
            headers: this.setAuthHeaders(headers),
        })
            .pipe(catchError(this.parseErrorBlob));
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * @returns Generate a download URL
     * @param {string} id Id of the instance
     * @param {string} model Model of the instance
     * @param {string} method Method where to download
     * @returns {string} URL for the download
     */
    getFileDownloadUrl(id, model, method) {
        let url = '';
        url += `${LoopBackConfig.getPath()}/`;
        url += `${LoopBackConfig.getApiVersion()}/`;
        url += `${model}/`;
        url += `${id}/`;
        url += method;
        return url;
    }
    /**
     * @description Set auth headers with Loopback authorization
     * @param {HttpHeaders} headers
     * @returns {HttpHeaders}
     */
    setAuthHeaders(headers = new HttpHeaders()) {
        if (this.auth.getAccessTokenId()) {
            headers = headers.append('Authorization', LoopBackConfig.getAuthPrefix() + this.auth.getAccessTokenId());
        }
        return headers;
    }
}
DownloadService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DownloadService_Factory() { return new DownloadService(i0.ɵɵinject(i1.LoopBackAuth), i0.ɵɵinject(i2.HttpClient)); }, token: DownloadService, providedIn: "root" });
