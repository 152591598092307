/* tslint:disable */
import { UserModel } from '../index';

declare var Object: any;
export interface PriceCategoryInterface {
  name: string;
  id?: any;
  userIdCreated?: string;
  userIdModified?: string;
  parentVersionId?: any;
  dateCreated?: Date;
  dateModified?: Date;
  userCreated?: UserModel;
  userModified?: UserModel;
  parentVersion?: PriceCategory;
  items?: any[];
}

export class PriceCategory implements PriceCategoryInterface {
  'name': string;
  'id': any;
  'userIdCreated': string;
  'userIdModified': string;
  'parentVersionId': any;
  'dateCreated': Date;
  'dateModified': Date;
  userCreated: UserModel;
  userModified: UserModel;
  parentVersion: PriceCategory;
  items: any[];
  constructor(data?: PriceCategoryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PriceCategory`.
   */
  public static getModelName() {
    return 'PriceCategory';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of PriceCategory for dynamic purposes.
   **/
  public static factory(data: PriceCategoryInterface): PriceCategory {
    return new PriceCategory(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'PriceCategory',
      plural: 'PriceCategories',
      path: 'PriceCategories',
      idName: 'id',
      properties: {
        name: {
          name: 'name',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        userIdCreated: {
          name: 'userIdCreated',
          type: 'string',
        },
        userIdModified: {
          name: 'userIdModified',
          type: 'string',
        },
        parentVersionId: {
          name: 'parentVersionId',
          type: 'any',
        },
        dateCreated: {
          name: 'dateCreated',
          type: 'Date',
        },
        dateModified: {
          name: 'dateModified',
          type: 'Date',
        },
      },
      relations: {
        userCreated: {
          name: 'userCreated',
          type: 'UserModel',
          model: 'UserModel',
          relationType: 'belongsTo',
          keyFrom: 'userIdCreated',
          keyTo: 'id',
        },
        userModified: {
          name: 'userModified',
          type: 'UserModel',
          model: 'UserModel',
          relationType: 'belongsTo',
          keyFrom: 'userIdModified',
          keyTo: 'id',
        },
        parentVersion: {
          name: 'parentVersion',
          type: 'PriceCategory',
          model: 'PriceCategory',
          relationType: 'belongsTo',
          keyFrom: 'parentVersionId',
          keyTo: 'id',
        },
        items: {
          name: 'items',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'priceCategoryId',
        },
      },
    };
  }
}
