import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Router } from '@angular/router';
import { InternalStorage, LoopBackAuth } from 'app/_sdk';
import { environment } from 'environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxRolesService } from 'ngx-permissions';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppConfigurationService {
  public title$ = new BehaviorSubject(environment.defaultTitle);
  public logo: string;

  private renderer: Renderer2;

  constructor(
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Inject(InternalStorage) protected storage: InternalStorage,
    private deviceService: DeviceDetectorService,
    private rolesService: NgxRolesService,
    private router: Router,
    renderer: RendererFactory2,
  ) {
    this.getLogo().then(logo => (this.logo = logo));
    this.renderer = renderer.createRenderer(null, null);
  }

  setMobileClass() {
    if (this.deviceService.isMobile()) {
      if (this.deviceService.device === 'android') {
        if (
          this.deviceService.getDeviceInfo().userAgent.match(/Mobile/i) !== null
        ) {
          this.renderer.addClass(document.body, 'isMobile');
        }
      } else {
        this.renderer.addClass(document.body, 'isMobile');
      }
    }
  }

  setDefaultTitle() {
    this.setTitle(environment.defaultTitle);
  }

  setTitle(title: string) {
    this.title$.next(title);
  }

  async getLogo(checkAuthenticated: boolean = false): Promise<string> {
    return environment.defaultLogo;
  }

  async loginProcedure(token: any, saveCurrentUser = false): Promise<void> {
    /* fetch the ttl were using to save the cookie **/
    const expires = new Date(new Date().getTime() + token.ttl * 1000);

    /** get a copy of the current token and store it as the previous user */
    if (saveCurrentUser) {
      this.storage.set(
        'previousUser',
        JSON.stringify(this.auth.getToken()),
        expires,
      );
    }

    /** set the new token as the logged-in user */
    this.auth.setToken(token);

    /** check which roles the user has and register them with ngx-perms */
    this.rolesService.flushRoles();
    environment.permissions.roles.map(r => {
      if (token.user.roles.indexOf(r.value) !== -1) {
        this.rolesService.addRole(r.role, () => true);
      }
    });

    /** save the the ngx-perms in our storage */
    this.storage.set(
      'roles',
      JSON.stringify(this.rolesService.getRoles()),
      expires,
    );

    /** go to our admin route which handles where which role should be navigated */
    this.router.navigate(['/admin']);
  }

  public async getBase64ImageFromUrl(imageUrl): Promise<any> {
    const res = await fetch(imageUrl);
    const blob = await res.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result);
        },
        false,
      );

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }
}
